import React, { FC, useEffect, useState } from 'react'
import { Card } from './style'
import NewNotification from '../NewNotification'
import { INotification } from 'src/types'
import { UiNotification } from 'src/constants'
import { useAppSelector } from '../../customHooks'
import { removeNotification } from '../../stores/api-notifications'
import { useAppDispatch } from '../../customHooks'

const NotificationArea: FC<any> = (props) => {
  const dispatch = useAppDispatch()
  const list: INotification[] = useAppSelector(
    (state) => state.apiNotificationDetails.notificationList
  )
  const [notifications, setNotifications] = useState<INotification[]>([])

  const deleteNotification = (name: string) => {
    dispatch(removeNotification(name))
  }

  useEffect(() => {
    setNotifications(list)
  }, [list])

  if (!notifications.length) return null

  const notificationCards = notifications.map((item, index) => (
    <Card key={index} data-testid={item?.notificationName}>
      <NewNotification
        statusType={item?.statusType as UiNotification}
        title={item?.title}
        description={item?.description}
        notificationName={item?.notificationName}
        showCloseButton={item?.showCloseButton}
        onClose={() => deleteNotification(item?.notificationName)}
        className={item?.className}
        hideIcon={item.hideIcon}
      />
    </Card>
  ))

  return (
    <div data-testid="notification-area-container">{notificationCards}</div>
  )
}

export default NotificationArea
