import React from 'react'
import { CardText, DowngradeModalLink, CardContentDialog } from '../style'
import { redirectURLs } from '../../../configs/external-link'

const GoogleDowngradeContent = ({ t, googleUpgradePrice }) => {
  return (
    <div>
      <CardText>
        {t(
          'Downgrade-Modal-Content1',
          'Please check your amount of current storage space used at '
        )}

        <a>
          <DowngradeModalLink
            href={redirectURLs.oneGoogleURL}
            target="_blank"
            rel="noreferrer"
          >
            {t('Downgrade-Modal-Content2', 'one.google.com')}
          </DowngradeModalLink>
        </a>
        {t(
          'Downgrade-Modal-Content3',
          " before downgrading. If you're currently using 200 GB or more and proceed with downgrading, you will not be able to store anything new until storage space is freed up. Save your files elsewhere or upgrade your storage space before two years to avoid loss of data (files, images, etc.)."
        )}
      </CardText>
      <CardContentDialog>
        <CardText>
          {t(
            'Downgrade-Modal-Content4',
            'By downgrading to 200 GB of storage, your HP All-In Plan will decrease by'
          )}

          {` $${googleUpgradePrice} `}
          {t(
            'Downgrade-Modal-Content5',
            "per month, partial months will be prorated. You'll receive an invoice today that notifies you of your plan changes. No payment is due until your next billing statement."
          )}
        </CardText>
      </CardContentDialog>
    </div>
  )
}
export default GoogleDowngradeContent
