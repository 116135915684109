import styled from 'styled-components'

import { tokens } from '@veneer/tokens'
const {
  fontTextRegular,
  lineHeight4,
  fontSize3,
  space1,
  colorGray0,
  colorGray7,
  space4
} = tokens

export const PreArrivalPaymentContainer = styled.div`
  width: 100%;
  background: ${colorGray0};
  gap: ${space1};
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CardNotload = styled.p`
  text-align: center;
  color: ${colorGray7};
  font-size: ${fontSize3};
  padding: 0 ${space4} ${space4} ${space4};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight4};

  &:last-child {
    padding-bottom: 0;
  }
`
