import React, { useState } from 'react'
import { useAppDispatch } from '../../../customHooks'
import { SubscriptionApiClient } from '../../../services/subscription-api'
import { BtnGroup } from '../style'
import GoogleDowngradeContent from '../GoogleContents/GoogleDowngradeContent'
import GoogleUpgradeContent from '../GoogleContents/GoogleUpgradeContent'

import Modal from '@veneer/core/dist/scripts/modal'
import ButtonGroup from '@veneer/core/dist/scripts/button_group'
import Button from '@veneer/core/dist/scripts/button'
import DataCollection from '../../../services/DataCollection'
import { uiEvents } from '../../../services/DataCollection/simpleUiEvents'
import { getactionAuxParams } from '../../../services/getactionAuxParams'

import { redirectURLs } from '../../../configs/external-link'
import {
  GoogleTransitionProps,
  GoogleDetailsProps
} from '../../../types/services/google-details'
import {
  ApiStatusCode,
  APINotificationList,
  GOOGLE_SUBSCRIPTION_STATE_LOCAL_STORAGE,
  EntityState
} from '../../../constants'
import {
  addNotification,
  removeNotification
} from '../../../stores/api-notifications'

enum upgradeEventType {
  CLOSE = 'close',
  CANCEL = 'cancel',
  UPDATE = 'update'
}

const ChangeStorageModal: React.FunctionComponent<
  GoogleTransitionProps & GoogleDetailsProps
> = ({ t, googleData, handleClose, refetchApiFn, url }) => {
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const captureEvents = async (eventType) => {
    const actionAuxParams = await getactionAuxParams(
      googleData.subscriptionData,
      googleData.currentActivePc
    )
    switch (eventType) {
      case upgradeEventType.UPDATE: {
        const event = uiEvents.updatePlan
        event.actionAuxParams = actionAuxParams
        DataCollection.sendEvent(event)
        break
      }
      case upgradeEventType.CLOSE: {
        const event = uiEvents.closeModalWindow
        event.actionAuxParams = actionAuxParams
        DataCollection.sendEvent(event)
        break
      }
      case upgradeEventType.CANCEL: {
        const event = uiEvents.cancelModalWindow
        event.actionAuxParams = actionAuxParams
        DataCollection.sendEvent(event)
        break
      }
    }
  }
  const changeStorage = async () => {
    const subscriptionClient = new SubscriptionApiClient(
      googleData.authProvider,
      googleData.stack
    )
    const subcriptionID = googleData.subscriptionData.subscriptionId
    const googleStorageEntityId = googleData.googleStorageData.entityId

    try {
      setModalLoading(true)
      const response = await subscriptionClient.changeGoogleStorage(
        subcriptionID,
        googleStorageEntityId,
        googleData.optionId
      )
      setModalLoading(false)
      handleClose()
      if (response.status === ApiStatusCode.Status_Code_202) {
        //activate again...
        googleData.eligibleForUpgrade
          ? window.localStorage.setItem(
              GOOGLE_SUBSCRIPTION_STATE_LOCAL_STORAGE,
              EntityState.PENDING_UPGRADE
            )
          : window.localStorage.setItem(
              GOOGLE_SUBSCRIPTION_STATE_LOCAL_STORAGE,
              EntityState.PENDING_DOWNGRADE
            )
        window.location.replace(url)
      } else {
        const notification = {
          statusType: 'positive',
          title: t(
            'NotificationsCard.googleUpgradeAPISuccess.title',
            'Your Google One cloud storage plan was successfully updated'
          ),
          description: t(
            'NotificationsCard.googleUpgradeAPISuccess.subtitle',
            'Your 2 TB Google cloud storage is ready.'
          ),
          notificationName: APINotificationList.GOOGLE_UPGRADE_SUCCESS,
          showCloseButton: true,
          className: 'notificationCard'
        }

        if (!googleData.eligibleForUpgrade) {
          notification.title = t(
            'NotificationsCard.googleDowngradeAPISuccess.title',
            'Your Google One cloud storage plan was successfully updated'
          )
          notification.description = t(
            'NotificationsCard.googleDowngradeAPISuccess.subtitle',
            'Your 200 GB Google cloud storage is ready.'
          )
        }
        dispatch(
          removeNotification(APINotificationList.GOOGLE_ACTIVATION_SUCCESS)
        )
        dispatch(addNotification(notification))
        setTimeout(() => refetchApiFn(), 2000)
      }
    } catch (error) {
      setModalLoading(false)
      handleClose()
      //dispatch events
      const notification = {
        statusType: 'warning',
        title: t(
          'NotificationsCard.googleUpgradeAPIFail.title',
          'There was an unexpected error while processing your request, please try again to upgrade your Google One storage'
        ),
        description: (
          <div>
            {t(
              'NotificationsCard.googleUpgradeAPIFail.subtitle',
              'For assistance, visit '
            )}
            <a href={redirectURLs.hpsupportPCaaSURL}>
              {t(
                'NotificationsCard.googleUpgradeAPIFail.hp-support',
                'HP 24/7 Pro live support.'
              )}
            </a>
          </div>
        ),
        notificationName: APINotificationList.GOOGLE_UPGRADE_ERROR,
        showCloseButton: true,
        className: 'notificationCard'
      }
      dispatch(addNotification(notification))
    }
  }
  return (
    <div data-testid="common-modal" style={{ position: 'relative' }}>
      <Modal
        align="left"
        closeButton
        id="modal-id"
        show={true}
        onClose={() => {
          captureEvents(upgradeEventType.CLOSE)
          handleClose()
        }}
        footer={
          <>
            <BtnGroup>
              <ButtonGroup>
                <Button
                  data-testid="modal-cancel-button"
                  appearance="secondary"
                  onClick={() => {
                    captureEvents(upgradeEventType.CANCEL)
                    handleClose()
                  }}
                >
                  {t('CloudStorage.Cancel-Button', 'Cancel')}
                </Button>
                <Button
                  data-testid="modal-submit-button"
                  onClick={() => {
                    captureEvents(upgradeEventType.UPDATE)
                    changeStorage()
                  }}
                  loading={modalLoading}
                >
                  {t('CloudStorage.Update-Button', 'Update Plan')}
                </Button>
              </ButtonGroup>
            </BtnGroup>
          </>
        }
        title={t(
          googleData.eligibleForUpgrade
            ? 'Upgrade to 2 TB of cloud storage'
            : 'Downgrade to 200 GB of cloud storage'
        )}
        data-testid="modal-update"
      >
        {googleData.eligibleForUpgrade ? (
          <GoogleUpgradeContent
            googleUpgradePrice={googleData.planChangePrice}
            {...{ t }}
          />
        ) : (
          <GoogleDowngradeContent
            googleUpgradePrice={googleData.planChangePrice}
            {...{ t }}
          />
        )}
      </Modal>
    </div>
  )
}

export default ChangeStorageModal
