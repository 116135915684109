import React from 'react'
import {
  IconSlashCircle,
  IconCheckCircle,
  IconExternalLink
} from '@veneer/core/dist/scripts/icons'
import {
  CardContentActivated,
  CardContentNotActivated,
  CardTextContent,
  LinkLable,
  ViewLink,
  CardActivated,
  CardContentText
} from '../style'
import { EntityState } from '../../../constants'
import { redirectURLs } from '../../../configs/external-link'
const GoogleCardContent = ({ t, googleData }) => {
  const entityState = googleData.googleStorageData.state
  return (
    <>
      {googleData?.isActivated || entityState == EntityState.UPDATING ? (
        <CardContentText>
          <CardActivated>
            <IconCheckCircle size={20} color="colorGreen6" />
            <CardContentActivated>
              {t('CloudStorage.activated', 'Activated')}
            </CardContentActivated>
          </CardActivated>
          <div>
            <a
              href={redirectURLs.googleDriveURL}
              target="_blank"
              rel="noreferrer"
            >
              <ViewLink>
                <LinkLable>
                  {t('CloudStorage.ViewDetails', 'View details')}
                </LinkLable>
                <LinkLable>
                  <IconExternalLink
                    size={24}
                    color="colorHpBlue6"
                  ></IconExternalLink>
                </LinkLable>
              </ViewLink>
            </a>
          </div>
        </CardContentText>
      ) : (
        <>
          <CardTextContent>
            <IconSlashCircle size={20} color="colorGray7" />
            <CardContentNotActivated>
              {t('CloudStorage.Not-activated', 'Not activated')}
            </CardContentNotActivated>
          </CardTextContent>
        </>
      )}
    </>
  )
}

export default GoogleCardContent
