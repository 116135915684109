import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const {
  fontTextRegular,
  fontSize1,
  fontSize2,
  lineHeight3,
  lineHeight2,
  colorGray10,
  colorGray7
} = tokens
export const CardComponents = styled.div`
  padding-top: 16px;
  @media (min-width: 500px) {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  @media (min-width: 640px) {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
`
export const CardTitle = styled.p`
  font-family: ${fontTextRegular};
  font-style: normal;
  font-weight: 700;
  font-size: ${fontSize2};
  line-height: ${lineHeight3};
  color: ${colorGray10};
`
export const CardDescription = styled.p`
  font-family: 'Forma DJR UI';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #404040;
`
export const CardAnchorTag = styled.p`
  font-family: 'Forma DJR UI';
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0278ab;
`
