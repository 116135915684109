import React, { useState, useEffect } from 'react'
import moment from 'moment'
import NotificationContainer from './NotificationContainer'
import { Entitlements } from 'src/types/services/subscription'
import { useAppSelector } from '../../customHooks'

import { ReturnsClient } from '../../services/returns-api'
import { EntityState } from '../../constants'
import { getDaysRemaining } from '../../utils/getDaysRemaining'
import { isHybridUser, isInRemorsePeriod } from '../../utils/common-utils'
const PostCancelNotification = ({
  t,
  authProvider,
  stack,
  subscriptionDetailsData
}) => {
  const [
    showPostCancelNotification,
    setShowPostCancelNotification
  ] = useState<boolean>(false)
  const [
    postCancelNotificationData,
    setPostCancelNotificationData
  ] = useState<any>()
  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )
  const maxDaysToReturn = useAppSelector(
    (state) => state.userSubscriptionDetails.maxDaysToReturn
  )

  useEffect(() => {
    /* istanbul ignore next */
    if (subscriptionDetailsData && maxDaysToReturn) fetch()
  }, [subscriptionDetailsData, authProvider, stack, maxDaysToReturn])

  /* istanbul ignore next */
  const fetch = async () => {
    if (!currentActivePc) {
      return
    }
    const inRemorsePeriod = isInRemorsePeriod(
      currentActivePc.cancellation.value.validUntil,
      moment(new Date())
    )
    if (
      currentActivePc?.state === EntityState.CANCELLING ||
      currentActivePc?.state === EntityState.CANCELLED
    ) {
      const notificationData = {
        subscriptionState: currentActivePc?.state,
        isHybridUser: isHybridUser(subscriptionDetailsData),
        remorsePeriod: inRemorsePeriod
      }

      setPostCancelNotificationData(notificationData)
      setShowPostCancelNotification(true)
    } else if (
      currentActivePc?.state === EntityState.PENDING_RETURN ||
      currentActivePc?.state === EntityState.RETURNED
    ) {
      const returnsClient = new ReturnsClient(authProvider, stack)
      const returnsList = await returnsClient.getReturnDetailsByTenantId(
        subscriptionDetailsData.tenantId
      )
      const filteredReturnList = returnsList.filter(
        (item) =>
          item.fulfillmentOrderId ===
          currentActivePc.fulfillment.value.fulfillmentOrderId
      )
      let status
      if (filteredReturnList.length > 0) {
        const returnStatus = filteredReturnList[0].parts.filter(
          (item) =>
            item.serialNumber === currentActivePc.entityDetails.serialNumber
        )
        status = returnStatus[0].status
      }
      const { daysRemainingToDeliver } = await getDaysRemaining(
        filteredReturnList[0].createdAt,
        maxDaysToReturn
      )
      const notificationData = {
        subscriptionState: currentActivePc?.state,
        serialNumber: currentActivePc.entityDetails.serialNumber,
        returnStatus: status,
        timeoutInDays: daysRemainingToDeliver,
        isHybridUser: isHybridUser(subscriptionDetailsData),
        remorsePeriod: inRemorsePeriod
      }
      setPostCancelNotificationData(notificationData)
      setShowPostCancelNotification(true)
    }
  }

  return (
    showPostCancelNotification && (
      <NotificationContainer
        notificationData={postCancelNotificationData}
        {...{ t }}
      />
    )
  )
}

export default PostCancelNotification
