import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
const {
  colorWhite,
  space1,
  space2,
  space4,
  space6,
  fontTextRegular,
  fontSize2,
  colorGray7,
  lineHeight4,
  lineHeight3,
  fontSize4,
  colorHpBlue6,
  colorGray4
} = tokens
export const CardHpOne = styled.div`
  background-color: ${colorWhite};
  width: 100%;
  height: auto;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  gap: ${space1};
  padding: ${space4};
  pointer-events: ${(props) => (props.isSuspended ? 'none' : 'auto')};
  color: ${(props) => (props.isSuspended ? colorGray4 : colorGray7)};
`

export const CardLabelHpOne = styled.p`
  font-size: ${fontSize2};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight3};
  color: ${(props) => (props.isSuspended ? colorGray4 : colorHpBlue6)};
`

export const AlignToBottomHpOne = styled.div``
export const CardNumberText = styled.div`
  margin-top: ${space1};
  color: ${(props) => (props.isSuspended ? colorGray4 : colorGray7)};
  font-family: ${fontTextRegular};
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSize2};
  line-height: ${lineHeight3};
`

export const CardTitle = styled.p`
  font-size: ${fontSize4};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight4};
  color: ${(props) => (props.isSuspended ? colorGray4 : colorGray7)};
  letter-spacing: 0.01em;
  margin-top: ${space2};
  margin-bottom: ${space1};
`
export const Border = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
`
export const Container = styled.div`
  padding-top: 24px;
  width: 100%;
`
