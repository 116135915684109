import citizens from './citizens.png'
import CloudOne from './CloudOne.png'
import GoogleOneLogo from './GoogleOneLogo.png'
import MicrosoftLogo from './MicrosoftLogo.png'
import CitizenpayPrearrival from './CitizenpayPrearrival.png'
import MicrosoftPrearrival from './MicrosoftPrearrival.png'
import MicrosoftPersonal from './MicrosoftPersonal.png'
const Images = {
  citizens,
  CloudOne,
  GoogleOneLogo,
  MicrosoftLogo,
  CitizenpayPrearrival,
  MicrosoftPrearrival,
  MicrosoftPersonal
}

export default Images
