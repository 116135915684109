export function addDay(date: string | number, qtDays: number): Date {
  const returningDate = new Date(date)

  returningDate.setDate(returningDate.getDate() + qtDays)

  return new Date(returningDate)
}

const transformToTime = (date: Date | string) => new Date(date).getTime()

export const getDaysRemaining = async (
  timeStamp: string,
  timeoutInDays: number
) => {
  if (!timeStamp || !timeoutInDays)
    return {
      haveDaysRemaining: false,
      limitDate: null,
      daysRemainingToDeliver: 0
    }
  const cancellationStartDate = transformToTime(timeStamp)
  const limitDate = new Date(addDay(cancellationStartDate, timeoutInDays))
  const daysRemainingToDeliver = Math.ceil(
    (transformToTime(limitDate) - Date.now()) / (1000 * 3600 * 24)
  )
  const haveDaysRemaining = daysRemainingToDeliver >= 0
  return {
    haveDaysRemaining,
    limitDate,
    daysRemainingToDeliver
  }
}
