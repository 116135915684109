import React from 'react'

import {
  Card,
  CardTitle,
  CardSize,
  CardContentSmall,
  CardContent,
  Border,
  AlignContent,
  AlignToTop,
  Container
} from '../5GConnectivity/style'
import { IconCheckCircle, Icon5GNetwork } from '@veneer/core/dist/scripts/icons'
import '../../styles/global.scss'
import { TranslatorFunctionType } from '../../types/localization'

import { useAppSelector } from '../../customHooks'
import { Entitlements } from 'src/types/services/subscription'
import { isInCancelState } from '../../utils/common-utils'
const Mobile5GConnectivity = ({ t }: { t: TranslatorFunctionType }) => {
  const isEntityEnabled = useAppSelector(
    (state) =>
      state.userSubscriptionDetails?.subscriptionStatus?.isEntityEnabled
  )
  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )
  /* istanbul ignore next */
  const isCancelled = isInCancelState(currentActivePc?.state)
  return (
    <Card>
      <Container
        data-testid="Container"
        isSuspended={!isEntityEnabled || isCancelled}
      >
        <Icon5GNetwork
          size={32}
          color="colorGray7"
          data-testid="Icon-Connectivity"
        />
        <CardTitle data-testid="connectivity_title">
          {t('5GConnectivity.title', '5G service')}
        </CardTitle>
        <AlignContent>
          <CardContent data-testid="plan">
            {t('5GConnectivity.plan', ' Plan')}
          </CardContent>
          <CardSize>
            <IconCheckCircle
              size={20}
              color={!isEntityEnabled ? 'colorGray4' : 'colorGreen6'}
            />
            <CardContentSmall
              data-testid="connected"
              isSuspended={!isEntityEnabled}
            >
              {t('5GConnectivity.always-on-connected', 'Connected (Always on)')}
            </CardContentSmall>
          </CardSize>
          <AlignToTop>
            <Border />
          </AlignToTop>
        </AlignContent>
      </Container>
    </Card>
  )
}

export default Mobile5GConnectivity
