import React from 'react'
import {
  IconPersonAssist,
  IconChevronRight
} from '@veneer/core/dist/scripts/icons'
import {
  CardTitle,
  CardLabelHpOne,
  Border,
  AlignToBottomHpOne,
  CardHpOne,
  CardNumberText,
  Container
} from './style'
import { TranslatorFunctionType } from '../../types/localization'
import { redirectURLs } from '../../configs/external-link'
import { captureClickEvents } from '../../services/DataCollection/clickActions'
import { AnalyticsClickEventsList } from '../../constants'
import { useAppSelector } from '../../customHooks'
import type { Subscription } from 'src/types/services/subscription'
import { HPOneSupport } from '@jarvis/react-hpx-shared-components'
import { Entitlements } from 'src/types/services/subscription'

function HPOneLiveSupport(getText: { t: TranslatorFunctionType }) {
  const subscriptionData: Subscription = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )
  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )
  const isEntityEnabled = useAppSelector(
    (state) =>
      state.userSubscriptionDetails?.subscriptionStatus?.isEntityEnabled
  )

  return (
    <Container>
      <CardHpOne isSuspended={!isEntityEnabled}>
        <IconPersonAssist
          size={32}
          color={!isEntityEnabled ? 'colorGray4' : 'colorGray10'}
        />

        <CardTitle isSuspended={!isEntityEnabled}>
          {getText.t('hp-one-support.title', '24/7 Pro live support')}
        </CardTitle>

        <HPOneSupport t={getText.t} />

        <AlignToBottomHpOne>
          <Border />
          <a
            className="full-width accessibility-links"
            href={redirectURLs.hpsupportContactURL}
            target="_blank"
            rel="noreferrer"
            data-testid="Support"
            tabIndex={0}
            onClick={() => {
              captureClickEvents(
                AnalyticsClickEventsList.HP_SUPPORT_LINK,
                subscriptionData,
                currentActivePc
              )
            }}
          >
            <CardLabelHpOne isSuspended={!isEntityEnabled}>
              {getText.t('hp-one-support.support-text', 'Contact Support')}
            </CardLabelHpOne>
            <CardLabelHpOne>
              <IconChevronRight
                size={24}
                color={!isEntityEnabled ? 'colorGray4' : 'colorHpBlue6'}
              />
            </CardLabelHpOne>
          </a>
          <CardNumberText isSuspended={!isEntityEnabled}>
            1-866-204-8618
          </CardNumberText>
        </AlignToBottomHpOne>
      </CardHpOne>
    </Container>
  )
}

export default HPOneLiveSupport
