import React from 'react'
import { ActivateButton, ActivateButtonText } from '../style'
import Modal from '@veneer/core/dist/scripts/modal'
import Button from '@veneer/core/dist/scripts/button'
import { GoogleTransitionProps } from '../../../types/services/google-details'
import GoogleActivateContent from '../GoogleContents/GoogleActivateContent'

const GoogleActivateModal: React.FunctionComponent<GoogleTransitionProps> = ({
  t,
  handleClose,
  url
}) => {
  return (
    <div data-testid="activate-modal" style={{ position: 'relative' }}>
      <Modal
        align="left"
        closeButton
        id="modal-id"
        show={true}
        onClose={() => {
          handleClose()
        }}
        footer={
          <>
            <ActivateButton>
              <Button
                appearance="secondary"
                style={{
                  borderRadius: '8px',
                  padding: '8px 20px'
                }}
                onClick={() => {
                  handleClose()
                }}
              >
                {t('CloudStorage.CancelButton', 'Cancel')}
              </Button>

              <a
                data-testid="google-activated"
                className="External-link accessibility-links"
                href={url}
              >
                <Button
                  appearance="primary"
                  style={{
                    borderRadius: '8px',
                    padding: '8px 20px',
                    color: 'colorHpBlue6'
                  }}
                >
                  <ActivateButtonText>
                    {t(
                      'CloudStorage.CActivateGoogleOne',
                      'Activate Google One'
                    )}
                  </ActivateButtonText>
                </Button>
              </a>
            </ActivateButton>
          </>
        }
        title={t(
          'CloudStorage. GoogleActivatePopUP-title',
          'Consent to activate Google One storage plan'
        )}
      >
        <GoogleActivateContent {...{ t }} />
      </Modal>
    </div>
  )
}

export default GoogleActivateModal
