import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
const {
  space6,
  colorGray0,
  space1,
  fontSize3,
  fontTextRegular,
  lineHeight3,
  colorGray7
} = tokens

export const Image = styled.img`
  padding-top: ${space6};
`

export const Card = styled.div`
  background-color: ${colorGray0};
  height: 184.25px;
  border-radius: 8px;
  padding: 18px 16px 18px 16px;
  display: flex;
  flex-flow: column;
  gap: ${space1};
  align-items: center;
  justify-content: space-between;
  @media (min-width: 500px) {
    width: 50%;
  }
  @media (min-width: 640px) {
    width: 50%;
  }
`
export const TextBlock = styled.div`
  border-top: 2px solid #ffffff;
  gap: ${space1};
  width: 100%;
  display: inline-flex;
`

export const Cardlabel = styled.div`
  font-size: ${fontSize3};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight3};
  letter-spacing: 0.02em;
  color: ${colorGray7};
  font-weight: 400;
  padding-top: 8px;
`
export const CardLabelText = styled.sup`
  font-size: ${fontSize3};
  font-family: ${fontTextRegular};
  letter-spacing: 0.02em;
  color: ${colorGray7};
  font-weight: 400;
  margin-top: 4px;
`
export const CardIconMouse = styled.div`
  margin-top: 18px;
`
