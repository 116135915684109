import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { Stack } from '../types/stratus'
import { URLS } from './urls-api'
import type { CommerceBillingDetails } from '../types/services/subscription'

export class CommerceBillingCycleClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(URLS[stack].API_URLS.COMMERCE_BILLING_CYCLE_BASE_URL, authToken)
  }

  async getCommerceBillingDetails(
    commersesubscriptionId
  ): Promise<CommerceBillingDetails> {
    try {
      return await this.jarvisWebHttpInstance
        .get({
          url: `/subscriptions/${commersesubscriptionId}/details`
        })
        .then(({ data: commerceData }) => commerceData)
    } catch (error) {
      throw new Error(`commerceData API failed ${error}`)
    }
  }
}
