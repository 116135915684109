import { Action, SimpleUiEvent } from '../../types/webanalytics'

const simpleUiEventVersion = '1.3.0'

export const uiEvents = {
  closeSuccessNotification: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'PaymentIssueResolved',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'Dismiss'
  } as SimpleUiEvent,
  googleOneUpgradeErrorNotification: {
    version: simpleUiEventVersion,
    action: Action.moduleDisplayed,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'GoogleOneUpgradeError',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  googleOneUpgradeSuccessNotification: {
    version: simpleUiEventVersion,
    action: Action.moduleDisplayed,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'GoogleOneUpgradeModal',
    screenMode: 'Confirmation',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  cancelSubscriptionModuleDisplay: {
    version: simpleUiEventVersion,
    action: Action.moduleDisplayed,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'SubscriptionHasBeenCancelled',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  modelWindowDisplay: {
    version: simpleUiEventVersion,
    action: Action.modalWindowDisplayed,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'GoogleOneUpgradeModal',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  cancelModalWindow: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'GoogleOneUpgradeModal',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'Cancel',
    actionAuxParams: ''
  } as SimpleUiEvent,
  updatePlan: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'GoogleOneUpgradeModal',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'UpdatePlan',
    actionAuxParams: ''
  } as SimpleUiEvent,
  confirmationPlan: {
    version: simpleUiEventVersion,
    action: Action.moduleDisplayed,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'GoogleOneUpgradeModal',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  closeModalWindow: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'GoogleOneUpgradeModal',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'CloseButton',
    actionAuxParams: ''
  } as SimpleUiEvent,
  PaymentIssueResolvedNotification: {
    version: simpleUiEventVersion,
    action: Action.moduleDisplayed,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'PaymentIssueResolved',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  PaymentIsOverdueNotification: {
    version: simpleUiEventVersion,
    action: Action.moduleDisplayed,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'PaymentIsOverdue',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  PaymentIsOverdueCitizenpayLink: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'PaymentIsOverdue',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'CitizensPay',
    actionAuxParams: ''
  } as SimpleUiEvent,
  PaymentIsOverdueLearnMoreLink: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'PaymentIsOverdue',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'LearnMore',
    actionAuxParams: ''
  } as SimpleUiEvent,
  SubscriptionSuspendedNotification: {
    version: simpleUiEventVersion,
    action: Action.moduleDisplayed,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'SubscriptionSuspended',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  SubscriptionSuspendedCitizensPayLink: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'SubscriptionSuspended',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'CitizensPay',
    actionAuxParams: ''
  } as SimpleUiEvent,
  SubscriptionSuspendedContactSupportLink: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'SubscriptionSuspended',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'ContactSupport',
    actionAuxParams: ''
  } as SimpleUiEvent,
  SubscriptionNoLongerActiveNotification: {
    version: simpleUiEventVersion,
    action: Action.moduleDisplayed,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'SubscriptionNoLongerActive',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  viewAllSubscription: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'Overview',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'ViewAllSubscriptions',
    actionAuxParams: ''
  } as SimpleUiEvent,
  cancelSubscription: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'Overview',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'CancelSubscription',
    actionAuxParams: ''
  } as SimpleUiEvent,
  Support: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'Overview',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'Support',
    controlDetail: 'HpOneSupport',
    actionAuxParams: ''
  } as SimpleUiEvent,
  notificationControlButtonDismiss: {
    version: simpleUiEventVersion,
    action: Action.controlButtonClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: '',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'Dismiss',
    actionAuxParams: ''
  } as SimpleUiEvent,
  LoadingDataIssueNotification: {
    version: simpleUiEventVersion,
    action: Action.moduleDisplayed,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'LoadingDataIssueNotification',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/'
  } as SimpleUiEvent,
  modalScreenDisplayed: {
    version: simpleUiEventVersion,
    action: Action.screenDisplayed,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'Overview',
    screenPath: '/ReactHpxSubscriptionOverview/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  ManagePayments: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'Overview',
    screenPath: '/ReactHpxSubscriptionOverview/',
    controlName: 'ManagePayments',
    controlDetail: 'CitizensPay',
    actionAuxParams: ''
  } as SimpleUiEvent,
  ManageAddresses: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'Overview',
    screenPath: '/ReactHpxSubscriptionOverview/',
    controlName: 'ManageAddresses',
    controlDetail: 'ShippingAddress',
    actionAuxParams: ''
  } as SimpleUiEvent,
  GoogleLogInbutton: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'Overview',
    screenPath: '/ReactHpxSubscriptionOverview/',
    controlName: 'GoogleLogIn',
    controlDetail: 'GoogleOne',
    actionAuxParams: ''
  } as SimpleUiEvent,
  GetMoreStoragebutton: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'Overview',
    screenPath: '/ReactHpxSubscriptionOverview/',
    controlName: 'GetMoreStorage',
    controlDetail: 'GoogleOne',
    actionAuxParams: ''
  } as SimpleUiEvent,
  CancelModuleDisplayed: {
    version: simpleUiEventVersion,
    action: Action.moduleDisplayed,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'SubscriptionHasBeenCancelled',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    actionAuxParams: ''
  } as SimpleUiEvent,
  PostCancelNotificationSupportLink: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'SubscriptionHasBeenCancelled',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'VisitHpSupportPage',
    actionAuxParams: ''
  } as SimpleUiEvent,
  GoogleLogIn: {
    version: simpleUiEventVersion,
    action: Action.controlHyperLinkClicked,
    activity: 'HpOnePcMgmt-v01',
    screenName: 'Overview',
    screenPath: '/ReactHpxSubscriptionOverview/Overview/',
    controlName: 'GoogleLogIn',
    controlDetail: 'GoogleOne',
    actionAuxParams: ''
  } as SimpleUiEvent
}
