import { AnalyticsClickEventsList } from '../../constants'
import DataCollection from './index'
import { uiEvents } from './simpleUiEvents'
import { getactionAuxParams } from '../../services/getactionAuxParams'

export const captureClickEvents = async (
  eventType,
  subscriptionData,
  currentActivePC
) => {
  const actionAuxParams = await getactionAuxParams(
    subscriptionData,
    currentActivePC
  )
  let eventResult
  switch (eventType) {
    case AnalyticsClickEventsList.OVERDUE_CITIZENPAY_LINK: {
      const event = uiEvents.PaymentIsOverdueCitizenpayLink
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
    case AnalyticsClickEventsList.OVERDUE_LEARNMORE_LINK: {
      const event = uiEvents.PaymentIsOverdueLearnMoreLink
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
    case AnalyticsClickEventsList.SUSPENDED_CITIZENPAY_LINK: {
      const event = uiEvents.SubscriptionSuspendedCitizensPayLink
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
    case AnalyticsClickEventsList.SUSPENDED_CONTACTSUPPORT_LINK: {
      const event = uiEvents.SubscriptionSuspendedContactSupportLink
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
    case AnalyticsClickEventsList.VIEWALL_SUBSCRIPTION_LINK: {
      const event = uiEvents.viewAllSubscription
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
    case AnalyticsClickEventsList.CANCEL_SUBSCRIPTION_LINK: {
      const event = uiEvents.cancelSubscription
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
    case AnalyticsClickEventsList.HP_SUPPORT_LINK: {
      const event = uiEvents.Support
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
    case AnalyticsClickEventsList.MANAGE_PAYMENTS_LINK: {
      const event = uiEvents.ManagePayments
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
    case AnalyticsClickEventsList.MANAGE_ADDRESSES_LINK: {
      const event = uiEvents.ManageAddresses
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
    case AnalyticsClickEventsList.GETMORE_STORAGE_LINK: {
      const event = uiEvents.GetMoreStoragebutton
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
    case AnalyticsClickEventsList.POST_CANCEL_HP_SUPPORT_LINK: {
      const event = uiEvents.PostCancelNotificationSupportLink
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
    case AnalyticsClickEventsList.GOOGLE_LOGIN_LINK: {
      const event = uiEvents.GoogleLogIn
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
  }
  return eventResult
}
