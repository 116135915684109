import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'

import { Stack } from '../types/stratus'
import type { Address } from '../types/services/Address'
import { URLS } from './urls-api'
import { reloginOnUnauthorized } from '../utils/reloginOnUnauthorized'

export class ShippingAddressApiClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(URLS[stack].API_URLS.SHIPPING_ADDRESS_URL, authToken)
  }

  async getShippingAddressData(shippingAddressId): Promise<Address> {
    return this.jarvisWebHttpInstance
      .get({
        url: `addresssvc/addresses/${shippingAddressId}`
      })
      .then(({ data: ShippingAddress }) => ShippingAddress)
      .catch((err) => {
        reloginOnUnauthorized(err)
        throw err
      })
  }
}
