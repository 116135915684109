import DataCollection from './index'
import { uiEvents } from './simpleUiEvents'

export const captureModuleDisplayed = async (moduleName) => {
  let eventResult
  switch (moduleName) {
    case 'LoadingDataIssueNotification':
      {
        const event = uiEvents.LoadingDataIssueNotification

        eventResult = DataCollection.sendEvent(event)
      }
      return eventResult
  }
}
