import React, { useState, useEffect } from 'react'
import { useAppSelector } from '../../customHooks'

import { Card, Image, Cardlabel, TextBlock, CardLabelText } from './Style'
import { LoadingHandler } from '@jarvis/react-hpx-shared-components'
import type { Device } from '../../types/services/device'
import { DeviceCacheApiClient } from '../../services/device-cache-api'
import { IconKeyboard } from '@veneer/core/dist/scripts/icons'

const KeyboardDetails = ({ t, authProvider, stack }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [keyBoardData, setKeyBoardData] = useState<Device>(null)
  const [apiError, setApiError] = useState<boolean>(false)
  const keyboardId = useAppSelector(
    (state) => state.userSubscriptionDetails.keyboardId
  )
  useEffect(() => {
    const deviceCacheApiClient = new DeviceCacheApiClient(authProvider, stack)
    const fetch = async () => {
      try {
        const accessoriesDetailsFetch: Device = await deviceCacheApiClient.show(
          keyboardId
        )
        setApiError(false)
        setKeyBoardData(accessoriesDetailsFetch)
        setLoading(false)
      } catch (err) {
        setLoading(false)

        setApiError(true)
      }
    }
    if (keyboardId) fetch()
  }, [keyboardId, authProvider, stack])

  return loading ? (
    <LoadingHandler
      loadingText={t('loader.text', 'Loading...')}
      loading={true}
    />
  ) : (
    !apiError && (
      <Card data-testid="keyboard-details">
        {keyBoardData.images ? (
          <Image
            src={keyBoardData?.images[0]?.url}
            width="100px"
            height="62px"
            alt="Keyboard"
            data-testid="keyboardImage"
          />
        ) : (
          <IconKeyboard size={119} color="colorGray3" />
        )}
        <TextBlock>
          <Cardlabel data-testid="keyboardName">
            {keyBoardData?.identity.makeAndModel.name}
          </Cardlabel>
          <CardLabelText>ONE</CardLabelText>
        </TextBlock>
      </Card>
    )
  )
}

export default KeyboardDetails
