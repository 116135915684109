import React from 'react'
import { PreArrivalPaymentContainer, CardNotload } from './style'
import { IconCalendarDay } from '@veneer/core/dist/scripts/icons'

const PreArrivalPayment = ({ children }) => {
  return (
    <PreArrivalPaymentContainer data-testid="pre-arrival-payment">
      <div>
        <div style={{ textAlign: 'center' }}>
          <IconCalendarDay size={32} color="colorGray7" />
        </div>
        <CardNotload>{children}</CardNotload>
      </div>
    </PreArrivalPaymentContainer>
  )
}

export default PreArrivalPayment
