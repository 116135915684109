import React, { FC, useEffect } from 'react'
import { captureToastDisplayEvents } from '../../services/DataCollection/notificationDisplayAction'
import { captureNotificationDismissEvent } from '../../services/DataCollection/notificationDismissEvent'
import { NotificationBar } from '@jarvis/react-hpx-shared-components'
import { INotification } from 'src/types'
import type { Subscription } from '../../types/services/subscription'
import { useAppSelector } from '../../customHooks'
import { Entitlements } from 'src/types/services/subscription'
const NewNotification: FC<INotification> = ({
  statusType,
  title,
  description,
  notificationName,
  showCloseButton,
  onClose,
  className = '',
  hideIcon
}) => {
  const subscriptionData: Subscription = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )
  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )
  const handleClose = () => {
    onClose && onClose()
    if (!notificationName) return
    captureNotificationDismissEvent(
      subscriptionData,
      notificationName,
      currentActivePc
    )
  }

  useEffect(() => {
    const captureAnalytics = async () => {
      captureToastDisplayEvents(
        subscriptionData,
        notificationName,
        currentActivePc
      )
    }
    if (subscriptionData && notificationName) captureAnalytics()
  }, [notificationName, subscriptionData])

  return (
    <NotificationBar
      statusType={statusType}
      title={title}
      description={description}
      showCloseButton={showCloseButton}
      onClose={handleClose}
      className={className}
      hideIcon={hideIcon}
    />
  )
}

export default NewNotification
