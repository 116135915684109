import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'

import { Stack } from '../types/stratus'
import type { Subscription } from '../types/services/subscription'
import { URLS } from './urls-api'

export class SubscriptionApiClient extends StratusClient {
  constructor(
    authToken: JarvisAuthProvider,
    stack: Stack,
    googleActivate?: boolean
  ) {
    super(
      googleActivate
        ? URLS[stack].API_URLS.GOOGLE_ACTIVATE_URL
        : URLS[stack].API_URLS.SUBSCRIPTION_BASE_URL,
      authToken
    )
  }

  async getAllSubscriptionDetails() {
    try {
      return this.jarvisWebHttpInstance
        .get({
          url: `/357e06c1-a567-42ba-9548-d57f37e34f0f/subscriptions`
        })
        .then(({ data: subsriptionDetails }) => subsriptionDetails)
    } catch (error) {
      throw new Error(`Subscription API failed ${error}`)
    }
  }

  async getSubscriptionDetailsData(): Promise<Subscription> {
    try {
      const allSubcriptions = await this.getAllSubscriptionDetails()
      return this.jarvisWebHttpInstance
        .get({
          url: `/357e06c1-a567-42ba-9548-d57f37e34f0f/subscriptions/${allSubcriptions[0].subscriptionId}`
        })
        .then(({ data: subsriptionDetails }) => subsriptionDetails)
    } catch (error) {
      throw new Error(`Subscription API failed ${error}`)
    }
  }
  async getEntityDetails(
    subscriptionId,
    googleStorageEntityId
  ): Promise<Subscription> {
    try {
      const {
        data: googleSubscriptionDetails
      } = await this.jarvisWebHttpInstance.get({
        url: `/357e06c1-a567-42ba-9548-d57f37e34f0f/subscriptions/${subscriptionId}/options?entityIds=${googleStorageEntityId}`
      })
      return googleSubscriptionDetails
    } catch (error) {
      throw new Error(`Subscription API failed ${error}`)
    }
  }

  async changeGoogleStorage(
    subcriptionID,
    googleStorageEntityId,
    optionId
  ): Promise<any> {
    const upgradedData = {
      entities: [
        {
          entityId: googleStorageEntityId,
          edit: {
            apply: {
              type: 'immediately',
              value: {
                optionId: optionId
              }
            }
          }
        }
      ]
    }

    if (!this.jarvisWebHttpInstance) {
      throw new Error('HTTP client not initialized')
    }

    const response = await this.jarvisWebHttpInstance.patch({
      url: `/357e06c1-a567-42ba-9548-d57f37e34f0f/subscriptions/${subcriptionID}`,
      data: upgradedData
    })
    return response
  }

  // https://stratus-pie.tropos-rnd.com/google-lifecycle-controller/v1/subscriptions/{subscriptionId}/activate
  // try {
  //   return response?.data
  // } catch (error) {
  //   return { status: error.response.status, data: error.response.data }
  // }
  async activateGoogleStoragePlan(
    subscriptionId,
    authCode,
    clientId,
    redirectUrl
  ): Promise<any> {
    const response = await this.jarvisWebHttpInstance.post({
      url: `/google-lifecycle-controller/v1/subscriptions/${subscriptionId}/activate`,
      data: {
        authCode,
        clientId,
        redirectUrl
      }
    })
    return response
  }
}
