import { gql } from '@apollo/client'

export const GET_BILLING_DETAILS = gql`
  query getCitizensBillingDetails($orderId: Int!) {
    getCitizensBillingDetails(orderId: $orderId) {
      CreditLimitAmount
      AvailableCreditAmount
      CurrentAccountBalanceAmount
      CurrentTotalBalanceAmounts
      CustomerIsDelinquent
      PaymentDueThisMonth
      PaymentDueDate
    }
  }
`

export const GET_CLOUD_PLAN_DETAILS = gql`
  query getCloudPlanDetails($parentSKU: String!) {
    products(filter: { sku: { eq: $parentSKU } }) {
      items {
        name
        sku
        ... on BundleProduct {
          hp_max_days_to_return
          items {
            options {
              product {
                hp_product_type_label
                hp_product_type
                image {
                  url
                }
                thumbnail {
                  url
                }
                sku
                ... on VirtualProduct {
                  hp_storage_uom_label
                  hp_storage_uom
                  hp_storage_amount
                  price {
                    regularPrice {
                      amount {
                        value
                      }
                    }
                  }
                }
              }
              label
            }
          }
        }
      }
    }
  }
`
export const GET_DEVICE_DETAILS = gql`
  query getDeviceDetails($parentSKU: String!) {
    products(filter: { sku: { eq: $parentSKU } }) {
      items {
        name
        sku
        ... on BundleProduct {
          hp_max_days_to_return
          items {
            title
            options {
              product {
                hp_product_type_label
                hp_product_type
                thumbnail {
                  url
                }
                sku
              }
              label
            }
          }
        }
      }
    }
  }
`
