import React from 'react'
import {
  ErrorContainer,
  InsideCard,
  CardErrorText,
  CardErrorImage,
  CardErrorLabel,
  Image
} from './style'
import Images from '../../assets/images'

const GoogleCloudStorageAPIError = ({ t }) => {
  return (
    <ErrorContainer data-testid="error-container">
      <InsideCard>
        <CardErrorImage>
          <Image
            data-testid="Image"
            src={Images.GoogleOneLogo}
            width="114"
            height="26"
            alt={t('Cloudone.alt', 'Cloudone')}
            style={{ opacity: 0.5 }}
          />
        </CardErrorImage>

        <CardErrorText>
          {t(
            'CloudStorage.Content-error',
            'Something went wrong. Your storage information did not load.'
          )}
        </CardErrorText>
        <CardErrorLabel>
          {t('CloudStorage.Content-error2', 'Please refresh the page.')}
        </CardErrorLabel>
      </InsideCard>
    </ErrorContainer>
  )
}

export default GoogleCloudStorageAPIError
