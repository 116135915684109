import React from 'react'
import { NotificationBar } from '@jarvis/react-hpx-shared-components'
import { CardDescription } from './style'
import SupportAnchorLink from '../SupportAnchorLink'
import { EntityType } from '../../../constants'
const CountDownStage = ({ t, notificationData, subscriptionData }) => {
  /* istanbul ignore next */
  const printerData = subscriptionData?.entities?.filter(
    (item) => item?.entityType === EntityType.PRINTER
  )[0]
  const title = (
    <>
      {notificationData.isHybridUser && notificationData.remorsePeriod ? (
        <div>
          <p>
            {t(
              'PostCancelNotification-Hybrid.CountDownStage.message',
              'Your HP All-In Plans have been cancelled. Please return your devices '
            )}
            {notificationData.timeoutInDays > 0 ? (
              <>
                {t(
                  'PostCancelNotification-Hybrid.CountDownStage.within-text',
                  'within '
                )}
                {notificationData.timeoutInDays}{' '}
                {t(
                  'PostCancelNotification-Hybrid.CountDownStage.day-text',
                  'days.'
                )}
              </>
            ) : (
              t(
                'PostCancelNotification-Hybrid.CountDownStage.today-text',
                'today.'
              )
            )}
          </p>
        </div>
      ) : (
        <div>
          <p>
            {t(
              'PostCancelNotification-PC.CountDownStage.message',
              'Your HP All-In Laptop Plan has been cancelled. Please return your laptop '
            )}
            {notificationData.timeoutInDays > 0 ? (
              /* istanbul ignore next */
              <>
                {t(
                  'PostCancelNotification-PC.CountDownStage.within-text',
                  'within '
                )}
                {notificationData.timeoutInDays}{' '}
                {t(
                  'PostCancelNotification-PC.CountDownStage.day-text',
                  'days.'
                )}
              </>
            ) : (
              /* istanbul ignore next */
              t('PostCancelNotification-PC.CountDownStage.today-text', 'today.')
            )}
          </p>
        </div>
      )}
    </>
  )
  const description = (
    /* istanbul ignore next */
    <>
      {notificationData.isHybridUser && notificationData.remorsePeriod ? (
        <div>
          <p>
            {t(
              'PostCancelNotification-Hybrid.CountDownStage.desc',
              'Avoid being charged the full purchase price by shipping your HP All-In Plan laptop'
            )}{' '}
            (
            {t(
              'PostCancelNotification-Hybrid.CountDownStage.serial',
              'serial #'
            )}
            {notificationData.serialNumber}){' '}
            {t('PostCancelNotification-Hybrid.printer', 'and printer')} (
            {t(
              'PostCancelNotification-Hybrid.CountDownStage.serial',
              'serial #'
            )}
            {printerData?.entityDetails?.serialNumber}){' '}
            {notificationData.timeoutInDays === 10
              ? t(
                  'PostCancelNotification-Hybrid.CountDownStage.description-ten-days',
                  'back to HP immediately. You will receive an email with return shipping details shortly.'
                )
              : t(
                  'PostCancelNotification-Hybrid.CountDownStage.description-other',
                  'back to HP immediately. We emailed your address on file with return shipping instructions.'
                )}
          </p>
          <CardDescription>
            {t(
              'PostCancelNotification-Hybrid.CountDownStage.description-large',
              'If you have already returned these devices, please refer to your tracking information for more details.'
            )}
          </CardDescription>
          <p>
            {t(
              'PostCancelNotification-Hybrid.CountDownStage.description-short',
              'For assistance, visit'
            )}{' '}
            <SupportAnchorLink {...{ t }} />
          </p>
        </div>
      ) : (
        <div>
          <p>
            {t(
              'PostCancelNotification-PC.CountDownStage.desc',
              'Avoid being charged the full purchase price by shipping your HP All-In Plan laptop'
            )}{' '}
            ({t('PostCancelNotification-PC.CountDownStage.serial', 'serial')}{' '}
            {notificationData.serialNumber}){' '}
            {notificationData.timeoutInDays === 10
              ? t(
                  'PostCancelNotification-PC.CountDownStage.description-ten-days',
                  'back to HP immediately. You will receive an email with return shipping details shortly.'
                )
              : t(
                  'PostCancelNotification-PC.CountDownStage.description-other',
                  'back to HP immediately. We emailed your address on file with return shipping instructions.'
                )}
          </p>
          <CardDescription>
            {t(
              'PostCancelNotification-PC.CountDownStage.description-large',
              'If you have already returned this laptop, please refer to your tracking information for more details.'
            )}
          </CardDescription>
          <p>
            {t(
              'PostCancelNotification-PC.CountDownStage.description-short',
              'For assistance, visit'
            )}{' '}
            <SupportAnchorLink {...{ t }} />
          </p>
        </div>
      )}
    </>
  )

  return (
    <div data-testid="CountDownStage">
      <NotificationBar
        statusType="negative"
        title={title}
        description={description}
        showCloseButton={false}
      />
    </div>
  )
}

export default CountDownStage
