export const currencyCodeByLocale = {
  'en-GB': 'GBP',
  'en-IE': 'EUR',
  'nl-NL': 'EUR',
  'en-US': 'USD',
  'en-CA': 'CAD',
  'en-PR': 'USD',
  'en-SG': 'SGD',
  'en-HK': 'HKD',
  'zh-HK': 'HKD',
  'zh-TW': 'TWD',
  'en-CN': 'CNY',
  'zh-CN': 'CNY',
  'fr-CA': 'CAD',
  'fr-FR': 'EUR',
  'de-DE': 'EUR',
  'es-ES': 'EUR',
  'it-IT': 'EUR',
  'fr-BE': 'EUR',
  'nl-BE': 'EUR',
  'fr-LU': 'EUR',
  'pt-PT': 'EUR',
  'lt-LT': 'EUR',
  'et-EE': 'EUR',
  'lv-LV': 'EUR',
  'el-GR': 'EUR',
  'en-MT': 'EUR',
  'el-CY': 'EUR',
  'sl-SI': 'EUR',
  'de-AT': 'EUR',
  'fi-FI': 'EUR',
  'sk-SK': 'EUR',
  'sv-SE': 'SEK',
  'no-NO': 'NOK',
  'da-DK': 'DKK',
  'de-CH': 'CHF',
  'fr-CH': 'CHF',
  'it-CH': 'CHF',
  'cs-CZ': 'CZK',
  'pl-PL': 'PLN',
  'hu-HU': 'HUF',
  'ro-RO': 'RON',
  'hr-HR': 'HRK',
  'bg-BG': 'BGN',
  'pt-BR': 'BRL'
}

export function FormatCurrency(amount) {
  if (!amount && amount !== 0) return ''
  const locale = getLocale()
  const currencyCode = currencyCodeByLocale[locale]

  if (locale && currencyCode) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode
    }).format(amount)
  }

  return amount
}

function getLocale() {
  const localLanguage = navigator.language
  const defaultLocale = localStorage.getItem(localLanguage)
    ? localStorage.getItem(localLanguage)
    : 'en-US' // English is the default locale if none is set
  return defaultLocale
}
