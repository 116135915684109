import React from 'react'
import { CardTitle, CardContent, Container, CardButton } from './style'
import Button from '@veneer/core/dist/scripts/button'
import { FC, useEffect } from 'react'
import { captureModuleDisplayed } from '../../services/DataCollection/moduleDisplayEvent'

import { TranslatorFunctionType } from 'src/types/localization'
interface IProps {
  getText: { t: TranslatorFunctionType }
  refetchApiFn: () => void
}
const ApiErrorState: FC<IProps> = ({ getText, refetchApiFn }) => {
  useEffect(() => {
    const captureEvents = async () => {
      captureModuleDisplayed('LoadingDataIssueNotification')
    }
    captureEvents()
  }, [])

  const handleClick = () => {
    refetchApiFn()
  }
  return (
    <Container>
      <CardTitle>
        {getText.t('ApiErrorState.title', 'Something went wrong')}
      </CardTitle>
      <CardContent>
        {getText.t(
          'ApiErrorState.information',
          'Your account information did not load.'
        )}
      </CardContent>
      <CardButton>
        <Button appearance="secondary" data-testid="id" onClick={handleClick}>
          {getText.t('ApiErrorState.try-again', 'Try again')}
        </Button>
      </CardButton>
    </Container>
  )
}

export default ApiErrorState
