import React, { useEffect, useState, FC } from 'react'
import moment from 'moment'
import { IconDocument } from '@veneer/core/dist/scripts/icons'
import { Card, CardTitle, Container, CardDocument } from './style'
import { TranslatorFunctionType } from '../../types/localization'
import type {
  Subscription,
  Entitlements
} from '../../types/services/subscription'
import { useAppSelector } from '../../customHooks'
import { SubscriptionDetails as JarvisSubscriptionDetails } from '@jarvis/react-hpx-shared-components'

interface IProps {
  getText: { t: TranslatorFunctionType }
}

const PlanDetails: FC<IProps> = ({ getText }) => {
  const [subscriptionStartDate, setSubscriptionStartDate] = useState<string>('')
  const subscriptionData: Subscription = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )

  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )
  useEffect(() => {
    const fetch = async () => {
      if (currentActivePc) {
        const subscriptionStartDate = currentActivePc.entityStartDate

        const subscriptionDate = moment(subscriptionStartDate).format(
          'MMM DD, YYYY'
        )
        setSubscriptionStartDate(subscriptionDate)
      }
    }
    if (subscriptionData.entities?.length) fetch()
  }, [subscriptionData])

  return (
    <Card>
      <Container data-testid="subscription-details">
        <CardDocument>
          <IconDocument size={32} color="colorGray7" />
        </CardDocument>
        <CardTitle>
          {getText.t('subscription-details.title', 'Plan details')}
        </CardTitle>

        <JarvisSubscriptionDetails
          t={getText.t}
          subscriptionId={subscriptionData.friendlySubscriptionId}
          subscriptionStartDate={subscriptionStartDate}
        />
      </Container>
    </Card>
  )
}

export default PlanDetails
