import React, { useEffect } from 'react'
import { APINotificationList, SubscriptionPaymentStatus } from '../../constants'
import { useAppDispatch } from '../../customHooks'
import { addNotification } from '../../stores/api-notifications'
import { CardTitle, CardDescription, CardAnchorTag } from './style'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { redirectURLs } from '../../configs/external-link'
import { setEntityState } from '../../stores/subscription'

const PaymentNotification = ({ t }) => {
  const dispatch = useAppDispatch()
  const flags = useFlags()

  useEffect(() => {
    const showPaymentNotification = () => {
      paymentData(flags.delinquencyStatus)
      if (
        flags.delinquencyStatus ===
          SubscriptionPaymentStatus.SUSCRIPTION_SUSPENDED ||
        flags.delinquencyStatus ===
          SubscriptionPaymentStatus.SUBSCRIPTION_INACTIVE
      ) {
        dispatch(setEntityState({ isEntityEnabled: false }))
      } else {
        dispatch(setEntityState({ isEntityEnabled: true }))
      }
    }
    if (flags != null) showPaymentNotification()
  }, [flags])

  const paymentData = (state) => {
    let notification
    switch (state) {
      case SubscriptionPaymentStatus.PAYMENT_RESOLVED: {
        notification = {
          statusType: 'positive',
          title: (
            <>
              <CardTitle>
                {t(
                  'NotificationsCard.success.title',
                  'Your HP All-In payment issue has been resolved '
                )}
              </CardTitle>
              <CardDescription>
                {t(
                  'NotificationsCard.success.subtitle',
                  'Your plan(s) are now active'
                )}
              </CardDescription>
            </>
          ),
          description: '',
          notificationName: APINotificationList.PAYMENT_RESOLVED,
          showCloseButton: true,
          className: 'notificationCard'
        }
        break
      }
      case SubscriptionPaymentStatus.PAYMENT_OVERDUE: {
        notification = {
          statusType: 'warning',
          title: (
            <>
              <CardTitle>
                {t(
                  'NotificationsCard.overdue.title',
                  'Your HP All-In payment is overdue'
                )}
              </CardTitle>
              <CardDescription>
                {t(
                  'NotificationsCard.overdue.subtitle1',
                  'You are at risk of losing access to the benefits of your plan(s), including device replacement and 24/7 live support. Please visit '
                )}
                <a
                  href={redirectURLs.payEarlyURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t(
                    'NotificationsCard.suspended.citizenPayLabel',
                    'Citizens Pay '
                  )}
                </a>
                {t(
                  'NotificationsCard.overdue.subtitle2',
                  'to update your payment information.'
                )}
              </CardDescription>
              <CardAnchorTag>
                <a>
                  {t(
                    'NotificationsCard.overdue.secondarySubtitle',
                    'Learn more about your potential service suspension.'
                  )}
                </a>
              </CardAnchorTag>
            </>
          ),
          description: '',
          notificationName: APINotificationList.PAYMENT_OVERDUE,
          showCloseButton: true,
          className: 'notificationCard'
        }
        break
      }
      case SubscriptionPaymentStatus.SUSCRIPTION_SUSPENDED: {
        notification = {
          statusType: 'negative',
          title: (
            <>
              <CardTitle>
                {t(
                  'NotificationsCard.suspended.title',
                  'Your HP All-In Plan has been suspended'
                )}
              </CardTitle>
              <CardDescription>
                {t(
                  'NotificationsCard.suspended.subtitle1',
                  'Your plan(s) have been suspended due to nonpayment. Please visit '
                )}
                <a
                  href={redirectURLs.payEarlyURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t(
                    'NotificationsCard.suspended.citizenPayLabel',
                    'Citizens Pay '
                  )}
                </a>
                {t(
                  'NotificationsCard.suspended.subtitle2',
                  'to resolve the issue. If you believe this is in error, visit '
                )}
                <a
                  href={redirectURLs.hpsupportPCaaSURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('NotificationsCard.suspended.hpLabel', 'HP Support ')}
                </a>
                .
              </CardDescription>
            </>
          ),
          description: '',
          notificationName: APINotificationList.SUSCRIPTION_SUSPENDED,
          showCloseButton: true,
          className: 'notificationCard'
        }
        break
      }
      case SubscriptionPaymentStatus.SUBSCRIPTION_INACTIVE: {
        notification = {
          statusType: 'negative',
          title: (
            <>
              <CardTitle>
                {t(
                  'NotificationsCard.inactive.title',
                  'Your HP All-In Plan is no longer active'
                )}
              </CardTitle>
              <CardDescription>
                {t(
                  'NotificationsCard.inactive.subtitle',
                  'Your plan(s) were terminated due to nonpayment.'
                )}
              </CardDescription>
            </>
          ),
          description: '',
          notificationName: APINotificationList.SUBSCRIPTION_INACTIVE,
          showCloseButton: true,
          className: 'notificationCard'
        }
      }
    }
    if (notification != null) dispatch(addNotification(notification))
  }

  return <div data-testid="PaymentNotification"></div>
}

export default PaymentNotification
