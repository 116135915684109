import React from 'react'
import { NotificationBar } from '@jarvis/react-hpx-shared-components'

const InProcess = ({ t, notificationData }) => {
  const title = (
    <>
      {notificationData.isHybridUser && notificationData.remorsePeriod
        ? t(
            'PostCancelNotification-Hybrid.InProcess.title',
            'Your HP All-In Plans cancellation is in progress.'
          )
        : t(
            'PostCancelNotification-PC.InProcess.title',
            'Your HP All-In Laptop Plan cancellation is in progress.'
          )}
    </>
  )

  return (
    <div data-testid="InProcess">
      <NotificationBar
        statusType="warning"
        title={title}
        showCloseButton={false}
      />
    </div>
  )
}

export default InProcess
