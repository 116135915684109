import { APINotificationList } from '../../constants'
import DataCollection from './index'
import { uiEvents } from './simpleUiEvents'
import { getactionAuxParams } from '../getactionAuxParams'

export const captureNotificationDismissEvent = (
  subscriptionData,
  apiNotificationName,
  currentActivePC
) => {
  const actionAuxParams = getactionAuxParams(subscriptionData, currentActivePC)
  const event = uiEvents.notificationControlButtonDismiss
  event.actionAuxParams = actionAuxParams

  switch (apiNotificationName) {
    case APINotificationList.GOOGLE_UPGRADE_ERROR: {
      event.screenName = 'GoogleOneUpgradeError'
      break
    }
  }
  const eventResult = DataCollection.sendEvent(event)
  return eventResult
}
