export interface SimpleUiEvent {
  /**
   * Version ex (1.0.0)
   * https://schemaregistry.cso-hp.com/browse?contains=com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1
   */
  version: string
  /**
   * Named UI action
   */
  // action: Action;
  action: string
  /**
   * Activity (workflow) name
   */
  activity?: string
  /**
   * UI screen name
   */
  screenName: string
  /**
   * UI screen path  in PascalCase separated by /..
   * Note:  This is NOT a breadcrumb model for tracking all previous pages
   * Example '/NetworkSetup/Foo/'
   */
  screenPath?: string
  /**
   * UI screen mode
   */
  screenMode?: string
  /**
   * UI Control Label or if not available the UI Control accessibility identifier
   */
  controlName?: string
  /**
   * Control detail (example URL for hyperlink, or other relevent data).
   * Note:  Do NOT send PII data as URL params within this field.
   * Example 'https://www.hp.com/xxx'
   */
  controlDetail?: string

  // "Auxiliary Parameters to provide additional information regarding Actions in cgi/form encoded.
  actionAuxParams?: string
}
/**
 * The action performed as part of the event
 */
export enum Action {
  screenDisplayed = 'ScreenDisplayed',
  controlButtonClicked = 'ControlButtonClicked',
  controlTileClicked = 'ControlTileClicked',
  controlHyperLinkClicked = 'ControlHyperLinkClicked',
  modalWindowDisplayed = 'ModalWindowDisplayed',
  controlAccordianExpanded = 'ControlAccordianExpanded',
  controlAccordianCollapsed = 'ControlAccordianCollapsed',
  controlDisplayed = 'ControlDisplayed',
  controlToggleEnabled = 'ControlToggleEnabled',
  controlToggleDisabled = 'ControlToggleDisabled',
  moduleInitialized = 'ModuleInitialized',
  moduleDisplayed = 'ModuleDisplayed'

  // source: https://schemaregistry.cso-hp.com/browse?contains=com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1
  // version 1.3.0
}

export type DataValveCDMEvent = {
  /**
   * Example: 2020-05-11T22:30:22.7Z
   * Description: RFC 3339 formated date. YYYY-MM-DD'T':HH:mm:SS.sssZ
   * */
  dateTime: string
  /**
   * Example: “com.hp.cdm.platform.software.domain.eventing.resource.eventDetail.type.simpleUi.version.1”
   * Description: The GUN that applies to the event
   * */
  eventDetailType: string
  /**
   * Example: “simpleUi”
   * */
  eventCategory: string
  /**
   * Example: 1.0.0
   * Description: The version of the GUN (eventDetailType) that applies to the event.
   * */
  version: string
  /**
   * Example: SysInfo example: “{"version": "1.0.0", "osArchitecture": "bit_64"…}
   * Description: The event detail which must comply to the specified `eventDetailType` schema.
   * CDM Schemas definitions can be found in the Schema Registry.  See https://rndwiki.inc.hpicorp.net/confluence/display/pepto/Schema+Registry+User+Guide
   * */
  // eslint-disable-next-line @typescript-eslint/ban-types
  eventDetail: object
}
