import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'

import { Stack } from '../types/stratus'
import { URLS } from './urls-api'

export class GrantControllerApiClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(URLS[stack].API_URLS.GRANT_BASE_URL, authToken)
  }

  async getActivePcDetails(deviceId) {
    return this.jarvisWebHttpInstance
      .get({
        url: `grant-controller/grants?level=DEVICE&grant=ws-hp.com/pcaas&deviceId=${deviceId}`
      })
      .then(({ data: activePcDetails }) => activePcDetails)
  }

  async getGrantForGoogle(tenantId) {
    return this.jarvisWebHttpInstance
      .get({
        url: `grant-controller/pending-grants?grant=ws-hp.com/google1&tenantId=${tenantId}`
      })
      .then(({ data: googleGrantDetails }) => googleGrantDetails)
  }

  async getGrantForMS(tenantId) {
    return this.jarvisWebHttpInstance
      .get({
        url: `grant-controller/pending-grants?grant=ws-hp.com/ms365&tenantId=${tenantId}`
      })
      .then(({ data: googleGrantDetails }) => googleGrantDetails)
  }
}
