import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
const {
  colorGray0,
  space1,
  space6,
  space4,
  fontTextRegular,
  colorGray7,
  fontSize5,
  fontSize2,
  fontSize1,
  colorGray5,
  fontSize3,
  lineHeight3,
  space2,
  space3
} = tokens
export const Card = styled.div`
  height: ${(props) => (props.loadingHeight ? '316px' : 'auto')};
  width: 100%;
  background: ${colorGray0};
  gap: ${space1};
  border-radius: 8px;
  position: relative;
`
export const FlexSpaceBetween = styled.div`
  padding: 16px;
  @media (min-width: 640px) {
    padding: ${space4};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const Image = styled.img`
  padding-bottom: ${space4};
  height: 42px;
  width: 60px;
`
export const CardContainer = styled.div`
  font-family: ${fontTextRegular};
`
export const CardTitle = styled.p`
  color: ${colorGray7};
  font-size: ${fontSize5};
  line-height: 28px;
`

export const LaptopImage = styled.div`
  img {
    height: auto;
    width: 100%;
    @media (min-width: 640px) {
      max-width: 312px;
      max-height: 220px;
    }
  }
`
export const CardlabelSmall = styled.p`
  font-size: ${fontSize1};
  color: ${colorGray5};
  margin-bottom: ${space1};
  line-height: 16px;
`
export const Cardspace = styled.div``
export const CardLaptopIcon = styled.div`
  text-align: center;
`

export const CardIconLaptop = styled.div`
  @media (min-width: 768px) {
    margin-right: 48px;
  }
  @media (min-width: 992px) {
    margin-right: 140px;
  }
`
export const CardspaceLaptop = styled.div`
  padding-bottom: 16px;
`
export const CardNotload = styled.p`
  text-align: center;
  color: ${colorGray5};
  font-size: ${fontSize3};
  padding-bottom: ${space4};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight3};
  &:last-child {
    padding-bottom: 0;
  }
`

export const CardContent = styled.p`
  font-size: ${fontSize1};
  color: ${colorGray7};
  display: inline-flex;
  margin-bottom: ${space3};
  align-items: center;
  gap: 2px;
  line-height: 16px;
`
export const CardError = styled.div`
  padding: 50px 16px;
  opacity: 0.5;
  @media (min-width: 768px) {
    padding-top: 75px;
    padding-bottom: 75px;
  }
`
export const CardLabelText = styled.sup`
  font-size: ${fontSize2};
  font-family: ${fontTextRegular};
  color: ${colorGray7};
  vertical-align: text-top;
`
export const CardText = styled.sup`
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  font-family: ${fontTextRegular};
  color: ${colorGray7};
`
export const TextBlock = styled.div`
  gap: ${space1};
  display: inline-flex;
`
export const CardTextNotload = styled.p`
  text-align: center;
  color: ${colorGray7};
  font-size: ${fontSize3};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight3};
`
