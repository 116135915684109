import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
const {
  fontTextRegular,
  colorWhite,
  fontSize4,
  lineHeight4,
  lineHeight3,
  fontSize2,
  fontSize3,
  fontSize1,
  fontSize5,
  lineHeight2,
  lineHeight5,
  colorGray7,
  space2,
  space1,
  colorGray5,
  space4,
  colorGray0
} = tokens
export const Card = styled.div`
  background-color: ${colorWhite};
  width: 100%;
  height: 267px;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  gap: ${space2};
  @media (min-width: 641px) {
    width: calc(50% - 16px);
    min-width: calc(50% - 16px);
    flex: calc(50% - 16px);
  }
  @media (min-width: 1008px) {
    width: calc(33.33% - 16px);
    min-width: calc(33.33% - 16px);
    flex: calc(33.33% - 16px);
  }
`
export const CardTitle = styled.p`
  font-size: ${fontSize4};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight4};
  color: ${colorGray7};
  margin-bottom: ${space1};
  @media (min-width: 1008px) {
    font-size: ${fontSize5};
    line-height: ${lineHeight5};
  }
`
export const Cardlabel = styled.p`
  font-size: ${fontSize2};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight3};
  margin-top: ${space1};
  color: ${colorGray7};
  letter-spacing: 0.03em;
`
export const CardlabelMedium = styled.p`
  font-size: ${fontSize3};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight3};
  margin-top: 5px;
  color: ${colorGray7};
  letter-spacing: 0.02em;
`
export const CardlabelMediumText = styled.p`
  font-size: ${fontSize3};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight4};
  margin-top: 5px;
  color: ${colorGray7};
  letter-spacing: 0.02em;
`
export const CardlabelSmall = styled.div`
  font-size: ${fontSize1};
  font-family: ${fontTextRegular};
  display: inline-flex;
  gap: 5px;
  line-height: ${lineHeight2};
  color: ${colorGray5};
  letter-spacing: 0.04em;
`
export const CardlabelContent = styled.div`
  font-size: ${fontSize1};
  font-family: ${fontTextRegular};
  display: inline-flex;
  gap: 5px;
  line-height: ${lineHeight2};
  color: ${colorGray7};
  letter-spacing: 0.04em;
`
export const CardContentSmall = styled.div`
  font-size: ${fontSize1};
  font-family: ${fontTextRegular};
  gap: 5px;
  color: ${colorGray7};
  line-height: ${lineHeight2};
  letter-spacing: 0.04em;
`

export const FlexSpaceBetween = styled.div`
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
`
export const FlexSpace = styled.div`
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 26px;
  padding-bottom: ${(props) => (!props.paddingBottom ? '0' : '26px')};
`

export const FlexSpaceContent = styled.div`
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 78px;
`
export const Border = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 7px;
  margin-bottom: 4px;
`
export const AlignToBottom = styled.div`
  margin-top: 94px;
  @media (min-width: 1008px) {
    margin-top: 90px;
  }
`
export const CardCalender = styled.div``
export const Container = styled.div`
  padding: ${space4};
  height: 100%;
`
export const ErrorContainer = styled.div`
  background-color: ${colorWhite};
  height: 100%;
  padding: 16px;
`
export const InsideCard = styled.div`
  background-color: ${colorGray0};
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-self: stretch;
`
export const CardErrorTextOne = styled.div`
  font-family: ${fontTextRegular};
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSize3};
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${colorGray7};
  margin-left: 16px;
  margin-right: 16px;
`
export const CardErrorTextTwo = styled.div`
  font-family: ${fontTextRegular};
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSize3};
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${colorGray7};
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 20px;
`
