import React, { useEffect, useState } from 'react'
import SubscriptionOverview from '../src/components/SubscriptionOverview'
import OnboardingService from '../src/components/OnboardingService'
import { MfePropsType } from '../src/types/mfeProps'
import projectNames from '../src/configs/projectNames'
import { TranslatorFunctionType } from '../src/types/localization'
import resources from '../src/assets/locale'
import { Provider } from 'react-redux'
import { store } from '../src/stores/index'
import { ApolloProvider } from '@apollo/client'
import { createApolloClient } from '../src/services/ApolloClient'
import { LDProvider } from 'launchdarkly-react-client-sdk'

type SubscriptionOverviewProps = {
  country?: string
  language?: string
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({
  // used only to test onboarding services
  useOnboardinServiceExample,
  ...props
}: MfePropsType & SubscriptionOverviewProps) {
  // You can find all Shell´s props here. See "../src/types/shell" for more details.
  const [t, setT] = useState<TranslatorFunctionType>()
  const [authToken, setAuthToken] = useState<string>('')
  const interfaces = window.Shell.v1
  const Component = useOnboardinServiceExample
    ? OnboardingService
    : SubscriptionOverview

  const graphqlClient = createApolloClient(authToken, props?.stack)

  // Create a translator function by providing the resource files
  useEffect(() => {
    interfaces.localization
      .createTranslatorFunction(resources)
      .then((v) => setT(() => v))
    if (interfaces?.authProvider) {
      interfaces?.authProvider?.getAccessToken().then((res) => {
        if (res?.length) setAuthToken(res)
      })
    }
  }, [interfaces])

  // Wait for the translator function creation to show the content
  if (!t) {
    return null
  }

  // You can find the "stack" information by props
  // const stack = props.stack.

  return (
    props.properties.launchDarklyClientId && (
      <section
        className={`${projectNames.namespace}`}
        id={projectNames.packageJsonName}
      >
        <LDProvider
          clientSideID={
            props.properties?.launchDarklyClientId || '624769cf7b765b1471f2d115'
          }
          user={{ key: 'test@email.com' }}
        >
          <Provider store={store}>
            <ApolloProvider client={graphqlClient}>
              <Component {...{ ...props, ...interfaces, t }} />
            </ApolloProvider>
          </Provider>
        </LDProvider>
      </section>
    )
  )
}
