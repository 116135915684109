export enum EPreaArrivalState {
  PENDING = 'pending'
}

export enum EntityType {
  PC = 'iot-pc',
  CHROMEBOOK = 'chromebook',
  KEYBOARD = 'keyboard',
  MOUSE = 'mouse',
  THIRDPARTY = 'third-party-software',
  PRINTER = 'iot-printer'
}

export enum ThirdPartyType {
  GOOGLE = 'googleOne',
  MICROSOFT = 'ms365',
  TMOBILE = 'connectivity'
}

export enum ReturnStates {
  NEW = 'new',
  INITIATED = 'initiated',
  PROCESSING = 'processing',
  ITEMRECEIVED = 'itemReceived',
  COMPLETESUCCESS = 'completeSuccessful',
  COMPLETETIMEOUT = 'completeTimeout',
  COMPLETEUNSUCCESS = 'completeUnsuccessful',
  TIMEOUT = 'timeout'
}

export enum SubscriptionPaymentStatus {
  PAYMENT_RESOLVED = 'success',
  PAYMENT_OVERDUE = 'overdue',
  SUSCRIPTION_SUSPENDED = 'suspended',
  SUBSCRIPTION_INACTIVE = 'inactive'
}

export enum EntityState {
  SUBSCRIBED = 'subscribed',
  CANCELLED = 'cancelled',
  CANCELLING = 'cancelling',
  PAUSED = 'paused',
  PENDING = 'pending',
  PENDING_RETURN = 'pending-return',
  PENDING_GRANT = 'pending-grant',
  PENDING_UPGRADE = 'pending-upgrade',
  PENDING_DOWNGRADE = 'pending-downgrade',
  UPDATING = 'updating',
  ERROR = 'error',
  RETURNED = 'returned'
}

export enum AnalyticsClickEventsList {
  SUSPENDED_CITIZENPAY_LINK = 'SuspendedCitizensPayLink',
  SUSPENDED_CONTACTSUPPORT_LINK = 'SuspendedContactSupportLink',
  OVERDUE_LEARNMORE_LINK = 'OverdueLearnMoreLink',
  OVERDUE_CITIZENPAY_LINK = 'OverdueCitizenpayLink',
  VIEWALL_SUBSCRIPTION_LINK = 'ViewAllSubscriptionLink',
  CANCEL_SUBSCRIPTION_LINK = 'CancelSubscriptionLink',
  HP_SUPPORT_LINK = 'HpSupportLink',
  POST_CANCEL_HP_SUPPORT_LINK = 'PostCancelHpSupportLink',
  MANAGE_PAYMENTS_LINK = 'ManagePaymentsLink',
  MANAGE_ADDRESSES_LINK = 'ManageAddresses',
  GETMORE_STORAGE_LINK = 'GetMoreStorage',
  GOOGLE_LOGIN_LINK = 'GoogleLogIn'
}

export enum APINotificationList {
  GOOGLE_UPGRADE_SUCCESS = 'google-upgrade-success',
  GOOGLE_UPGRADE_ERROR = 'google-upgrade-error',
  DEVICE_API_WARNING = 'device-api-warning',
  PREARRIVAL_LAPTOP = 'preavrrival-laptop',
  CANCEL_SUBSCRIPTION_ERROR = 'cancel-subscription-error',
  PAYMENT_RESOLVED = 'payment-resolved',
  PAYMENT_OVERDUE = 'payment-overdue',
  SUSCRIPTION_SUSPENDED = 'payment-suspended',
  SUBSCRIPTION_INACTIVE = 'payment-inactive',
  GOOGLE_ACTIVATION_SUCCESS = 'google-activate-success',
  GOOGLE_ACTIVATION_FAILURE = 'google-activate-failure',
  GOOGLE_ACTIVATION_PENDING = 'google-activate-pending',
  MICROSOFT_INACTIVE = 'microsoft-inactice',
  GOOGLE_INACTIVE = 'google-inactice'
}

export enum FulfillmentStatus {
  INVOICED = 'invoiced',
  NEW = 'new',
  SHIPPED = 'shipped',
  PROCESSING = 'processing',
  RECIEVED = 'received'
}

export type UiNotification =
  | 'positive'
  | 'negative'
  | 'help'
  | 'informative'
  | 'warning'

export const GOOGLE_SUBSCRIPTION_STATE_LOCAL_STORAGE =
  'google-subscription-state'

export enum ApiStatusCode {
  Status_Code_202 = 202,
  Status_Code_200 = 200
}

export enum GoooglePlan {
  PLAN_200GB = 'RSG1PD.2966-0235-3720-53891',
  PLAN_2TB = 'RSG1PD.6075-5573-9576-11301'
}
