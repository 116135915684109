import { Stack } from '../types/stratus'
const DEV_URL = require('../configs/url.dev.json')
const PIE_URL = require('../configs/url.pie.json')
const STAGE_URL = require('../configs/url.stage.json')
const PROD_URL = require('../configs/url.prod.json')

export const URLS = {
  [Stack.prod]: PROD_URL,
  [Stack.stage]: STAGE_URL,
  [Stack.pie]: PIE_URL,
  [Stack.dev]: DEV_URL
}
