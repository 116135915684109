import { ThirdPartyType, GoooglePlan } from '../../constants'
import type { Subscription, Edit } from '../../types/services/subscription'
import { GrantControllerApiClient } from '../../services/grant-controller-api'

export const checkEligibleForUpgrade = async (
  googleEntityDetails: Subscription,
  productSku: string
) => {
  const filterData = googleEntityDetails.entities[0].edit.filter(
    (item: Edit) =>
      item.product.value.productType === ThirdPartyType.GOOGLE &&
      item.product.value.productSku !== productSku
  )
  const eligible = IdentifyPlan(filterData[0].product.value.productSku)
  return {
    eligible: eligible,
    optionId: filterData[0].optionId
  }
}

const IdentifyPlan = (productSku) => {
  if (GoooglePlan.PLAN_200GB === productSku) {
    return false
  } else {
    return true
  }
}

export const checkForStorageWithGrants = async (
  authProvider,
  stack,
  subscription: Subscription
) => {
  const grantControllerClient = new GrantControllerApiClient(
    authProvider,
    stack
  )
  try {
    const grantControllerData = await grantControllerClient.getGrantForGoogle(
      subscription.tenantId
    )

    return grantControllerData && grantControllerData.contents.length > 0
      ? false
      : true
  } catch (error) {
    console.log(error)
  }
}
