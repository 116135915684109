import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const {
  colorWhite,
  space1,
  space2,
  space4,
  lineHeight4,
  fontSize4,
  fontSize3,
  colorGray7,
  fontTextRegular,
  fontSize1,
  lineHeight2,
  colorGray5,
  lineHeight3,
  colorGray0,
  colorGray4,
  colorGreen6,
  colorHpBlue6
} = tokens
export const Card = styled.div`
  background-color: ${colorWhite};
  width: 100%;
  height: 267px;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  gap: ${space1};
  @media (min-width: 641px) {
    width: calc(50% - 16px);
    min-width: calc(50% - 16px);
    flex: calc(50% - 16px);
  }
  @media (min-width: 1008px) {
    width: calc(33.33% - 16px);
    min-width: calc(33.33% - 16px);
    flex: calc(33.33% - 16px);
  }
`
export const CardErrorLabel = styled.div`
  font-size: 20px;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${colorGray4};
  margin-bottom: 8px;
`

export const ErrorContainer = styled.div`
  background-color: ${colorWhite};
  height: 100%;
  padding: 16px;
`
export const InsideCard = styled.div`
  background-color: ${colorGray0};
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  flex-flow: column;

  align-self: stretch;
`
export const CardErrorText = styled.div`
  font-family: ${fontTextRegular};
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSize3};
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${colorGray7};
  margin-left: 16px;
  margin-right: 16px;
`
export const CardErrorContent = styled.div`
  font-family: ${fontTextRegular};
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSize3};
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${colorGray7};
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
`
export const Container = styled.div`
  padding: ${space4};
  line-height: ${lineHeight4};
  opacity: ${(props) => (props.isSuspended ? 0.4 : 1)};
  text-decoration-line: none;
  pointer-events: ${(props) => (props.isSuspended ? 'none' : 'auto')};
`
export const Image = styled.img`
  margin-top: ${space1};
  margin-bottom: ${space1};
`
export const MicrosoftImage = styled.div`
  height: 32px;
  margin-top: ${space1};
  margin-bottom: ${space1};
`
export const MicrosoftPersonalImage = styled.img`
  margin-top: 7px;
  display: block;
`
export const CardTitle = styled.p`
  font-size: ${fontSize3};
  letter-spacing: 0.02em;
  line-height: ${lineHeight3};
  margin-top: 8px;
  color: ${(props) => (props.isSuspended ? colorGray4 : colorGray7)};
`
export const CardText = styled.p`
  margin-top: 7px;
`

export const CardContentMedium = styled.div`
  font-family: ${fontTextRegular};
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  color: ${(props) => (props.isSuspended ? colorGray4 : colorGray7)};
`
export const CardContent = styled.div`
  display: inline-flex;
  gap: ${space1};
  align-items: center;
  margin-top: ${space1};
  color: ${(props) => (props.isSuspended ? colorGray4 : colorHpBlue6)};
  line-height: ${lineHeight3};
`
export const CardPlanContent = styled.div`
  font-family: ${fontTextRegular};
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  color: ${colorGray5};
`
export const Border = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: ${space1};
`

export const AlignToBottom = styled.div``

export const AlignContent = styled.div`
  position: relative;
  margin-top: 36px;
`

export const CardContentActivated = styled.div`
  font-family: ${fontTextRegular};
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  color: ${(props) => (props.isSuspended ? colorGray4 : colorGreen6)};
`
export const CardContentNotActivated = styled.div`
  font-family: ${fontTextRegular};
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  color: ${(props) => (props.isSuspended ? colorGray4 : colorGray7)};
`
export const CardTextContent = styled.div`
  display: inline-flex;
  gap: ${space1};
  align-items: center;
`
