import { FulfillmentOrdersClient } from '../services/fulfillment-orders-api'
import { Entitlements } from '../types/services/subscription'

export const isDeviceShippedOrDelivered = async (
  fulfilmentClient: FulfillmentOrdersClient,
  entity: Entitlements
) => {
  const entityFulfilment = (
    await fulfilmentClient.getfulfillmentOrdersDetails(
      entity.fulfillment.value.fulfillmentOrderId
    )
  ).content

  let foundPart
  const enrollmentOrder = entityFulfilment.filter(
    (item) => item.productType === 'hardware'
  )
  const possible = enrollmentOrder[0].parts.find(
    (part) => part.modelSku === entity.product.value.productSku
  )
  if (possible) foundPart = possible

  if (!foundPart) return false
  const statuses = foundPart.history.map((old) => old.status)
  statuses.push(foundPart.status)
  return statuses.includes('shipped')
}
