import React from 'react'
import { CardText } from '../style'

const GoogleUpgradeContent = ({ t, googleUpgradePrice }) => {
  return (
    <div>
      <CardText>
        {t(
          'CloudStorage.Content1',
          'Your upgrade will start now. By upgrading to 2 TB of cloud storage, your HP All-In Plan will increase by'
        )}
        {` $${googleUpgradePrice} `}
        {t(
          'CloudStorage.Content2',
          "per month, partial months will be prorated. You'll receive an invoice today that notifies you of your plan changes. No payment is due until your next billing statement."
        )}
      </CardText>
    </div>
  )
}
export default GoogleUpgradeContent
