import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'

import { Stack } from '../types/stratus'
import { URLS } from './urls-api'
import { reloginOnUnauthorized } from '../utils/reloginOnUnauthorized'

export class FulfillmentOrdersClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(URLS[stack].API_URLS.FULFILLMENT_ORDERS, authToken)
  }

  async getfulfillmentOrdersDetails(fulfillmentOrderId) {
    try {
      return await this.jarvisWebHttpInstance
        .get({
          url: `/orders?size=100&fulfillmentOrderId=${fulfillmentOrderId}`
        })
        .then(({ data: fulfillmentorder }) => fulfillmentorder)
    } catch (error) {
      reloginOnUnauthorized(error)
      throw new Error(`fulfillmentorder API failed ${error}`)
    }
  }
}
