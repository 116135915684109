import React, { useState } from 'react'
import { Cancelation } from '@jarvis/react-hpx-cancellation'
import { CancelationCdmEvent } from '@jarvis/react-hpx-cancellation/dist/src/types'
import { useAppSelector } from '../../customHooks'
import type { Subscription } from '../../types/services/subscription'

import { useAppDispatch } from '../../customHooks'
import { redirectURLs } from '../../configs/external-link'
import { addNotification } from '../../stores/api-notifications'
import { Entitlements } from '../../../src/types/services/subscription'

import { APINotificationList } from '../../constants'

const CancelSubscription = ({
  t,
  show,
  setOpen,
  authProvider,
  stack,
  hybridUser,
  remorsePeriod
}) => {
  const dispatch = useAppDispatch()
  const [dialogClose, setDialogClose] = useState<boolean>(false)

  const subscriptionData: Subscription = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )

  const currentActivePc: Entitlements = useAppSelector(
    (state) => state?.userSubscriptionDetails?.activePcData
  )

  const handleOnClose = (): void => {
    setOpen(false)
    if (dialogClose) {
      window.location.reload()
    }
  }

  const handleOnSuccess = (): void => {
    // reload the page if the cancellation has been successful
    setDialogClose(true)
  }
  /* istanbul ignore next */
  const handleOnError = (): // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // error: CancelationError | CancelationHttpError
  void => {
    const notification = {
      statusType: 'warning',
      title:
        hybridUser && remorsePeriod
          ? t(
              'cancelation-error.cancelPlan.title-hybrid',
              'We were unable to cancel your HP All-In Laptop and Print plan. Please try again.'
            )
          : t(
              'cancelation-error.cancelPlan.title-pc',
              'We were unable to cancel your HP All-In Laptop Plan. Please try again.'
            ),
      description: (
        <div>
          {t('cancelation-error.cancelPlan.subtitle', 'For assistance, visit')}{' '}
          <a
            target="_blank"
            href={redirectURLs.hpsupportPCaaSURL}
            rel="noreferrer"
          >
            {t('cancelation-error.cancelPlan.hp-support', 'HP Support')}{' '}
          </a>
          {t(
            'cancelation-error.cancelPlan.subtitle2',
            'or call 1-866-204-8618'
          )}
        </div>
      ),
      notificationName: APINotificationList.CANCEL_SUBSCRIPTION_ERROR,
      showCloseButton: true,
      className: 'notificationCard'
    }
    dispatch(addNotification(notification))
    // increment the event object as necessary and publish to analytics API
  }
  /* istanbul ignore next */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnEvent = (event: CancelationCdmEvent): void => {
    // increment the event object as necessary and publish to analytics API
    setTimeout(() => {
      document.getElementById('yes-button')?.focus()
    }, 1000)
  }

  return (
    show && (
      <Cancelation
        open={show}
        subscriptionId={subscriptionData.subscriptionId}
        authProvider={authProvider}
        stack={stack}
        entityId={currentActivePc.entityId}
        onClose={handleOnClose}
        onSuccess={handleOnSuccess}
        onError={handleOnError}
        onEvent={handleOnEvent}
      />
    )
  )
}
export default CancelSubscription
