import React from 'react'
import { redirectURLs } from '../../../configs/external-link'
import { captureClickEvents } from '../../../services/DataCollection/clickActions'
import { AnalyticsClickEventsList } from '../../../constants'
import { useAppSelector } from '../../../customHooks'
import { Entitlements } from 'src/types/services/subscription'

const SupportAnchorLink = ({ t }) => {
  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )
  const subscriptionData = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )

  return (
    <>
      <a
        href={redirectURLs.hpsupportPCaaSURL}
        target="_blank"
        rel="noreferrer"
        data-testid="Support"
        onClick={() => {
          captureClickEvents(
            AnalyticsClickEventsList.POST_CANCEL_HP_SUPPORT_LINK,
            subscriptionData,
            currentActivePc
          )
        }}
      >
        {t(
          'PostCancelNotification-PC.CountDownStage.support-link',
          'HP Support'
        )}
      </a>
      .
    </>
  )
}

export default SupportAnchorLink
