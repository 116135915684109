import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const {
  colorWhite,
  space1,
  fontTextRegular,
  fontSize3,
  fontSize4,
  fontSize2,
  lineHeight4,
  lineHeight3,
  colorGray10,
  colorHpBlue6,
  colorGray4,
  colorGray0,
  colorGray5,
  fontSize5,
  lineHeight5,
  colorGray7,
  space4,
  space2,
  space5,
  size4
} = tokens

export const ErrorContainer = styled.div`
  background-color: ${colorWhite};
  height: 100%;
  padding: ${space4};
`
export const Card = styled.div`
  background-color: ${colorWhite};
  width: 100%;
  height: 267px;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  gap: ${space1};
  @media (min-width: 641px) {
    width: calc(50% - 16px);
    min-width: calc(50% - 16px);
    flex: calc(50% - 16px);
  }
  @media (min-width: 1008px) {
    width: calc(33.33% - 16px);
    min-width: calc(33.33% - 16px);
    flex: calc(33.33% - 16px);
  }
`
export const CardTitle = styled.p`
  font-size: ${fontSize4};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight4};
  margin-bottom: 16px;
  color: ${colorGray7};
  @media (min-width: 1008px) {
    font-size: ${fontSize5};
    line-height: ${lineHeight5};
    color: ${colorGray10};
  }
`
export const InsideCard = styled.div`
  background-color: ${colorGray0};
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-self: stretch;
`
export const CardErrorText = styled.div`
  font-family: ${fontTextRegular};
  font-style: normal;
  font-weight: 400;
  font-size: ${size4};
  line-height: ${lineHeight4};
  text-align: center;
  letter-spacing: 0.02em;
  color: ${colorGray7};
  margin-left: ${space4};
  margin-right: ${space4};
  margin-top: ${space1};
`
export const CardErrorTextOne = styled.div`
  font-family: ${fontTextRegular};
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSize3};
  line-height: ${lineHeight4};
  text-align: center;
  letter-spacing: 0.02em;
  color: ${colorGray7};
  margin-left: ${space4};
  margin-right: ${space4};
  margin-top: ${space5};
`
export const AlignToTopShippingAddress = styled.div`
  position: relative;
  margin-top: 34px;
  pointer-events: ${(props) => (props.isSuspended ? 'none' : 'auto')};
`
export const Container = styled.div`
  padding: ${space4};
  line-height: ${lineHeight4};
  opacity: ${(props) => (props.isSuspended ? 0.4 : 1)};
  pointer-events: ${(props) => (props.isSuspended ? 'none' : 'auto')};
`
export const Border = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: ${space2};
`
export const CardPin = styled.div`
  padding-bottom: ${space1};
`
export const CardContent = styled.p`
  font-size: ${fontSize2};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight3};
  color: ${(props) => (props.isSuspended ? colorGray4 : colorHpBlue6)};
`
export const AddressContainer = styled.div`
  text-transform: capitalize;
`
