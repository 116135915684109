import { DataValveCDMEvent, SimpleUiEvent } from '../../types/webanalytics'

const eventDetailType =
  'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1'
const eventCategory = 'simpleUi'
const version = '1.4.0'

export default class DataCollection {
  public static sendEvent = (event: SimpleUiEvent) => {
    const cdmEventObject: DataValveCDMEvent = {
      dateTime: new Date().toISOString(),
      eventDetailType: eventDetailType,
      eventCategory: eventCategory,
      version: version,
      eventDetail: event
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.Shell.v1.analytics.publishCdmEvents([cdmEventObject])
    return cdmEventObject

    // if (!window?.WebAnalytics) {
    //   return console.log('WebAnalytics not available to publish events.')
    // }
    // window.WebAnalytics.publishCdmEvents([cdmEventObject])
  }
}
