import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
const {
  colorGray10,
  fontTextRegular,
  fontSize2,
  lineHeight3,
  colorHpBlue6,
  colorGray4
} = tokens

export const CardLabelHpOne = styled.p`
  font-size: ${fontSize2};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight3};
  color: ${(props) => (props.isSuspended ? colorGray4 : colorHpBlue6)};
`
export const SubscriptionBar = styled.p`
  color: ${colorGray10};
  font-family: ${fontTextRegular};
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  @media (min-width: 375px) {
    justify-content: flex-end;
  }
  @media (min-width: 640px) {
    justify-content: space-between;
  }
`
export const Line = styled.span`
  width: 24px;
  height: 0px;
  border: 1px solid #adadad;
  transform: rotate(90deg);
  margin-top: 10px;
`
export const Section = styled.span`
  margin-right: 10px;
`
export const Container = styled.div`
  opacity: ${(props) => (props.isSuspended ? 0.4 : 1)};
  text-decoration-line: none;
  pointer-events: ${(props) => (props.isSuspended ? 'none' : 'auto')};
`
