import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
import { IconExternalLink } from '@veneer/core/dist/scripts/icons'

const {
  colorWhite,
  fontTextRegular,
  space2,
  colorGray7,
  lineHeight3,
  fontSize3,
  space1,
  space4,
  fontSize2,
  colorHpBlue6,
  colorGray0,
  space3
} = tokens

export const Image = styled.img``

export const Card = styled.div`
  background-color: ${colorWhite};
  width: 100%;
  height: 267px;
  border-radius: 8px;
  display: ${(props) => (props.isShowErrorBlock ? 'flex' : '')};
  flex-flow: ${(props) => (props.isShowErrorBlock ? 'column' : '')};
  gap: ${space2};
  @media (min-width: 641px) {
    width: calc(50% - 16px);
    min-width: calc(50% - 16px);
    flex: calc(50% - 16px);
  }
  @media (min-width: 1008px) {
    width: calc(33.33% - 16px);
    min-width: calc(33.33% - 16px);
    flex: calc(33.33% - 16px);
  }
`
export const CardTitle = styled.p`
  font-size: ${fontSize3};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight3};
  margin-bottom: ${space4};
  color: ${colorGray7};
`
export const Border = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 11px;
`
export const Container = styled.div`
  padding: ${space4};
`
export const CardImage = styled.div`
  margin-top: ${space2};
  margin-bottom: ${space1};
`
export const Cardlabel = styled.p`
  font-size: ${fontSize2};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight3};
  color: ${colorHpBlue6};
`
export const AlignToBottom = styled.div`
  position: relative;
  margin-top: 7px;
`

export const ErrorContainer = styled.div`
  background-color: ${colorWhite};
  height: 100%;
  padding: 16px;
`

export const InsideCard = styled.div`
  background-color: ${colorGray0};
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-self: stretch;
`
export const CardErrorTextOne = styled.div`
  font-family: ${fontTextRegular};
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSize3};
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${colorGray7};
  padding: 12px 0px;
`
export const CardErrorContainer = styled.div`
  text-align: center;
  padding: 0px 16px;
`
export const CardErrorTextTwo = styled.div`
  font-family: ${fontTextRegular};
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSize3};
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${colorGray7};
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 20px;
`
export const ExternalLinkIconPreArival = styled(IconExternalLink)`
  color: ${colorHpBlue6};
  margin-left: ${space3};
  line-height: ${lineHeight3};
`
