import { IconLaptop } from '@veneer/core/dist/scripts/icons'
import React from 'react'
import { CardTextNotload, CardError, Card, CardLaptopIcon } from '../Style'

const PreArrivalLaptopDetails = ({ t }) => {
  return (
    <Card data-testid="preArrival-laptop">
      <CardError>
        <CardLaptopIcon>
          <IconLaptop size={72} color="colorGray4" />
        </CardLaptopIcon>
        <CardTextNotload>
          {t(
            'NotificationsCard.preArrivalText.title',
            'Your plan details will display here after you set up your Laptop'
          )}
        </CardTextNotload>
      </CardError>
    </Card>
  )
}

export default PreArrivalLaptopDetails
