import { useState, useEffect } from 'react'
import { FulfillmentOrdersClient } from '../services/fulfillment-orders-api'
import { FulfillmentStatus } from '../../src/constants'

const useFulfillmentOrders = (
  authProvider,
  stack,
  fulfillmentOrderId,
  pcDetails
) => {
  const [hasInvoicedData, setHasInvoicedData] = useState<any>('')
  useEffect(() => {
    fetchData()
  }, [authProvider, stack, fulfillmentOrderId, pcDetails])

  const fetchData = async () => {
    try {
      const FulfillmentOrdersAPIClient = new FulfillmentOrdersClient(
        authProvider,
        stack
      )
      const {
        content
      } = await FulfillmentOrdersAPIClient.getfulfillmentOrdersDetails(
        fulfillmentOrderId
      )
      const { parts } = content[0] ?? {}
      const filteredData = parts?.filter(
        (item) => item.serialNumber === pcDetails?.entityDetails?.serialNumber
      )
      const invoicedData = filteredData
        ?.flatMap((item) => item.history)
        ?.filter((item) => item.status === FulfillmentStatus.INVOICED)
      /* istanbul ignore next */
      setHasInvoicedData(
        invoicedData?.length > 0 ||
          filteredData[0]?.status == FulfillmentStatus.INVOICED
      )
    } catch (error) {
      setHasInvoicedData('false')
    }
  }

  return hasInvoicedData
}

export default useFulfillmentOrders
