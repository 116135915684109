import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { IconCloud } from '@veneer/core/dist/scripts/icons'
import Images from '../../assets/images'
import {
  Image,
  Card,
  CardTitle,
  CardContent,
  CardContentMedium,
  CardPlanContent,
  AlignContent,
  Container
} from './style'

import { useAppDispatch, useAppSelector } from '../../customHooks'
import {
  ThirdPartyType,
  EntityState,
  APINotificationList,
  GOOGLE_SUBSCRIPTION_STATE_LOCAL_STORAGE,
  ApiStatusCode
} from '../../constants'

import type {
  Subscription,
  Entitlements
} from '../../types/services/subscription'

import { useLazyGetCloudPlanDetails } from '../../services/ApolloClient'
import { LoadingHandler } from '@jarvis/react-hpx-shared-components'
import { checkEligibleForUpgrade, checkForStorageWithGrants } from './utility'
import GoogleCardContent from './GoogleContents/GoogleCardContent'
import GetGoogleButton from './GetGoogleButton'
import GoogleCloudStorageAPIError from './GoogleCloudStorageAPIError'
import { updateMaxDaysToReturn } from '../../stores/subscription'
import { SubscriptionApiClient } from '../../services/subscription-api'
import { redirectURLs } from '../../configs/external-link'
import {
  addNotification,
  removeNotification
} from '../../stores/api-notifications'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isInCancelState } from '../../utils/common-utils'
import GoogleAlreadySubscribedModal from './GoogleModals/GoogleAlreadySubscribedModal'
import GoogleSubscriptionIneligibleModal from './GoogleModals/GoogleSubscriptionIneligibleModal'

const GoogleCloudStorage = ({
  t,
  authProvider,
  stack,
  refetchApiFn,
  blockGActivation,
  setBlockGActivation
}) => {
  const dispatch = useAppDispatch()
  const flags = useFlags()
  const [googlePlanDetails, setGooglePlanDetails] = useState<string>(' ')
  const [showGoogleBlock, setShowGoogleBlock] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [planChangePrice, setPlanChangePrice] = useState<number>()
  const [getGooglePlanDetails] = useLazyGetCloudPlanDetails()
  const [googleData, setgoogleData] = useState<any>()
  const [showStorage, setShowStorage] = useState<boolean>(false)
  const [
    showGoogleSubscriptionIneligibleModal,
    setShowGoogleSubscriptionIneligibleModal
  ] = useState(false)
  const [
    showGoogleAlreadySubscribedModal,
    setShowGoogleAlreadySubscribedModal
  ] = useState(false)

  const authCode = useRef<string | null>('')
  const [activateStatusCode, setActivateStatusCode] = useState<number | null>(
    null
  )
  const [isRetryCalled, setIsRetryCalled] = useState<boolean>(false)
  let retryUrl = ''
  /* istanbul ignore next */
  if (flags.enablePostPurchasePathHPAllin) {
    retryUrl = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?client_id=${flags?.googleClientId}&redirect_uri=${window.location.origin}/ucde/hp-all-in-laptop-plan&scope=https://www.googleapis.com/auth/subscriptions.thirdparty.googleone&response_type=code&access_type=offline&prompt=consent&flowName=GeneralOAuthFlow&service=lso&o2v=1`
  } else {
    retryUrl = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?client_id=${flags?.googleClientId}&redirect_uri=${window.location.origin}/ucde/hpone&scope=https://www.googleapis.com/auth/subscriptions.thirdparty.googleone&response_type=code&access_type=offline&prompt=consent&flowName=GeneralOAuthFlow&service=lso&o2v=1`
  }
  const subscriptionClient = React.useMemo(
    () => new SubscriptionApiClient(authProvider, stack, true),
    [authProvider, stack]
  )

  const urlParams = useMemo(() => {
    return new URLSearchParams(window.location.search)
  }, [])

  useEffect(() => {
    authCode.current = urlParams.get('code')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showErrorNotification = useCallback(() => {
    const notification = {
      statusType: 'warning',
      title: t(
        'NotificationsCard.googleActivateFail.title',
        'There was an unexpected error while processing your request, please try again to activate your Google One storage'
      ),
      description: (
        <div>
          {t(
            'NotificationsCard.googleActivateFail.subtitle',
            'For assistance, visit '
          )}
          <a href={redirectURLs.hpsupportPCaaSURL}>
            {t(
              'NotificationsCard.googleActivateFail.hp-support',
              'HP 24/7 Pro live support.'
            )}
          </a>
        </div>
      ),
      notificationName: APINotificationList.GOOGLE_ACTIVATION_FAILURE,
      showCloseButton: true,
      className: 'notificationCard'
    }
    dispatch(addNotification(notification))
  }, [dispatch, t])

  useEffect(() => {
    if (urlParams.get('error') === 'access_denied') {
      showErrorNotification()
    }
  }, [showErrorNotification, urlParams])

  const subscriptionData: Subscription = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )

  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )

  const googleStorageData: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.googleStorageData
  )
  const isEntityEnabled = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionStatus?.isEntityEnabled
  )

  const googlePlanID = googleStorageData.product.value.parentSku
  const productSku = googleStorageData.product.value.productSku
  const isCancelled = isInCancelState(googleStorageData?.state)

  useEffect(() => {
    /* istanbul ignore next */
    const fetchGooglePlanDetails = async () => {
      if (!googlePlanID || !googleStorageData?.product?.value?.parentSku) {
        return
      }

      try {
        const { data } = await getGooglePlanDetails({
          variables: { parentSKU: googlePlanID },
          onError: () => {
            setShowGoogleBlock(false)
          }
        })

        filterAndMapItemsByType(data, ThirdPartyType.GOOGLE)
        dispatch(
          updateMaxDaysToReturn(data?.products?.items[0]?.hp_max_days_to_return)
        )
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }

    fetchGooglePlanDetails()
  }, [getGooglePlanDetails, googlePlanDetails, googlePlanID, productSku])

  /* istanbul ignore next */
  function filterAndMapItemsByType(data, targetType) {
    let minPrice, maxPrice, storagePrice
    data?.products?.items[0]?.items
      .filter((item) =>
        item.options.some(
          (childItem) => childItem?.product.hp_product_type_label === targetType
        )
      )
      .flatMap((item) =>
        item.options.map((option) => {
          const {
            hp_storage_amount,
            price,
            hp_storage_uom_label,
            sku
          } = option.product
          if (sku == productSku) {
            minPrice = price?.regularPrice?.amount?.value
            if (!hp_storage_amount || !hp_storage_uom_label) {
              setShowGoogleBlock(false)
            }
            setGooglePlanDetails(`${hp_storage_amount} ${hp_storage_uom_label}`)
          } else {
            maxPrice = price?.regularPrice?.amount?.value
          }
          //If user already have 2TB plan then value come negative here so used absolute here.
          storagePrice = Math.abs(minPrice - maxPrice)
          setPlanChangePrice(storagePrice)
        })
      )
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const subscriptionClient = new SubscriptionApiClient(authProvider, stack)
      const isUpdating = googleStorageData.state === EntityState.UPDATING
      const isSubscribed = googleStorageData.state === EntityState.SUBSCRIBED

      if (isCancelled) {
        setgoogleData(createGoogleData(false, false, null))
        setShowStorage(true)
      } else {
        const googleStorageDataDetailsFetch = await subscriptionClient.getEntityDetails(
          subscriptionData.subscriptionId,
          googleStorageData.entityId
        )
        const planChangeData = await checkEligibleForUpgrade(
          googleStorageDataDetailsFetch,
          googleStorageData.product.value.productSku
        )

        if (isUpdating) {
          window.localStorage.setItem(
            GOOGLE_SUBSCRIPTION_STATE_LOCAL_STORAGE,
            planChangeData.eligible
              ? EntityState.PENDING_UPGRADE
              : EntityState.PENDING_DOWNGRADE
          )
          setgoogleData(
            createGoogleData(
              false,
              planChangeData.eligible,
              planChangeData.optionId
            )
          )
        } else if (isSubscribed) {
          const eligibleforUpgradeData = await checkForStorageWithGrants(
            authProvider,
            stack,
            subscriptionData
          )
          if (eligibleforUpgradeData) {
            dismissNotification()
            setgoogleData(
              createGoogleData(
                true,
                planChangeData.eligible,
                planChangeData.optionId
              )
            )
          } else {
            setgoogleData(createGoogleData(false, false, null))
            showNotification()
          }
        }

        setShowStorage(true)
      }
      setLoading(false)
    }

    fetchData()
  }, [authProvider, stack, planChangePrice])

  const createGoogleData = (activated, eligibleforUpgrade, optionId) => {
    return {
      isActivated: activated,
      eligibleForUpgrade: eligibleforUpgrade,
      optionId: optionId,
      planChangePrice,
      authProvider,
      stack,
      subscriptionData,
      currentActivePc,
      googleStorageData
    }
  }

  const showNotification = () => {
    const notification = {
      statusType: 'help',
      title: t(
        'NotificationsCard.googleActivatePending.title',
        'Activate your Google One membership by clicking “Activate storage plan”'
      ),
      description: (
        <div>
          {t(
            'NotificationsCard.googleActivatePending.subtitle',
            'Keep your memories and file safe with the Google One membership included in your HP All-In Plan.'
          )}
        </div>
      ),
      notificationName: APINotificationList.GOOGLE_INACTIVE,
      showCloseButton: true,
      className: 'notificationCard',
      hideIcon: true
    }
    dispatch(addNotification(notification))
  }

  const dismissNotification = () => {
    dispatch(removeNotification(APINotificationList.GOOGLE_INACTIVE))
  }

  const retryGoogleActivation = async (code: string) => {
    if (!code) return
    try {
      const res = await subscriptionClient.activateGoogleStoragePlan(
        googleStorageData?.fulfillment?.value
          ?.lifecycleControllerSubscriptionId,
        code,
        flags?.googleClientId,
        retryUrl
      )
      setActivateStatusCode(res.status)
      const localStorageState = window.localStorage.getItem(
        GOOGLE_SUBSCRIPTION_STATE_LOCAL_STORAGE
      )
      if (localStorageState === EntityState.PENDING_UPGRADE) {
        window.localStorage.removeItem(GOOGLE_SUBSCRIPTION_STATE_LOCAL_STORAGE)
        const notification = {
          statusType: 'positive',
          title: t(
            'NotificationsCard.googleUpgradeAPISuccess.title',
            'Your Google One cloud storage plan was successfully updated'
          ),
          description: t(
            'NotificationsCard.googleUpgradeAPISuccess.subtitle',
            `Your 2 TB Google cloud storage is ready.`
          ),
          notificationName: APINotificationList.GOOGLE_UPGRADE_SUCCESS,
          showCloseButton: true,
          className: 'notificationCard'
        }
        dispatch(addNotification(notification))
      } else if (localStorageState === EntityState.PENDING_DOWNGRADE) {
        window.localStorage.removeItem(GOOGLE_SUBSCRIPTION_STATE_LOCAL_STORAGE)
        const notification = {
          statusType: 'positive',
          title: t(
            'NotificationsCard.googleDowngradeAPISuccess.title',
            'Your Google One cloud storage plan was successfully updated'
          ),
          description: t(
            'NotificationsCard.googleDowngradeAPISuccess.subtitle',
            'Your 200 GB Google cloud storage is ready.'
          ),
          notificationName: APINotificationList.GOOGLE_UPGRADE_SUCCESS,
          showCloseButton: true,
          className: 'notificationCard'
        }
        dispatch(addNotification(notification))
      }
    } catch (err) {
      setActivateStatusCode(err.response.status)
      if (
        err.response.message.includes(
          'ERROR_CODE_SUBSCRIPTION_REJECTED_SUBSCRIPTION_INELIGIBLE'
        )
      ) {
        setShowGoogleSubscriptionIneligibleModal(true)
      } else if (
        err.response.message.includes(
          'ERROR_CODE_SUBSCRIPTION_REJECTED_ALREADY_SUBSCRIBED'
        )
      ) {
        setShowGoogleAlreadySubscribedModal(true)
      } else {
        showErrorNotification()
      }
    } finally {
      setIsRetryCalled(true)
      authCode.current = null
    }
  }

  useEffect(() => {
    if (blockGActivation) return
    if (authCode !== null && authCode.current?.length) {
      //code is in url, then call activation
      setTimeout(() => retryGoogleActivation(authCode.current), 2000)
    }
    // 202 -> redirect again to Google page and get the code
    if (isRetryCalled && activateStatusCode === ApiStatusCode.Status_Code_202) {
      // setErrorCode(null)
      setIsRetryCalled(false)
      window.location.replace(retryUrl)
      //200, save status and fetch SubscriptionAPI
    } else if (activateStatusCode === ApiStatusCode.Status_Code_200) {
      authCode.current = null
      urlParams.delete('code')
      setBlockGActivation(true)
      refetchApiFn()
      window.history.pushState(
        {},
        window.location.origin,
        window.location.pathname
      )
      const notification = {
        statusType: 'positive',
        title: t(
          'NotificationsCard.googleActivateSuccess.title',
          'Storage activation was successfully completed'
        ),
        description:
          t('NotificationsCard.googleActivateSuccess.subtitle-1', `Your `) +
          googlePlanDetails +
          t(
            'NotificationsCard.googleActivateSuccess.subtitle-2',
            `of cloud storage is ready to use.`
          ),
        notificationName: APINotificationList.GOOGLE_ACTIVATION_SUCCESS,
        showCloseButton: true,
        className: 'notificationCard'
      }
      dismissNotification()
      dispatch(addNotification(notification))
    } else {
      //Other status codes
    }
    setActivateStatusCode(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateStatusCode, isRetryCalled, blockGActivation])

  //TODO: Pending activation notification to be done using
  // useStateWithLocalStorage

  return (
    <Card>
      {loading ? (
        <LoadingHandler
          loadingText={t('loader.text', 'Loading...')}
          loading={true}
        />
      ) : showGoogleBlock ? (
        <Container
          data-testid="modal-content"
          isSuspended={!isEntityEnabled || isCancelled}
        >
          <Image
            data-testid="Image"
            src={Images.GoogleOneLogo}
            width="114"
            height="26"
            alt="Google One"
          />

          <CardTitle data-testid="connectivity_title">
            {t('CloudStorage.cloud-storage', 'Cloud storage')}
          </CardTitle>

          <AlignContent>
            <CardPlanContent>{t('CloudStorage.plan', ' Plan')}</CardPlanContent>
            <CardContent>
              <IconCloud size={20} data-testid="Icon-cloud" />
              <CardContentMedium data-testid="google-plan-details">
                {googlePlanDetails}
              </CardContentMedium>
            </CardContent>
            <div>
              {googleData && <GoogleCardContent {...{ t, googleData }} />}
            </div>
          </AlignContent>
          {showStorage && (
            <GetGoogleButton
              {...{ t, googleData, refetchApiFn, url: retryUrl }}
            />
          )}
        </Container>
      ) : (
        <GoogleCloudStorageAPIError {...{ t }} />
      )}
      <GoogleAlreadySubscribedModal
        t={t}
        handleClose={() => setShowGoogleAlreadySubscribedModal(false)}
        showModal={showGoogleAlreadySubscribedModal}
      />
      <GoogleSubscriptionIneligibleModal
        t={t}
        handleClose={() => setShowGoogleSubscriptionIneligibleModal(false)}
        showModal={showGoogleSubscriptionIneligibleModal}
        url={retryUrl}
      />
    </Card>
  )
}

export default GoogleCloudStorage
