import React, { FC } from 'react'
import { IconExternalLink } from '@veneer/core/dist/scripts/icons'
import '../../styles/global.scss'

import {
  Image,
  Card,
  CardTitle,
  Border,
  Container,
  Cardlabel,
  CardImage,
  AlignToBottom,
  ErrorContainer,
  InsideCard,
  CardErrorTextOne,
  CardErrorTextTwo
} from './style'

import Images from '../../assets/images'
import PreArrivalCitizenPay from './PreArrivalCitizenPay'

import { TranslatorFunctionType } from '../../types/localization'
import { redirectURLs } from '../../configs/external-link'
import type { Subscription } from '../../types/services/subscription'
import { useAppSelector } from '../../customHooks'
import { captureClickEvents } from '../../services/DataCollection/clickActions'
import { AnalyticsClickEventsList } from '../../constants'
import {
  CitizensBalance,
  LoadingHandler
} from '@jarvis/react-hpx-shared-components'
import moment from 'moment'
import { Entitlements } from 'src/types/services/subscription'
import { FormatCurrency } from '../../utils/formatCurrency'

interface IProps {
  getText: { t: TranslatorFunctionType }
  totalBalance: number
  creditAvailable: number
  showErrorBlock: boolean
  loading: boolean
  preArrivalCitizenPay: boolean
}

const CitizensPayPayments: FC<IProps> = ({
  getText,
  totalBalance,
  creditAvailable,
  showErrorBlock,
  loading,
  preArrivalCitizenPay
}) => {
  const subscriptionData: Subscription = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )

  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )

  const currentPeriod = moment().format('MMM DD, YYYY')

  return (
    <Card data-testid="citizen-pay" isShowErrorBlock={showErrorBlock}>
      {loading ? (
        <div
          data-testid="citizen-pay-loader"
          style={{ position: 'relative', height: '100%' }}
        >
          <LoadingHandler
            loadingText={getText.t('loader.text', 'Loading...')}
            loading={true}
          />
        </div>
      ) : !showErrorBlock ? (
        <>
          {preArrivalCitizenPay ? (
            <PreArrivalCitizenPay t={getText.t} />
          ) : (
            <Container>
              <CardImage>
                <Image
                  src={Images.citizens}
                  width="140"
                  height="18"
                  alt="Citizens Pay"
                />
              </CardImage>
              <CardTitle>
                {getText.t('citizens-balance.title', 'Citizens balance')}
              </CardTitle>
              <CitizensBalance
                t={getText.t}
                formatedMonthYearDate={currentPeriod}
                totalBalance={FormatCurrency(totalBalance?.toFixed(2)) || 0}
                creditAvailable={
                  FormatCurrency(creditAvailable?.toFixed(2)) || 0
                }
              />
              <AlignToBottom>
                <Border />

                <a
                  className="full-width accessibility-links"
                  href={redirectURLs.payEarlyURL}
                  target="_blank"
                  data-testid="support"
                  rel="noreferrer"
                  tabIndex={0}
                  onClick={() => {
                    captureClickEvents(
                      AnalyticsClickEventsList.MANAGE_PAYMENTS_LINK,
                      subscriptionData,
                      currentActivePc
                    )
                  }}
                >
                  <Cardlabel>
                    {getText.t(
                      'citizens-balance.manage-paymentsb',
                      'Manage payment at Citizens Pay'
                    )}
                  </Cardlabel>
                  <Cardlabel>
                    <IconExternalLink size={24} color="colorHpBlue6" />
                  </Cardlabel>
                </a>
              </AlignToBottom>
            </Container>
          )}
        </>
      ) : (
        <ErrorContainer data-testid="error-container">
          <InsideCard>
            <CardImage>
              <Image
                src={Images.CitizenpayPrearrival}
                width="140"
                height="18"
                alt="Citizens Pay"
              />
            </CardImage>
            <CardErrorTextOne>
              {getText.t(
                'citizens-balance.Content-error-paragraph-1',
                'Something went wrong.'
              )}
            </CardErrorTextOne>
            <CardErrorTextOne>
              {getText.t(
                'citizens-balance.Content-error-paragraph-2',
                'Your citizens balance did not load.'
              )}
            </CardErrorTextOne>
            <CardErrorTextTwo>
              {getText.t(
                'citizens-balance.Content-error1',
                'Please refresh the page.'
              )}
            </CardErrorTextTwo>
          </InsideCard>
        </ErrorContainer>
      )}
    </Card>
  )
}

export default CitizensPayPayments
