import React, { useState, useEffect } from 'react'
import { Entitlements } from 'src/types/services/subscription'
import NextPayment from '../NextPayment'
import CitizensPayPayments from '../CitizensPayPayments'
import { useLazyGetBillingDetails } from '../../services/ApolloClient'
import useFulfillmentOrders from '../../customHooks/useFulfillmentOrders'
import { useAppSelector } from '../../customHooks'
import { CommerceBillingCycleClient } from '../../services/commerce-billing-api'
import { CommerceBillingDetails } from '../../types/services/subscription'
interface BillingDetails {
  CreditLimitAmount: number
  AvailableCreditAmount: number
  CurrentAccountBalanceAmount: number
  CurrentTotalBalanceAmounts: number
  CustomerIsDelinquent: boolean
  PaymentDueThisMonth: number
  PaymentDueDate: string
}

const PaymentBlock = ({ t, authProvider, stack }) => {
  const [getBillingDetails] = useLazyGetBillingDetails()
  const [billingDetails, setBillingDetails] = useState<BillingDetails>()
  const [paymentDueDate, setPaymentDueDate] = useState<CommerceBillingDetails>()
  const [showPaymentErrorBlock, setShowPaymentErrorBlock] = useState<boolean>(
    false
  )
  const [showPaymentLoading, setShowPaymentLoading] = useState<boolean>(true)
  const [preArrivalPaymentBlock, setPreArrivalPaymentBlock] = useState<boolean>(
    false
  )
  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )

  const hasInvoicedData = useFulfillmentOrders(
    authProvider,
    stack,
    currentActivePc?.fulfillment.value.fulfillmentOrderId,
    currentActivePc
  )

  const commerceClient = new CommerceBillingCycleClient(authProvider, stack)
  useEffect(() => {
    const checkInvoiceCompleted = () => {
      if (hasInvoicedData) {
        setPreArrivalPaymentBlock(false)
        setShowPaymentLoading(true)
        citizenPayfetchData()
      } else {
        setShowPaymentLoading(false)
        setPreArrivalPaymentBlock(true)
      }
    }
    if (hasInvoicedData !== '') checkInvoiceCompleted()
  }, [hasInvoicedData])

  const citizenPayfetchData = async () => {
    try {
      /* istanbul ignore next */
      await getBillingDetails({
        variables: { orderId: currentActivePc?.source?.value?.orderId },
        onCompleted: (data) => {
          setBillingDetails(data?.getCitizensBillingDetails)
          //TODO: Save CustomerIsDelinquent value to display payment notification
          setShowPaymentErrorBlock(false)
          setShowPaymentLoading(false)
        },
        onError: (errors) => {
          setShowPaymentLoading(false)
          if (errors.message.includes('no setteled')) {
            setPreArrivalPaymentBlock(true)
          } else {
            setShowPaymentErrorBlock(true)
          }
        }
      })

      const dateData = await commerceClient.getCommerceBillingDetails(
        currentActivePc.commerce.value.subscriptionId
      )
      setPaymentDueDate(dateData)
    } catch (err) {
      setShowPaymentErrorBlock(true)
    }
  }
  return (
    <>
      <NextPayment
        paymentDue={billingDetails?.PaymentDueThisMonth}
        paymentDueDate={paymentDueDate?.billingDate}
        getText={{ t }}
        preArrival={preArrivalPaymentBlock}
        showErrorBlock={showPaymentErrorBlock}
        loading={showPaymentLoading}
      />
      <CitizensPayPayments
        totalBalance={billingDetails?.CurrentTotalBalanceAmounts}
        creditAvailable={billingDetails?.AvailableCreditAmount}
        getText={{ t }}
        showErrorBlock={showPaymentErrorBlock}
        loading={showPaymentLoading}
        preArrivalCitizenPay={preArrivalPaymentBlock}
      />
    </>
  )
}

export default PaymentBlock
