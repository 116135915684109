import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const {
  lineHeight2,
  lineHeight4,
  colorWhite,
  colorGreen6,
  fontTextRegular,
  fontSize4,
  fontSize1,
  colorGray7,
  colorGray5,
  colorGray4,
  fontSize5,
  lineHeight5,
  space1,
  space2,
  space6,
  space4,
  fontSize3,
  lineHeight3
} = tokens

export const Card = styled.div`
  background-color: ${colorWhite};
  width: 100%;
  height: 267px;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  gap: ${space2};
  @media (min-width: 641px) {
    width: calc(50% - 16px);
    min-width: calc(50% - 16px);
    flex: calc(50% - 16px);
  }
  @media (min-width: 1008px) {
    width: calc(33.33% - 16px);
    min-width: calc(33.33% - 16px);
    flex: calc(33.33% - 16px);
  }
`
export const Container = styled.div`
  padding: ${space4};
  line-height: ${lineHeight4};
  opacity: ${(props) => (props.isSuspended ? 0.4 : 1)};
  pointer-events: ${(props) => (props.isSuspended ? 'none' : 'auto')};
`
export const CardTitle = styled.p`
  font-family: ${fontTextRegular};
  font-size: ${fontSize3};
  line-height: ${lineHeight3};
  color: ${colorGray7};
  margin-top: ${space2};
  @media (min-width: 1008px) {
    font-size: ${fontSize5};
    line-height: ${lineHeight5};
  }
`
export const CardContentSmall = styled.div`
  font-family: ${fontTextRegular};
  color: ${(props) => (props.isSuspended ? colorGray4 : colorGreen6)};
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  margin-top: ${space1};
`
export const CardSize = styled.div`
  display: inline-flex;
  gap: ${space1};
  align-items: center;
`

export const CardContent = styled.div`
  color: ${colorGray5};
  font-family: ${fontTextRegular};
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  margin-bottom: 6px;
  margin-top: ${space2};
`

export const Border = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  // margin-top: ${space2};
`

export const AlignToTop = styled.div``

export const AlignContent = styled.div`
  position: relative;
  margin-top: 82px;
  @media (min-width: 1008px) {
    margin-top: 76px !important;
  }
`
