import React, { FC, useEffect, useState } from 'react'
import { IconCalendarDay } from '@veneer/core/dist/scripts/icons'
import {
  Card,
  CardTitle,
  FlexSpaceBetween,
  FlexSpace,
  CardlabelSmall,
  CardContentSmall,
  Border,
  AlignToBottom,
  Container,
  CardlabelMedium,
  CardCalender,
  ErrorContainer,
  CardErrorTextTwo,
  InsideCard,
  CardErrorTextOne,
  CardlabelMediumText,
  CardlabelContent
} from './style'
import { TranslatorFunctionType } from '../../types/localization'
import moment from 'moment'
import PreArrivalPayment from './PreArrivalPayment'
import { LoadingHandler } from '@jarvis/react-hpx-shared-components'
import type { Subscription } from '../../types/services/subscription'
import { useAppSelector } from '../../customHooks'
import { isHybridUser } from '../../utils/common-utils'
import { FormatCurrency } from '../../utils/formatCurrency'
interface IProps {
  getText: { t: TranslatorFunctionType }
  paymentDue: number
  paymentDueDate: string
  preArrival: boolean
  showErrorBlock: boolean
  loading?: boolean
}

const NextPayment: FC<IProps> = ({
  getText,
  paymentDue,
  paymentDueDate,
  preArrival,
  showErrorBlock,
  loading
}) => {
  const dateToDisplay = paymentDueDate
    ? moment(paymentDueDate).format('MMM DD, YYYY')
    : ''
  const currentPeriod = moment().format('MMM DD, YYYY')
  const [checkHybridUser, setHybridUser] = useState<boolean>(false)
  const subscriptionData: Subscription = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )

  useEffect(() => {
    if (subscriptionData && subscriptionData?.entities) {
      setHybridUser(isHybridUser(subscriptionData))
    }
  }, [subscriptionData])

  const txtUser = {
    labelHybridUser: getText.t(
      'NextPayment.last-updated-hybrid',
      'last updated (Laptop and Print)'
    ),
    labelPcUser: getText.t('NextPayment.last-updated', 'Last updated')
  }

  return (
    <Card data-testid="next-payment">
      {loading ? (
        <div
          data-testid="next-payment-loader"
          style={{ position: 'relative', height: '100%' }}
        >
          <LoadingHandler
            loadingText={getText.t('loader.text', 'Loading...')}
            loading={true}
          />
        </div>
      ) : !showErrorBlock ? (
        <Container state={preArrival}>
          {preArrival ? (
            <PreArrivalPayment data-test-id="pre-arrival-payment">
              {getText.t(
                'NextPayment.preArrivalText',
                'Minimum payment amount will display here soon'
              )}
            </PreArrivalPayment>
          ) : (
            <>
              <CardCalender>
                <IconCalendarDay size={32} color="colorGray7" />
              </CardCalender>
              <CardTitle>
                {getText.t('NextPayment.title', 'Minimum payment due')}
              </CardTitle>{' '}
              <FlexSpace>
                <CardlabelSmall>
                  {getText.t(
                    checkHybridUser
                      ? txtUser.labelHybridUser
                      : txtUser.labelPcUser
                  )}
                </CardlabelSmall>
                <CardContentSmall>{currentPeriod}</CardContentSmall>
              </FlexSpace>
              <AlignToBottom>
                <FlexSpace paddingBottom={false}>
                  <CardlabelContent>
                    {getText.t('NextPayment.payment-due-date', {
                      defaultValue: 'Due date'
                    })}
                  </CardlabelContent>
                  <CardlabelContent>{dateToDisplay}</CardlabelContent>
                </FlexSpace>
                <Border />
                <FlexSpaceBetween>
                  <CardlabelMedium>
                    {getText.t('NextPayment.payment-due', 'Payment due')}
                  </CardlabelMedium>
                  <CardlabelMediumText>
                    {FormatCurrency(paymentDue?.toFixed(2)) || 0}
                  </CardlabelMediumText>
                </FlexSpaceBetween>
              </AlignToBottom>
            </>
          )}
        </Container>
      ) : (
        <ErrorContainer data-testid="error-container">
          <InsideCard>
            <CardCalender>
              <IconCalendarDay size={32} color="colorGray7" />
            </CardCalender>
            <CardErrorTextOne>
              {getText.t(
                'NextPayment.Content-error-paragraph-1',
                'Something went wrong.'
              )}
            </CardErrorTextOne>
            <CardErrorTextOne>
              {getText.t(
                'NextPayment.Content-error-paragraph-2',
                'Your payment information did not load.'
              )}
            </CardErrorTextOne>
            <CardErrorTextTwo>
              {getText.t(
                'NextPayment.Content-error1',
                'Please refresh the page.'
              )}
            </CardErrorTextTwo>
          </InsideCard>
        </ErrorContainer>
      )}
    </Card>
  )
}

export default NextPayment
