import React from 'react'
import { NotificationBar } from '@jarvis/react-hpx-shared-components'

import { EntityState } from '../../../constants'

const ReturnSuccess = ({ t, remorsePeriod, entityState, notificationData }) => {
  let message

  /* istanbul ignore next */
  if (remorsePeriod) {
    message = (
      <>
        {notificationData.isHybridUser
          ? t(
              'PostCancelNotification-Hybrid.ReturnSuccess.remorseMessage',
              'Your HP All-In Plans have been cancelled and we have received your device returns'
            )
          : t(
              'PostCancelNotification-PC.ReturnSuccess.remorseMessage',
              'Your HP All-In Laptop Plan has been cancelled and we have received your laptop return'
            )}
      </>
    )
  } else {
    message = (
      <>
        {entityState === EntityState.CANCELLED
          ? t(
              'PostCancelNotification-PC.ReturnSuccess.notremorseMessage',
              'Your HP All-In Laptop Plan has been cancelled'
            )
          : t(
              'PostCancelNotification-PC.ReturnSuccess.remorseMessage',
              'Your HP All-In Laptop Plan has been cancelled and we have received your laptop return'
            )}
      </>
    )
  }

  return (
    <div data-testid="ReturnSuccess">
      <NotificationBar
        statusType="informative"
        title={message}
        showCloseButton={false}
      />
    </div>
  )
}

export default ReturnSuccess
