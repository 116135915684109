import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
const { fontSize6, fontTextRegular, lineHeight4, space2, fontSize2 } = tokens
export const CardTitle = styled.p`
  font-size: ${fontSize6};
  font-family: ${fontTextRegular};
  font-weight: 700;
  line-height: ${lineHeight4};
  text-align: center;
  padding-bottom: ${space2};
`

export const CardContent = styled.p`
  font-size: ${fontSize2};
  font-family: ${fontTextRegular};
  font-weight: 500;
  line-height: ${lineHeight4};
  text-align: center;
`
export const CardButton = styled.p`
  text-align: center;
  margin-top: 14px;
`

export const Container = styled.div`
  padding-top: 30px;
`
