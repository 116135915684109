import React, { useState, useEffect, useMemo } from 'react'
import { IconXCircle, IconEye } from '@veneer/core/dist/scripts/icons'
import {
  CardLabelHpOne,
  SubscriptionBar,
  Line,
  Section,
  Container
} from './style'
import { captureClickEvents } from '../../services/DataCollection/clickActions'
import { AnalyticsClickEventsList, EntityState } from '../../constants'
import { useAppSelector } from '../../customHooks'
import type { Subscription } from '../../types/services/subscription'
import { internalURLs } from '../../configs/internal-link'
import 'regenerator-runtime'
import CancelSubscription from '../CancelSubscription'
import {
  isHybridUser,
  isInCancelState,
  isInRemorsePeriod
} from '../../utils/common-utils'
import { EntityType } from '../../constants'
import { Entitlements } from 'src/types/services/subscription'
import moment from 'moment'
import { FulfillmentOrdersClient } from '../../services/fulfillment-orders-api'
import { isDeviceShippedOrDelivered } from '../../utils/isDeviceShippedOrDelivered'

const SubscriptionButtons = ({ t, authProvider, stack, navigation }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [cancelEnabled, setCancelEnabled] = useState<boolean>(false)
  const [inRemorsePeriod, setInRemorsePeriod] = useState<boolean>()
  const [hybridUser, setHybridUser] = useState<boolean>()
  const [pcSubscriptionData, setPcSubscriptionData] = useState<any>()
  const [printerSubscriptionData, setPrinterSubscriptionData] = useState<any>()
  const subscriptionData: Subscription = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )
  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )
  const fulfillmentOrdersAPIClient = useMemo(() => {
    return new FulfillmentOrdersClient(authProvider, stack)
  }, [authProvider, stack])

  useEffect(() => {
    /* istanbul ignore next */
    if (subscriptionData && subscriptionData?.entities) {
      const hybridUser = isHybridUser(subscriptionData)
      setPcSubscriptionData(currentActivePc)
      if (hybridUser) {
        setPrinterSubscriptionData(
          subscriptionData.entities.filter(
            (item) => item.entityType === EntityType.PRINTER
          )[0]
        )
      }
      setHybridUser(hybridUser)
    }
  }, [subscriptionData])

  useEffect(() => {
    /* istanbul ignore next */
    const checkRemorsePeriod = async () => {
      const subscribedState = hybridUser
        ? pcSubscriptionData.state === EntityState.SUBSCRIBED &&
          printerSubscriptionData.state === EntityState.SUBSCRIBED
        : pcSubscriptionData.state === EntityState.SUBSCRIBED
      const pendingState = hybridUser
        ? pcSubscriptionData.state === EntityState.PENDING ||
          printerSubscriptionData.state === EntityState.PENDING
        : pcSubscriptionData.state === EntityState.PENDING

      if (subscribedState) {
        setCancelEnabled(true)
      } else if (isInCancelState(pcSubscriptionData?.state)) {
        setCancelEnabled(false)
      } else if (pendingState) {
        if (hybridUser) {
          const pcRemorse = isInRemorsePeriod(
            pcSubscriptionData.cancellation.value.validUntil,
            moment(new Date())
          )
          const printerRemorse = isInRemorsePeriod(
            printerSubscriptionData.cancellation.value.validUntil,
            moment(new Date())
          )
          setInRemorsePeriod(pcRemorse && printerRemorse)
        } else {
          const remorePeriodCheck = isInRemorsePeriod(
            pcSubscriptionData.cancellation.value.validUntil,
            moment(new Date())
          )
          setInRemorsePeriod(remorePeriodCheck)
        }
      }
    }

    if (hybridUser != null) checkRemorsePeriod()
  }, [hybridUser])

  /* istanbul ignore next */
  useEffect(() => {
    if (isInCancelState(pcSubscriptionData?.state)) {
      setCancelEnabled(false)
      return
    }
    const checkCancel = async () => {
      if (!pcSubscriptionData) return
      if (isHybridUser && inRemorsePeriod) {
        if (!printerSubscriptionData) return
        const isPcShippedPromise = isDeviceShippedOrDelivered(
          fulfillmentOrdersAPIClient,
          pcSubscriptionData
        )
        const isPrinterShippedPromise = isDeviceShippedOrDelivered(
          fulfillmentOrdersAPIClient,
          printerSubscriptionData
        )
        const [isPcShipped, isPrinterShipped] = await Promise.all([
          isPcShippedPromise,
          isPrinterShippedPromise
        ])
        setCancelEnabled(isPcShipped && isPrinterShipped)
      } else {
        const pcShipped = await isDeviceShippedOrDelivered(
          fulfillmentOrdersAPIClient,
          pcSubscriptionData
        )
        setCancelEnabled(pcShipped)
      }
    }

    void checkCancel()
  }, [
    fulfillmentOrdersAPIClient,
    inRemorsePeriod,
    pcSubscriptionData,
    printerSubscriptionData
  ])

  return (
    <SubscriptionBar>
      <a
        className="full-width accessibility-links"
        href={internalURLs.ViewAllSubscriptionURL}
        data-testid="viewall_subscription"
        tabIndex={0}
        onClick={(event) => {
          event.preventDefault()
          captureClickEvents(
            AnalyticsClickEventsList.VIEWALL_SUBSCRIPTION_LINK,
            subscriptionData,
            currentActivePc
          )
          navigation.push(internalURLs.ViewAllSubscriptionURL)
        }}
      >
        <Section>
          <CardLabelHpOne>
            <IconEye size={20} color="colorHpBlue6" />
          </CardLabelHpOne>
        </Section>
        <CardLabelHpOne data-testid="viewtext">
          {t('SubscriptionButtons.viewtext', 'View all subscriptions')}
        </CardLabelHpOne>
      </a>
      <Line isSuspended={!cancelEnabled} />
      <Container isSuspended={!cancelEnabled}>
        <a
          className="full-width accessibility-links"
          href="#"
          data-testid="cancel_subscription"
          tabIndex={0}
          onClick={() => {
            captureClickEvents(
              AnalyticsClickEventsList.CANCEL_SUBSCRIPTION_LINK,
              subscriptionData,
              currentActivePc
            )
            setOpen(true)
          }}
        >
          <Section>
            <CardLabelHpOne>
              <IconXCircle
                size={20}
                color={!cancelEnabled ? 'colorGray4' : 'colorHpBlue6'}
              />
            </CardLabelHpOne>
          </Section>
          <CardLabelHpOne data-testid="canceltext" isSuspended={!cancelEnabled}>
            {t('SubscriptionButtons.canceltext', 'Cancel plan')}
          </CardLabelHpOne>
        </a>
      </Container>
      <CancelSubscription
        t={t}
        show={open}
        setOpen={setOpen}
        authProvider={authProvider}
        stack={stack}
        hybridUser={hybridUser}
        remorsePeriod={inRemorsePeriod}
      ></CancelSubscription>
    </SubscriptionBar>
  )
}
export default SubscriptionButtons
