import { createSlice } from '@reduxjs/toolkit'

const initialSettings = {
  subscriptionData: {},
  subscriptionStatus: {},
  mouseId: '',
  keyboardId: '',
  uniqueDeviceId: '',
  shippingAddressId: '',
  googleStorageData: {},
  microsoftStorageData: {},
  tMobileConnectivityData: {},
  activePcData: '',
  laptopImage: '',
  maxDaysToReturn: ''
}

export const subscriptionDataSlice = createSlice({
  name: 'subscription',
  initialState: initialSettings,
  reducers: {
    updateSubscriptionData: (state, action) => {
      state.subscriptionData = action.payload
    },

    updateSubscriptionStatus: (state, { payload }) => {
      state.subscriptionStatus = {
        ...state.subscriptionStatus,
        status: payload.status,
        isSuspended: payload.isSuspended
      }
    },
    setEntityState: (state, { payload }) => {
      state.subscriptionStatus = {
        ...state.subscriptionStatus,
        isEntityEnabled: payload.isEntityEnabled
      }
    },
    updateGoogleStorageData: (state, action) => {
      state.googleStorageData = action.payload
    },
    updateMicrosoftStorageData: (state, action) => {
      state.microsoftStorageData = action.payload
    },
    updateTMobileConnectivityData: (state, action) => {
      state.tMobileConnectivityData = action.payload
    },
    updateMouseId: (state, action) => {
      state.mouseId = action.payload
    },
    updateKeyboardId: (state, action) => {
      state.keyboardId = action.payload
    },
    updateUniqueDeviceId: (state, action) => {
      state.uniqueDeviceId = action.payload
    },
    updateShippingAddressId: (state, action) => {
      state.shippingAddressId = action.payload
    },
    updateActivePC: (state, action) => {
      state.activePcData = action.payload
    },
    updatelaptopImage: (state, action) => {
      state.laptopImage = action.payload
    },
    updateMaxDaysToReturn: (state, action) => {
      state.maxDaysToReturn = action.payload
    }
  }
})

export const {
  updateSubscriptionData,
  updateSubscriptionStatus,
  updateMouseId,
  updateKeyboardId,
  updateUniqueDeviceId,
  updateShippingAddressId,
  updateGoogleStorageData,
  updateMicrosoftStorageData,
  updateTMobileConnectivityData,
  setEntityState,
  updateActivePC,
  updatelaptopImage,
  updateMaxDaysToReturn
} = subscriptionDataSlice.actions

export default subscriptionDataSlice.reducer
