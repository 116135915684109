import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
const {
  colorWhite,
  space4,
  fontTextRegular,
  colorGray7,
  fontSize4,
  fontSize5,
  lineHeight4,
  lineHeight5,
  space1
} = tokens
export const Card = styled.div`
  background-color: ${colorWhite};
  width: 100%;
  height: 267px;
  border-radius: 8px;
  gap: ${space4};
  @media (min-width: 641px) {
    width: calc(50% - 16px);
    min-width: calc(50% - 16px);
    flex: calc(50% - 16px);
  }
  @media (min-width: 1008px) {
    width: calc(33.33% - 16px);
    min-width: calc(33.33% - 16px);
    flex: calc(33.33% - 16px);
  }
`
export const CardTitle = styled.p`
  font-size: ${fontSize4};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight4};
  margin-bottom: ${space4};
  color: ${colorGray7};
  @media (min-width: 1008px) {
    font-size: ${fontSize5};
    line-height: ${lineHeight5};
  }
`

export const CardDocument = styled.div`
  margin-bottom: ${space1};
`

export const Container = styled.div`
  padding: ${space4};
`
