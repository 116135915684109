import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../customHooks'
import { IconCalendarDay, IconLaptop } from '@veneer/core/dist/scripts/icons'
import moment from 'moment'
import {
  Card,
  FlexSpaceBetween,
  Image,
  CardTitle,
  CardlabelSmall,
  LaptopImage,
  CardContainer,
  CardContent,
  Cardspace,
  CardspaceLaptop,
  CardNotload,
  CardLaptopIcon,
  CardError,
  TextBlock,
  CardLabelText,
  CardIconLaptop,
  CardText
} from './Style'

import { LoadingHandler } from '@jarvis/react-hpx-shared-components'
import type { DeviceDetails } from '../../types/services/device-details'
import { useAppDispatch } from '../../customHooks'
import { addNotification } from '../../stores/api-notifications'
import { APINotificationList } from '../../constants'
import type {
  Subscription,
  Entitlements
} from '../../types/services/subscription'
import { useLazyGetDeviceDetails } from '../../services/ApolloClient'
import { findCoreOfferPlan } from '../../utils/common-utils'

const LaptopDetails = ({ t, authProvider, stack }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [deviceData, setDeviceData] = useState<DeviceDetails>(null)
  const [apiError, setApiError] = useState<boolean>(false)
  const [upgradePCDate, setUpgradePCDate] = useState<string>('')
  const [getDeviceDetails] = useLazyGetDeviceDetails()

  const dispatch = useAppDispatch()
  const subscriptionData: Subscription = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )

  const activePcData: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )
  const parentSku = activePcData?.product?.value?.parentSku
  const productSku = activePcData?.product?.value?.productSku
  const serialNumber = activePcData?.entityDetails?.serialNumber

  useEffect(() => {
    /* istanbul ignore next */
    const fetch = async () => {
      if (activePcData) {
        const subscriptionStartDate = activePcData.entityStartDate

        const pcDate = moment(subscriptionStartDate)
          .add(2, 'years')
          .format('MMM DD, YYYY')
        setUpgradePCDate(pcDate)
      }
    }
    if (subscriptionData?.entities?.length) fetch()
  }, [subscriptionData])

  useEffect(() => {
    const fecthDeviceDetails = async () => {
      await getDeviceDetails({
        variables: { parentSKU: parentSku },
        onError: () => {
          setApiError(true)
          setLoading(false)
          const deviceAPIWarningNotification = {
            statusType: 'negative',
            title: t(
              'NotificationsCard.deviceAPIWarning.title',
              'There was a problem while loading your device information.'
            ),
            description: t(
              'NotificationsCard.deviceAPIWarning.subtitle',
              'Please try again later.'
            ),
            notificationName: APINotificationList.DEVICE_API_WARNING,
            showCloseButton: true,
            className: 'notificationCard'
          }
          dispatch(addNotification(deviceAPIWarningNotification))
        },
        onCompleted: (data) => {
          setLoading(false)
          setApiError(false)
          setDeviceData(findCoreOfferPlan(data, productSku))
        }
      })
    }
    fecthDeviceDetails()
  }, [authProvider, stack])
  const { sku, thumbnail } = deviceData?.product || {}
  return (
    <Card loadingHeight={loading}>
      {loading ? (
        <LoadingHandler
          loadingText={t('loader.text', 'Loading...')}
          loading={true}
        />
      ) : apiError ? (
        <CardError data-testid="error-card">
          <CardLaptopIcon>
            <IconLaptop size={72} color="colorGray4" />
          </CardLaptopIcon>
          <CardNotload>
            {t(
              'LaptopDetails.CardNotload.title',
              'Something went wrong, your device information did not load '
            )}
          </CardNotload>
          <CardNotload>
            {t('LaptopDetails.CardNotload.subtitle', 'Please try again later')}
          </CardNotload>
        </CardError>
      ) : (
        <FlexSpaceBetween>
          <CardContainer data-testid="laptop-details">
            <CardspaceLaptop>
              <IconLaptop size={32} color="colorGray7" />
              <div>
                <TextBlock>
                  <CardTitle data-testid="username">
                    {deviceData?.label?.replace('One', '')}
                  </CardTitle>
                  {deviceData?.label?.match('One') && (
                    <CardLabelText>ONE</CardLabelText>
                  )}
                </TextBlock>
              </div>
            </CardspaceLaptop>
            <Cardspace>
              <CardlabelSmall>
                {t('LaptopDetails.product-no', 'Product number')}
              </CardlabelSmall>
              <CardContent data-testid="product-no">
                {sku?.includes('#') ? sku.split('#')[0] : ''}
              </CardContent>

              <CardlabelSmall>
                {t('LaptopDetails.serial-no', 'Serial number')}
              </CardlabelSmall>
              <CardContent data-testid="serial-no">{serialNumber}</CardContent>
              <CardlabelSmall>
                {t(
                  'LaptopDetails.eligibility-text',
                  'Eligible for laptop upgrade'
                )}
              </CardlabelSmall>
              <CardContent data-testid="eligibility">
                <IconCalendarDay size={20} color="colorGray7" />
                <CardText>{upgradePCDate}</CardText>
              </CardContent>
            </Cardspace>
          </CardContainer>
          <LaptopImage>
            {thumbnail?.url ? (
              <Image
                src={thumbnail?.url}
                width="312"
                height="220"
                alt={deviceData.label}
                data-testid="laptopImage"
              />
            ) : (
              <CardIconLaptop data-testid="laptopFallBackImage">
                <IconLaptop size={230} color="colorGray3" />
              </CardIconLaptop>
            )}
          </LaptopImage>
        </FlexSpaceBetween>
      )}
    </Card>
  )
}

export default LaptopDetails
