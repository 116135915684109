import { EntityState, EntityType } from '../constants'
import { GrantControllerApiClient } from '../services/grant-controller-api'
import moment from 'moment'

export const getCurrentActivePC = async (
  authProvider,
  stack,
  subscriptionDetailsDataFetch
) => {
  const status = [
    EntityState.SUBSCRIBED,
    EntityState.UPDATING,
    EntityState.PAUSED,
    EntityState.CANCELLED,
    EntityState.CANCELLING
  ]

  const deviceDetails = subscriptionDetailsDataFetch.entities.filter(
    (item) =>
      item.entityType === EntityType.PC ||
      item.entityType === EntityType.CHROMEBOOK
  )

  if (deviceDetails.length === 1) {
    return deviceDetails[0]
  } else if (deviceDetails.length === 0) {
    return null
  }
  const state_filtered_pc = deviceDetails.filter((entity) =>
    status.includes(entity.state)
  )
  if (state_filtered_pc.length === 1) {
    return state_filtered_pc[0]
  }

  let i = 0
  let index = -1
  for (i; i < deviceDetails.length; i++) {
    const grantControllerClient = new GrantControllerApiClient(
      authProvider,
      stack
    )
    try {
      const grantControllerData = await grantControllerClient.getActivePcDetails(
        deviceDetails[i].entityDetails.uniqueDeviceId
      )
      if (grantControllerData && grantControllerData.contents.length > 0) {
        grantControllerData.contents.filter(
          (item) =>
            item.deviceCloudId === deviceDetails[i].entityDetails.uniqueDeviceId
        )
        index = i
        return deviceDetails[i]
      }
    } catch (error) {
      i++
    }
  }
  if (index !== -1) {
    return deviceDetails[index]
  }

  const pendingPC = deviceDetails
    .filter((item) => item.state === EntityState.PENDING_RETURN)
    .sort((a, b) => {
      return (
        new Date(a.entityStartDate).getTime() -
        new Date(b.entityStartDate).getTime()
      )
    })
  if (pendingPC[0]) {
    return pendingPC[0]
  }
  return null
}

export const isHybridUser = (subscriptionData) => {
  const pcData = subscriptionData.entities.filter(
    (item) =>
      item.entityType === EntityType.PC ||
      item.entityType === EntityType.CHROMEBOOK
  )
  const printerData = subscriptionData.entities.filter(
    (item) => item.entityType === EntityType.PRINTER
  )
  if (pcData.length && printerData.length) {
    return true
  } else {
    return false
  }
}

export const isCheckedState = (pcState) => {
  if (pcState.state === EntityState.PENDING) {
    return true
  }
  return false
}

export const isInRemorsePeriod = (validUntil, currentDate) => {
  return moment(currentDate).isBefore(moment(validUntil))
}

export const isCancelEnabled = (subscriptionDetails) => {
  return subscriptionDetails.subscription.cancellation[0].enabled
}

export const isDeviceShipped = (subscriptionEntityData) => {
  return subscriptionEntityData.fulfillment.value.shippedDate ? true : false
}

export const getDeviceDetails = (entityData) => {
  return entityData?.entityDetails?.uniqueDeviceId
    ? entityData?.entityDetails?.uniqueDeviceId
    : null
}

export const isImageAvailable = (laptopData) => {
  if (laptopData.images && laptopData.images?.length > 0) {
    return true
  } else {
    return false
  }
}
export const findCoreOfferPlan = (data, productSku) => {
  const printerBundle = data?.products?.items[0]
  const coreOfferBundle = printerBundle?.items.find(
    (item) => item.title == 'Core Offer'
  )
  const coreOffer = coreOfferBundle?.options?.find(
    (plan) => plan.product?.sku === productSku
  )
  return coreOffer
}

export const isInCancelState = (state) => {
  if (
    state === EntityState.CANCELLED ||
    state === EntityState.CANCELLING ||
    state === EntityState.PENDING_RETURN ||
    state === EntityState.RETURNED
  ) {
    return true
  }
  return false
}
