import React, { useState, useEffect } from 'react'
import { IconChevronRight } from '@veneer/core/dist/scripts/icons'
import { Border, Cardlabel } from './style'
import { captureClickEvents } from '../../services/DataCollection/clickActions'
import { AnalyticsClickEventsList, EntityState } from '../../constants'
import DataCollection from '../../services/DataCollection'
import { uiEvents } from '../../services/DataCollection/simpleUiEvents'
import { getactionAuxParams } from '../../services/getactionAuxParams'
import GoogleActivateModal from './GoogleModals/GoogleActivateModal'
import ChangeStorageModal from './GoogleModals/ChangeStorageModal'
import { GoogleDetailsProps } from '../../types/services/google-details'

const GetGoogleButton: React.FunctionComponent<GoogleDetailsProps> = ({
  t,
  googleData,
  refetchApiFn,
  url
}) => {
  const activationOrLoginText =
    googleData.googleStorageData.state === EntityState.UPDATING
      ? t('CloudStorage.Googlelogin-link', 'Google Login')
      : t('CloudStorage.Activate-storage-plan', 'Activate storage plan')
  const [open, setOpen] = useState<boolean>(false)
  const [showActivateModal, setShowActivateModal] = useState<boolean>(false)

  function handleClose() {
    setOpen(false)
    setShowActivateModal(false)
  }

  useEffect(() => {
    const captureModelWindowDisplay = async () => {
      const actionAuxParams = await getactionAuxParams(
        googleData.subscriptionData,
        googleData.currentActivePc
      )
      const event = uiEvents.modelWindowDisplay
      event.actionAuxParams = actionAuxParams
      DataCollection.sendEvent(event)
    }
    if (open) {
      captureModelWindowDisplay()
      setTimeout(() => {
        document.getElementById('terms-agree').focus()
      }, 1000)
    }
  }, [open, googleData.subscriptionData, googleData.currentActivePc])

  return (
    <>
      <Border />
      {!googleData.isActivated && (
        <a
          data-testid="google-activated"
          className="External-link accessibility-links"
          href="#"
          onClick={() => {
            setShowActivateModal(true)
            captureClickEvents(
              AnalyticsClickEventsList.GOOGLE_LOGIN_LINK,
              googleData.subscriptionData,
              googleData.currentActivePc
            )
          }}
        >
          <Cardlabel>{activationOrLoginText}</Cardlabel>
          <Cardlabel>
            <IconChevronRight size={24} color="colorHpBlue6" />
          </Cardlabel>
        </a>
      )}
      {googleData.isActivated && (
        <>
          {googleData.eligibleForUpgrade ? (
            <>
              <a
                data-testid="upgrade-link"
                className="External-link accessibility-links"
                href="#"
                onClick={() => {
                  setOpen(true)
                  captureClickEvents(
                    AnalyticsClickEventsList.GETMORE_STORAGE_LINK,
                    googleData.subscriptionData,
                    googleData.currentActivePc
                  )
                }}
              >
                <Cardlabel>
                  {t('CloudStorage.Get-more-storage', 'Get more storage')}
                </Cardlabel>
                <Cardlabel>
                  <IconChevronRight size={24} color="colorHpBlue6" />
                </Cardlabel>
              </a>
              {open && (
                <ChangeStorageModal
                  {...{ t, googleData, handleClose, refetchApiFn, url }}
                />
              )}
            </>
          ) : (
            <>
              <a
                data-testid="downgrade-link"
                className="External-link accessibility-links"
                href="#"
                onClick={() => {
                  setOpen(true)
                }}
              >
                <Cardlabel>
                  {t('Downgrade-link', ' Downgrade to 200 GB')}
                </Cardlabel>
                <Cardlabel>
                  <IconChevronRight size={24} color="colorHpBlue6" />
                </Cardlabel>
              </a>
              {open && (
                <ChangeStorageModal
                  {...{
                    t,
                    googleData,
                    handleClose,
                    refetchApiFn,
                    url
                  }}
                />
              )}
            </>
          )}
        </>
      )}

      {showActivateModal && (
        <GoogleActivateModal {...{ t, googleData, handleClose, url }} />
      )}
    </>
  )
}

export default GetGoogleButton
