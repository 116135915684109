import React from 'react'
import { ActivateButton, ActivateButtonText, ActiveCardContent } from '../style'
import Modal from '@veneer/core/dist/scripts/modal'
import Button from '@veneer/core/dist/scripts/button'

interface Props {
  t
  handleClose: () => void
  showModal: boolean
}

const GoogleAlreadySubscribedModal: React.FunctionComponent<Props> = ({
  t,
  showModal,
  handleClose
}) => {
  return (
    <Modal
      data-testid="already-subscribed-modal"
      align="left"
      closeButton
      id="modal-id"
      show={showModal}
      onClose={() => {
        handleClose()
      }}
      footer={
        <>
          <ActivateButton>
            <Button
              appearance="primary"
              style={{
                borderRadius: '8px',
                padding: '8px 20px',
                color: 'colorHpBlue6'
              }}
            >
              <ActivateButtonText>
                {t('CloudStorage.GoogleAlreadySubscribed-button', 'Continue')}
              </ActivateButtonText>
            </Button>
          </ActivateButton>
        </>
      }
      title={t(
        'CloudStorage.GoogleAlreadySubscribed-title',
        'The Google Account you have chosen is already a Google One member'
      )}
    >
      <ActiveCardContent>
        {t(
          'CloudStorage.GoogleAlreadySubscribed-description',
          "Please click 'continue' to migrate your Google  One membership to HP All-In Plan."
        )}
      </ActiveCardContent>
    </Modal>
  )
}

export default GoogleAlreadySubscribedModal
