import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
const {
  colorGray7,
  colorWhite,
  fontTextRegular,
  colorGray10,
  colorGray5,
  colorGray0,
  space2,
  space4,
  lineHeight4,
  fontSize4,
  fontSize2,
  fontSize1,
  lineHeight2,
  lineHeight3,
  fontSize3,
  space1,
  space5,
  colorHpBlue6,
  colorGreen6
} = tokens
export const Card = styled.div`
  background-color: ${colorWhite};
  width: 100%;
  height: 267px;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  gap: ${space2};
  @media (min-width: 641px) {
    width: calc(50% - 16px);
    min-width: calc(50% - 16px);
    flex: calc(50% - 16px);
  }
  @media (min-width: 1008px) {
    width: calc(33.33% - 16px);
    min-width: calc(33.33% - 16px);
    flex: calc(33.33% - 16px);
  }
`
export const Container = styled.div`
  padding: ${space4};
  line-height: ${lineHeight4};
  opacity: ${(props) => (props.isSuspended ? 0.4 : 1)};
  text-decoration-line: none;
  pointer-events: ${(props) => (props.isSuspended ? 'none' : 'auto')};
`
export const Image = styled.img``

export const GoogleImage = styled.div``
export const CardTitle = styled.p`
  margin-top: ${space2};
  font-size: ${fontSize3};
  color: ${colorGray7};
  letter-spacing: 0.02em;
  line-height: ${lineHeight3};
`
export const CardErrorTitle = styled.p`
  font-size: ${fontSize4};
  color: ${colorGray7};
  letter-spacing: 0.02em;
  line-height: ${lineHeight4};
  margin-top: 2px;
  margin-bottom: ${space2};
`
export const CardContentMedium = styled.div`
  font-family: ${fontTextRegular};
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  color: ${colorGray7};
`
export const CardContentActivated = styled.div`
  font-family: ${fontTextRegular};
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  color: ${colorGreen6};
`
export const CardContentNotActivated = styled.div`
  font-family: ${fontTextRegular};
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  color: ${colorGray7};
`
export const CardContent = styled.p`
  display: inline-flex;
  gap: ${space1};
  align-items: center;
  margin-top: ${space1};
`
export const ActiveCardContent = styled.div`
  display: inline-flex;
  gap: 5px;
  align-items: center;
  margin-top: ${space1};
  font-size: ${fontSize3};
  color: ${colorGray10};
  line-height: ${lineHeight4};
`
export const CardTextContent = styled.div`
  display: inline-flex;
  gap: ${space1};
  align-items: center;
`
export const CardPlanContent = styled.p`
  font-family: ${fontTextRegular};
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  color: ${colorGray5};
`
export const Border = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: ${space2};
  position: relative;
`
export const AlignToTop = styled.div`
  position: relative;
`

export const AlignContent = styled.p`
  position: relative;
  margin-top: 63px;
`
export const Cardlabel = styled.p`
  font-size: ${fontSize2};
  font-family: ${fontTextRegular};
  line-height: ${lineHeight3};
  color: ${colorHpBlue6};
`
export const BtnGroup = styled.div`
  float: right;
`
export const ActivateButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${space2};
`
export const ActivateButtonText = styled.div`
  color: ${colorWhite};
`
export const TermsCondition = styled.div`
  display: flex;
  gap: ${space5};
  align-items: center;
  margin-top: -20px;
  padding-bottom: ${space5};
`
export const CardLabel = styled.div`
  font-size: ${fontSize3};
  line-height: ${lineHeight4};
  font-weight: 400;
`
export const ErrorContainer = styled.div`
  background-color: ${colorWhite};
  height: 100%;
  padding: ${space4};
`
export const CardErrorImage = styled.div`
  margin-top: 23px;
`
export const InsideCard = styled.div`
  background-color: ${colorGray0};
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-self: stretch;
`
export const CardErrorText = styled.div`
  font-family: ${fontTextRegular};
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSize3};
  line-height: ${lineHeight4};
  text-align: center;
  letter-spacing: 0.02em;
  color: ${colorGray5};
  margin-left: ${space4};
  margin-right: ${space4};
  margin-bottom: 17px;
  margin-top: -6px;
`
export const CardErrorLabel = styled.div`
  font-family: ${fontTextRegular};
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSize3};
  line-height: ${lineHeight4};
  text-align: center;
  letter-spacing: 0.02em;
  color: ${colorGray5};
  margin-left: ${space4};
  margin-right: ${space4};
  margin-bottom: 23px;
`
export const ErrorGoogleImage = styled.div`
  margin-left: 36px;
`
export const CardContentDialog = styled.p`
  padding-top: ${space5};
`
export const ModalLink = styled.p`
  color: ${colorHpBlue6};
`
export const CardText = styled.p`
  font-size: ${fontSize3};
  line-height: ${lineHeight4};
  font-weight: 400;
`
export const UpgradeModalLink = styled.p`
  color: ${colorHpBlue6};
  font-size: ${fontSize3};
  line-height: ${lineHeight4};
`
export const DowngradeModalLink = styled.a`
  color: ${colorHpBlue6};
  font-size: ${fontSize3};
  line-height: ${lineHeight4};
`
export const CardLabelText = styled.div`
  font-size: ${fontSize3};
  line-height: ${lineHeight4};
  font-weight: 400;
`
export const LinkLable = styled.p``
export const ViewLink = styled.p`
  display: flex;
  align-items: center;
  gap: ${space1};
  font-size: ${fontSize2};
  line-height: ${lineHeight3};
  color: ${colorHpBlue6};
`
export const CardActivated = styled.p`
  display: flex;
  align-items: center;
  gap: ${space1};
`
export const CardContentText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
