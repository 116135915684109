import React from 'react'
import { NotificationBar } from '@jarvis/react-hpx-shared-components'
import SupportAnchorLink from '../SupportAnchorLink'

const ReturnFailure = ({ t, notificationData }) => {
  const title = (
    <>
      {
        /* istanbul ignore next */
        notificationData.isHybridUser && notificationData.remorsePeriod
          ? t(
              'PostCancelNotification-Hybrid.ReturnFailure.title',
              'Your HP All-In Plans has been cancelled. We have not received your returns. You will be charged for devices and any peripherals.'
            )
          : t(
              'PostCancelNotification-PC.ReturnFailure.title',
              'Your HP All-In Laptop Plan has been cancelled. We have not received your return. You will be charged for the laptop.'
            )
      }
    </>
  )
  const description = (
    <div>
      <p>
        {t(
          'PostCancelNotification-PC.ReturnFailure.description',
          'Your return date has passed. For assistance, visit'
        )}{' '}
        <SupportAnchorLink {...{ t }} />
      </p>
    </div>
  )
  return (
    <div data-testid="ReturnFailure">
      <NotificationBar
        statusType="negative"
        title={title}
        description={description}
        showCloseButton={false}
      />
    </div>
  )
}

export default ReturnFailure
