import { createSlice } from '@reduxjs/toolkit'

const initialSettings = {
  notificationList: []
}

export const APINotificationDataSlice = createSlice({
  name: 'subscription',
  initialState: initialSettings,
  reducers: {
    addNotification: (state, action) => {
      const previousNotification = state.notificationList
        ? state.notificationList
        : []
      previousNotification.push(action.payload)
      const removeDuplicates = Object.values(
        previousNotification.reduce(
          (acc, obj) => ({ ...acc, [obj.notificationName]: obj }),
          {}
        )
      )
      state.notificationList = removeDuplicates
    },
    removeNotification: (state, action) => {
      const previousNotification = state.notificationList
      const newNotification = previousNotification.filter(
        (item) => item.notificationName != action.payload
      )
      state.notificationList = newNotification
    }
  }
})

export const {
  addNotification,
  removeNotification
} = APINotificationDataSlice.actions

export default APINotificationDataSlice.reducer
