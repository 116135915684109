import React from 'react'
import Images from '../../../assets/images'
import { redirectURLs } from '../../../configs/external-link'

import {
  Image,
  ErrorContainer,
  InsideCard,
  CardImage,
  CardErrorTextOne,
  CardErrorContainer,
  Cardlabel,
  ExternalLinkIconPreArival
} from '../style'

const PreArrivalCitizenPay = ({ t }) => {
  return (
    <ErrorContainer data-testid="error-container">
      <InsideCard>
        <CardImage>
          <Image
            src={Images.CitizenpayPrearrival}
            width="140"
            height="18"
            alt="Citizens Pay"
          />
        </CardImage>
        <CardErrorContainer>
          <CardErrorTextOne data-testid="prearrival">
            {t(
              'citizens-balance.PreArrival',
              'Citizens balance will display here soon'
            )}
          </CardErrorTextOne>

          <a
            className="CitizenpayPrearrival-link accessibility-links"
            href={redirectURLs.payEarlyURL}
            target="_blank"
            data-testid="support"
            rel="noreferrer"
            tabIndex={0}
          >
            <Cardlabel>
              {t(
                'citizens-balance.manage-payments',
                'Manage payment at Citizens Pay'
              )}
            </Cardlabel>
            <ExternalLinkIconPreArival size={24} color="colorHpBlue6" />
          </a>
        </CardErrorContainer>
      </InsideCard>
    </ErrorContainer>
  )
}

export default PreArrivalCitizenPay
