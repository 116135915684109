import { GrantControllerApiClient } from '../../services/grant-controller-api'

export const checkGrants = async (authProvider, stack, tenantId: string) => {
  const grantControllerClient = new GrantControllerApiClient(
    authProvider,
    stack
  )
  try {
    const grantControllerData = await grantControllerClient.getGrantForMS(
      tenantId
    )
    /* istanbul ignore next */
    const grantResponse = {
      url:
        grantControllerData.contents.length > 0
          ? grantControllerData.contents[0].enablement?.length > 0
            ? grantControllerData.contents[0]?.enablement[0]?.value?.url
            : ''
          : null,
      content: grantControllerData.contents.length === 0
    }

    return grantResponse
  } catch (error) {
    console.log(error)
  }
}
