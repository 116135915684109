import React from 'react'
import { Header } from './style'
import { HpxHeader } from '@jarvis/react-hpx-shared-components'

const SubscriptionOverviewHeader = ({ t }) => {
  return (
    <>
      <Header data-testid="hp-one-header">
        <HpxHeader
          title={t('SubscriptionOverviewHeader.title', 'Laptop Plan overview')}
        />
      </Header>
    </>
  )
}

export default SubscriptionOverviewHeader
