import React from 'react'
import { NotificationBar } from '@jarvis/react-hpx-shared-components'
import { redirectURLs } from '../../../configs/external-link'
import { captureClickEvents } from '../../../services/DataCollection/clickActions'
import { AnalyticsClickEventsList } from '../../../constants'
import { useAppSelector } from '../../../customHooks'
import { Entitlements } from 'src/types/services/subscription'

const ItemReceived = ({ t, notificationData }) => {
  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )
  const subscriptionData = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )

  const title = (
    <>
      {notificationData.isHybridUser && notificationData.remorsePeriod
        ? t(
            'PostCancelNotification-Hybrid.ItemReceived.title',
            'Your HP All-In Plans have been cancelled and your returns are being processed'
          )
        : t(
            'PostCancelNotification-PC.ItemReceived.title',
            'Your HP All-In Laptop Plan has been cancelled and your return is being processed'
          )}
    </>
  )
  const description = (
    <div>
      <p>
        {t(
          'PostCancelNotification-PC.ItemReceived.description',
          'Refer to your tracking information for more details. For assistance, visit'
        )}{' '}
        <a
          data-testid="ItemReceived"
          href={redirectURLs.hpsupportPCaaSURL}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            /* istanbul ignore next */
            captureClickEvents(
              AnalyticsClickEventsList.POST_CANCEL_HP_SUPPORT_LINK,
              subscriptionData,
              currentActivePc
            )
          }}
        >
          {t(
            'PostCancelNotification-PC.ItemReceived.support-link',
            'HP Support'
          )}
        </a>
        .
      </p>
    </div>
  )
  return (
    <div data-testid="ItemReceived">
      <NotificationBar
        statusType="informative"
        title={title}
        description={description}
        showCloseButton={false}
      />
    </div>
  )
}

export default ItemReceived
