import React from 'react'
import { ActivateButton, ActivateButtonText, ActiveCardContent } from '../style'
import Modal from '@veneer/core/dist/scripts/modal'
import Button from '@veneer/core/dist/scripts/button'

interface Props {
  t
  handleClose: () => void
  showModal: boolean
  url: string
}

const GoogleSubscriptionIneligibleModal: React.FunctionComponent<Props> = ({
  t,
  showModal,
  handleClose,
  url
}) => {
  return (
    <Modal
      data-testid="subscription-ineligible-modal"
      align="left"
      closeButton
      id="modal-id"
      show={showModal}
      onClose={() => {
        handleClose()
      }}
      footer={
        <>
          <ActivateButton>
            <a
              data-testid="go-back"
              className="External-link accessibility-links"
              href={url}
            >
              <Button
                appearance="primary"
                style={{
                  borderRadius: '8px',
                  padding: '8px 20px',
                  color: 'colorHpBlue6'
                }}
              >
                <ActivateButtonText>
                  {t(
                    'CloudStorage.GoogleSubscriptionIneligible-back',
                    'Go Back'
                  )}
                </ActivateButtonText>
              </Button>
            </a>
          </ActivateButton>
        </>
      }
      title={t(
        'CloudStorage.GoogleSubscriptionIneligible-title',
        'The Google Account you have chosen is not eligible.'
      )}
    >
      <ActiveCardContent>
        {t(
          'CloudStorage.GoogleSubscriptionIneligible-paragraph1',
          'Google One is not available for organizations using Google Workspace Business or Enterprise editions. Google One is available to use through a personal Google Account.'
        )}
        <br />
        <br />
        {t(
          'CloudStorage.GoogleSubscriptionIneligible-paragraph2',
          "Please click 'Go Back' to sign into your personal Google Account or to create a new Google Account."
        )}
      </ActiveCardContent>
    </Modal>
  )
}

export default GoogleSubscriptionIneligibleModal
