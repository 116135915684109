import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { Stack } from '@jarvis/web-stratus-client'
// import { JarvisAuthProvider } from '@jarvis/web-http'
import fetch from 'cross-fetch'
import { setContext } from '@apollo/client/link/context'

const baseUrl = {
  [Stack.prod]: 'https://us1.api.ws-hp.com/magento/v1/graphql',
  [Stack.dev]: 'https://pie-us1.api.ws-hp.com/magento/v1/graphql',
  [Stack.stage]: 'https://stage-us1.api.ws-hp.com/magento/v1/graphql',
  [Stack.pie]: 'https://pie-us1.api.ws-hp.com/magento/v1/graphql'
}

export const createApolloClient = (authToken: string, stack: Stack) => {
  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: authToken.length ? `Bearer ${authToken}` : ''
      }
    }
  })

  const httpLink = new HttpLink({
    uri: baseUrl[stack],
    fetch
  })

  const settings = {
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink)
    // link: from([httpLink])
  }

  return new ApolloClient(settings)
}
