import React, { useState, useEffect } from 'react'
import { useAppSelector } from '../../customHooks'
import {
  Card,
  Cardlabel,
  Image,
  TextBlock,
  CardLabelText,
  CardIconMouse
} from './style'
import type { Device } from '../../types/services/device'
import { DeviceCacheApiClient } from '../../services/device-cache-api'
import { IconMouse } from '@veneer/core/dist/scripts/icons'
import { LoadingHandler } from '@jarvis/react-hpx-shared-components'

const MouseDetails = ({ t, authProvider, stack }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [mouseData, setMouseData] = useState<Device>()
  const [apiError, setApiError] = useState<boolean>(false)
  const mouseId = useAppSelector(
    (state) => state.userSubscriptionDetails.mouseId
  )
  useEffect(() => {
    const deviceCacheApiClient = new DeviceCacheApiClient(authProvider, stack)
    const fetch = async () => {
      try {
        const accessoriesDetailsFetch: Device = await deviceCacheApiClient.show(
          mouseId
        )
        setApiError(false)
        setMouseData(accessoriesDetailsFetch)
        setLoading(false)
      } catch (err) {
        setLoading(false)

        setApiError(true)
      }
    }
    if (mouseId) fetch()
  }, [mouseId, authProvider, stack])

  return loading ? (
    <LoadingHandler
      loadingText={t('loader.text', 'Loading...')}
      loading={true}
    />
  ) : (
    !apiError && (
      <Card data-testid="mouse-details">
        {mouseData.images ? (
          <Image
            src={mouseData?.images[0]?.url}
            width="100px"
            height="60.25px"
            alt="Mouse"
            data-testid="mouseImage"
          />
        ) : (
          <CardIconMouse>
            <IconMouse size={96} color="colorGray3" />
          </CardIconMouse>
        )}
        <TextBlock>
          <Cardlabel data-testid="MouseName">
            {mouseData?.identity.makeAndModel.name}
          </Cardlabel>
          <CardLabelText>ONE</CardLabelText>
        </TextBlock>
      </Card>
    )
  )
}

export default MouseDetails
