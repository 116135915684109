import { APINotificationList } from '../../constants'
import DataCollection from './index'
import { uiEvents } from './simpleUiEvents'
import { getactionAuxParams } from '../getactionAuxParams'

export const captureToastDisplayEvents = async (
  subscriptionData,
  notificationName,
  currentActivePC
) => {
  const actionAuxParams = await getactionAuxParams(
    subscriptionData,
    currentActivePC
  )
  let eventResult

  switch (notificationName) {
    case APINotificationList.GOOGLE_UPGRADE_ERROR: {
      const event = uiEvents.googleOneUpgradeErrorNotification
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
    case APINotificationList.GOOGLE_UPGRADE_SUCCESS: {
      const event = uiEvents.googleOneUpgradeSuccessNotification
      event.actionAuxParams = actionAuxParams
      eventResult = DataCollection.sendEvent(event)
      break
    }
  }
  return eventResult
}
