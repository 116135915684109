import { useQuery, useLazyQuery } from '@apollo/client'
import {
  GET_BILLING_DETAILS,
  GET_CLOUD_PLAN_DETAILS,
  GET_DEVICE_DETAILS
} from './queries'
import { BillingDetailsResult, UseGetBillingDetailsProps } from '../types'

const useGetBillingDetails = (variables: UseGetBillingDetailsProps) => {
  return useQuery(GET_BILLING_DETAILS, { variables })
}

const useLazyGetBillingDetails = () =>
  useLazyQuery<BillingDetailsResult>(GET_BILLING_DETAILS)

const useLazyGetCloudPlanDetails = () =>
  useLazyQuery<any>(GET_CLOUD_PLAN_DETAILS)

const useLazyGetDeviceDetails = () => useLazyQuery<any>(GET_DEVICE_DETAILS)

export {
  useGetBillingDetails,
  useLazyGetBillingDetails,
  useLazyGetCloudPlanDetails,
  useLazyGetDeviceDetails
}
