import React, { useEffect, useState } from 'react'
import { IconPin, IconChevronRight } from '@veneer/core/dist/scripts/icons'
import {
  Card,
  CardTitle,
  Border,
  AlignToTopShippingAddress,
  Container,
  CardContent,
  InsideCard,
  CardErrorText,
  ErrorContainer,
  AddressContainer,
  CardPin,
  CardErrorTextOne
} from './style'
import '../../styles/global.scss'
import type { Address } from '../../types/services/Address'
import { ShippingAddressApiClient } from '../../services/shipping-address-api'
import { useAppSelector } from '../../customHooks'
import { internalURLs } from '../../configs/internal-link'
import { captureClickEvents } from '../../services/DataCollection/clickActions'

import type { Subscription } from '../../types/services/subscription'
import {
  AddressDetails,
  LoadingHandler
} from '@jarvis/react-hpx-shared-components'
import { EPreaArrivalState, AnalyticsClickEventsList } from '../../constants'
import { maskPhoneNumber } from '../../utils/maskPhoneNumber'
import { Entitlements } from 'src/types/services/subscription'
import { isInCancelState } from '../../utils/common-utils'

const ShippingAddress = ({
  t,
  authProvider,
  stack,
  navigation,
  preArrival
}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [subscriberAddress, setSubscriberAddress] = useState<Address>()
  const [
    showShippingAddressBlock,
    setShowShippingAddressBlock
  ] = useState<boolean>(false)
  const shippingAddressId = useAppSelector(
    (state) => state.userSubscriptionDetails.shippingAddressId
  )
  const subscriptionStatus = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionStatus
  )
  const subscriptionData: Subscription = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )

  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )

  const isCancelling = isInCancelState(currentActivePc?.state)
  const isSuspended =
    preArrival === EPreaArrivalState.PENDING &&
    subscriptionStatus?.isEntityEnabled

  const isCancelled = !isSuspended && isInCancelState(currentActivePc?.state)
  useEffect(() => {
    const billigAddressApiClient = new ShippingAddressApiClient(
      authProvider,
      stack
    )
    /* istanbul ignore next */
    const fetch = async () => {
      try {
        const accessoriesDetailsFetch = await billigAddressApiClient
          .getShippingAddressData(shippingAddressId)
          .catch((err) => {
            console.log(err)
          })
        if (!accessoriesDetailsFetch) return
        setSubscriberAddress(accessoriesDetailsFetch as Address)
        setShowShippingAddressBlock(true)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
    if (shippingAddressId) fetch()
  }, [shippingAddressId, authProvider, stack])
  const maskedPhoneNumber =
    subscriberAddress?.countryCode === 'US'
      ? subscriberAddress?.phoneNumber &&
        maskPhoneNumber(subscriberAddress?.phoneNumber)
      : subscriberAddress?.phoneNumber

  return (
    <Card>
      {loading ? (
        <div
          data-testid="shipping-address-loader"
          style={{ position: 'relative', height: '100%' }}
        >
          <LoadingHandler
            loadingText={t('loader.text', 'Loading...')}
            loading={true}
          />
        </div>
      ) : showShippingAddressBlock ? (
        <Container
          data-testid="shipping-address-container"
          isSuspended={
            isCancelled || isCancelling || !subscriptionStatus?.isEntityEnabled
          }
        >
          <CardPin>
            <IconPin data-testid="Icon-pin" size={32} color="colorGray7" />
          </CardPin>
          <CardTitle data-testid="title">
            {t('shippingAddress.title', 'Shipping address')}
          </CardTitle>
          <AddressContainer>
            <AddressDetails
              contactName={`${subscriberAddress.firstName} ${subscriberAddress.lastName}`.toLowerCase()}
              addressLine1={`${subscriberAddress.address},`.toLowerCase()}
              addressLine2={`${subscriberAddress.city.toLowerCase()}, ${subscriberAddress.state.toUpperCase()} ${
                subscriberAddress.postalCode
              }`}
              phoneNumber={maskedPhoneNumber}
            />
          </AddressContainer>

          <AlignToTopShippingAddress
            isSuspended={!subscriptionStatus?.isEntityEnabled || isCancelled}
          >
            <Border />
            <a
              className="full-width accessibility-links"
              data-testid="manage-address-link"
              href={internalURLs.ShippingAdressURL}
              tabIndex={0}
              onClick={(event) => {
                event.preventDefault()
                captureClickEvents(
                  AnalyticsClickEventsList.MANAGE_ADDRESSES_LINK,
                  subscriptionData,
                  currentActivePc
                )
                navigation.push(internalURLs.ShippingAdressURL)
              }}
            >
              <CardContent
                isSuspended={
                  !subscriptionStatus?.isEntityEnabled || isCancelled
                }
              >
                {t('shippingAddress.manage-text', 'Manage address')}
              </CardContent>
              <CardContent>
                <IconChevronRight
                  size={24}
                  color={
                    !subscriptionStatus?.isEntityEnabled || isCancelled
                      ? 'colorGray4'
                      : 'colorHpBlue6'
                  }
                />
              </CardContent>
            </a>
          </AlignToTopShippingAddress>
        </Container>
      ) : (
        <ErrorContainer data-testid="error-container">
          <InsideCard>
            <IconPin data-testid="Icon-pin" size={32} color="colorGray7" />
            <CardErrorText>
              {t(
                'shippingAddress.Content-error',
                'Something went wrong. Your location information did not load.'
              )}
            </CardErrorText>
            <CardErrorTextOne>
              {t('shippingAddress.Content-error2', 'Please refresh the page.')}
            </CardErrorTextOne>
          </InsideCard>
        </ErrorContainer>
      )}
    </Card>
  )
}
export default ShippingAddress
