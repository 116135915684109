import React, { useEffect, useState } from 'react'

import { CardComponents, CardTitle } from './style'

import KeyboardDetails from '../KeyboardDetails/index'
import MouseDetails from '../MouseDetails/index'
import LaptopDetails from '../LaptopDetails'
import { addNotification } from '../../stores/api-notifications'
import PreArrivalLaptopDetails from '../LaptopDetails/PreArrivalLaptopDetails/index'

import { useAppDispatch, useAppSelector } from '../../customHooks'
import {
  updateKeyboardId,
  updateMouseId,
  updateUniqueDeviceId
} from '../../stores/subscription'
import { APINotificationList, EntityType, EntityState } from '../../constants'
import { getDeviceDetails } from '../../utils/common-utils'
import { Entitlements } from 'src/types/services/subscription'

const DeviceBlock = ({ t, authProvider, stack, subscriptionDetailsData }) => {
  const dispatch = useAppDispatch()
  const [showLaptopBlock, setshowLaptopBlock] = useState<boolean>(false)
  const [preArrival, setPreArrival] = useState<boolean>(false)
  const [showMouseBlock, setShowMouseBlock] = useState<boolean>(false)
  const [showKeyboardBlock, setShowKeyboardBlock] = useState<boolean>(false)

  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails?.activePcData
  )
  useEffect(() => {
    const showDeviceBlock = () => {
      currentActivePc?.state === EntityState.PENDING ||
      !getDeviceDetails(currentActivePc)
        ? preArrivalData(currentActivePc?.state)
        : deviceDetailsData()
    }
    showDeviceBlock()
  }, [subscriptionDetailsData])

  const preArrivalData = (state) => {
    const notification = {
      statusType: 'positive',
      title: (
        <>
          <CardTitle>
            {t(
              'NotificationsCard.PreArrivalSuccess.title',
              'Your laptop is on its way! '
            )}
          </CardTitle>
          <CardTitle>
            {t(
              'NotificationsCard.PreArrivalSuccess.subtitle',
              "You'll see information about your device below after you set up your laptop."
            )}
          </CardTitle>
        </>
      ),
      description: '',
      notificationName: APINotificationList.PREARRIVAL_LAPTOP,
      showCloseButton: true,
      className: 'notificationCard'
    }
    if (state === EntityState.PENDING) dispatch(addNotification(notification))
    setPreArrival(true)
  }

  const deviceDetailsData = () => {
    dispatch(
      updateUniqueDeviceId(currentActivePc.entityDetails?.uniqueDeviceId)
    )
    setshowLaptopBlock(true)
    const KeyboardDetails = subscriptionDetailsData?.entities.filter(
      (item) => item.entityType === EntityType.KEYBOARD
    )
    //set Keyboard Device ID to store
    if (
      KeyboardDetails?.length === 1 &&
      KeyboardDetails[0]?.entityDetails?.accessoryId
    ) {
      setShowKeyboardBlock(true)
      dispatch(updateKeyboardId(KeyboardDetails[0].entityDetails.accessoryId))
    }

    const mouseDetails = subscriptionDetailsData?.entities.filter(
      (item) => item.entityType === EntityType.MOUSE
    )
    //set Mouse Device ID to store
    if (
      mouseDetails?.length === 1 &&
      mouseDetails[0]?.entityDetails?.accessoryId
    ) {
      setShowMouseBlock(true)
      dispatch(updateMouseId(mouseDetails[0].entityDetails.accessoryId))
    }
  }

  return (
    <>
      {showLaptopBlock && (
        <LaptopDetails
          {...{
            t,
            authProvider,
            stack
          }}
        />
      )}
      {preArrival && <PreArrivalLaptopDetails {...{ t }} />}
      {(showKeyboardBlock || showMouseBlock) && (
        <CardComponents>
          {showKeyboardBlock && (
            <KeyboardDetails
              {...{
                t,
                authProvider,
                stack
              }}
            />
          )}
          {showMouseBlock && (
            <MouseDetails
              {...{
                t,
                authProvider,
                stack
              }}
            />
          )}
        </CardComponents>
      )}
    </>
  )
}

export default DeviceBlock
