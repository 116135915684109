import { configureStore, Store } from '@reduxjs/toolkit'
import subscriptionReducer from './subscription'
import APINotificationReducer from './api-notifications'

export const store: Store = configureStore({
  reducer: {
    userSubscriptionDetails: subscriptionReducer,
    apiNotificationDetails: APINotificationReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
