import React, { useEffect } from 'react'
import ReturnSuccess from '../ReturnSuccess'
import ReturnFailure from '../ReturnFailure'
import InProcess from '../InProcess'
import CountDownStage from '../CountDownStage'
import ItemReceived from '../ItemReceived'
import { EntityState, ReturnStates } from '../../../constants'
import { PostCancelNotificationProps } from '../../../types/services/cancel-return'
import { useAppSelector } from '../../../customHooks'
import type { Subscription } from '../../../types/services/subscription'
import { getactionAuxParams } from '../../../services/getactionAuxParams'
import DataCollection from '../../../services/DataCollection'
import { uiEvents } from '../../../services/DataCollection/simpleUiEvents'
import { isInCancelState } from '../../../utils/common-utils'

const NotificationContainer: React.FunctionComponent<PostCancelNotificationProps> = ({
  t,
  notificationData
}) => {
  const subscriptionData: Subscription = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )

  useEffect(() => {
    const captureModelWindowDisplay = async () => {
      const actionAuxParams = await getactionAuxParams(
        subscriptionData,
        notificationData
      )
      const event = uiEvents.cancelSubscriptionModuleDisplay
      event.actionAuxParams = actionAuxParams
      DataCollection.sendEvent(event)
    }
    if (isInCancelState(notificationData.subscriptionState))
      captureModelWindowDisplay()
  }, [notificationData, subscriptionData])

  return (
    <>
      {notificationData?.subscriptionState === EntityState.CANCELLING ? (
        <InProcess {...{ t }} notificationData={notificationData} />
      ) : (
        <>
          {notificationData?.subscriptionState === EntityState.CANCELLED ? (
            <ReturnSuccess
              {...{ t }}
              remorsePeriod={notificationData?.remorsePeriod}
              entityState={notificationData?.subscriptionState}
              notificationData={notificationData}
            />
          ) : (
            <>
              {(notificationData.returnStatus === ReturnStates.NEW ||
                notificationData.returnStatus === ReturnStates.INITIATED ||
                notificationData.returnStatus === ReturnStates.PROCESSING) && (
                <CountDownStage
                  {...{ t }}
                  subscriptionData={subscriptionData}
                  notificationData={notificationData}
                />
              )}
              {notificationData.returnStatus ===
                ReturnStates.COMPLETESUCCESS && (
                <ReturnSuccess
                  {...{ t }}
                  remorsePeriod={notificationData?.remorsePeriod}
                  entityState={notificationData?.subscriptionState}
                  notificationData={notificationData}
                />
              )}
              {notificationData.returnStatus === ReturnStates.ITEMRECEIVED && (
                <ItemReceived {...{ t }} notificationData={notificationData} />
              )}
              {(notificationData.returnStatus ===
                ReturnStates.COMPLETEUNSUCCESS ||
                notificationData.returnStatus ===
                  ReturnStates.COMPLETETIMEOUT ||
                notificationData.returnStatus === ReturnStates.TIMEOUT) && (
                <ReturnFailure {...{ t }} notificationData={notificationData} />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default NotificationContainer
