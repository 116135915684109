import React from 'react'
import { ActiveCardContent } from '../style'

const GoogleActivateContent = ({ t }) => {
  return (
    <div>
      <ActiveCardContent>
        {t(
          'CloudStorage.Content5',
          'To activate, you will need to give HP permission to manage your Google One subscription.'
        )}
      </ActiveCardContent>
    </div>
  )
}
export default GoogleActivateContent
