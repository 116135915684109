import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { TranslatorFunctionType } from '../../types/localization'
import { MfePropsType } from '../../types/mfeProps'
import '../../styles/global.scss'
import {
  CardContainer,
  CardSubcription,
  Container,
  PaymentTitle,
  SubscriptionIncludes,
  SubscriptionOneDetails,
  SubscriptionTitle
} from './styles'
import SubscriptionOverviewHeader from '../SubscriptionOverviewHeader'
import PlanDetails from '../SubscriptionDetails'
import ApiErrorState from '../ApiErrorState'
import PaymentBlock from '../PaymentBlock/PaymentBlock'
import ShippingAddress from '../ShippingAddress'
import HPOneLiveSupport from '../HPOneSupport'
import GoogleCloudStorage from '../GoogleCloudStorage'
import MicrosoftStorage from '../MicrosoftStorage'
import Mobile5GConnectivity from '../5GConnectivity'
import DeviceBlock from '../DeviceBlock'
import SubscriptionButtons from '../SubscriptionButtons'
import PaymentNotification from '../PaymentNotification'
import { LoadingHandler } from '@jarvis/react-hpx-shared-components'
import { useAppDispatch, useAppSelector } from '../../customHooks'
import { EntityType, ThirdPartyType } from '../../constants'
import { SubscriptionApiClient } from '../../services/subscription-api'

import {
  updateActivePC,
  updateGoogleStorageData,
  updateMicrosoftStorageData,
  updateShippingAddressId,
  updateSubscriptionData,
  updateTMobileConnectivityData
} from '../../stores/subscription'
import DataCollection from '../../services/DataCollection'
import { uiEvents } from '../../services/DataCollection/simpleUiEvents'
import { getactionAuxParams } from '../../services/getactionAuxParams'
import PostCancelNotification from '../PostCancelNotification'
import { Subscription } from '../../types/services/subscription'
import NotificationArea from '../NotificationArea'
import { getCurrentActivePC, isInCancelState } from '../../utils/common-utils'
import { filterThirdPartyFromSubscription } from '../../utils/filterThirdPartyFromSubscription'

const SubscriptionOverview = ({
  t,
  authProvider,
  stack,
  navigation
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [showGoogleBlock, setShowGoogleBlock] = useState<boolean>(false)
  const [showMicrosoftBlock, setShowMicrosoftBlock] = useState<boolean>(false)
  const [showConnectivity, setShowConnectivity] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [isCancelled, setCancelled] = useState<boolean>(false)
  const [blockGActivation, setBlockGActivation] = useState<boolean>(false)
  const [
    subscriptionDetailsData,
    setSubscriptionDetailsData
  ] = useState<Subscription>()
  const subscriptionStatus = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionStatus
  )

  const subscriptionClient = useMemo(
    () => new SubscriptionApiClient(authProvider, stack),
    [authProvider, stack]
  )
  /* istanbul ignore next */
  const fetchData = useCallback(async () => {
    try {
      const subscriptionDetailsDataFetch = await subscriptionClient.getSubscriptionDetailsData()

      if (!subscriptionDetailsDataFetch) return

      const activePC = await getCurrentActivePC(
        authProvider,
        stack,
        subscriptionDetailsDataFetch
      )
      if (!activePC) {
        setLoading(false)
        setError(true)
        return
      } else {
        setSubscriptionDetailsData(subscriptionDetailsDataFetch)
        dispatch(updateSubscriptionData(subscriptionDetailsDataFetch))
        dispatch(updateActivePC(activePC))
        setCancelled(isInCancelState(activePC.state))
      }

      //set address ID to store
      dispatch(updateShippingAddressId(activePC.shippingAddressId))

      const thirdPartyDetails = subscriptionDetailsDataFetch.entities.filter(
        (item) => item.entityType === EntityType.THIRDPARTY
      )
      //set 3PO details to store
      if (thirdPartyDetails.length > 0) {
        const googleOne = filterThirdPartyFromSubscription(
          thirdPartyDetails,
          ThirdPartyType.GOOGLE
        )
        if (googleOne.length === 1) {
          setShowGoogleBlock(true)
          dispatch(updateGoogleStorageData(googleOne[0]))
        }
        const microSoft = filterThirdPartyFromSubscription(
          thirdPartyDetails,
          ThirdPartyType.MICROSOFT
        )
        if (microSoft.length === 1) {
          setShowMicrosoftBlock(true)
          dispatch(updateMicrosoftStorageData(microSoft[0]))
        }
        const tmobile = filterThirdPartyFromSubscription(
          thirdPartyDetails,
          ThirdPartyType.TMOBILE
        )
        if (tmobile.length === 1) {
          setShowConnectivity(true)
          dispatch(updateTMobileConnectivityData(tmobile[0]))
        }
      }
      const actionAuxParams = getactionAuxParams(
        subscriptionDetailsDataFetch,
        activePC
      )
      const event = uiEvents.modalScreenDisplayed
      event.actionAuxParams = actionAuxParams
      DataCollection.sendEvent(event)

      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(true)
    }
  }, [authProvider, dispatch, stack, subscriptionClient])

  useEffect(() => {
    setError(false)
    setLoading(true)

    void fetchData()
  }, [fetchData])

  const refechAPIfn = () => {
    setError(false)
    void fetchData()
  }

  return loading || error ? (
    <Container>
      <SubscriptionOverviewHeader {...{ t }} />
      {loading && (
        <LoadingHandler
          loadingText={t('loader.text', 'Loading...')}
          loading={true}
        />
      )}

      {error && <ApiErrorState getText={{ t }} refetchApiFn={refechAPIfn} />}
    </Container>
  ) : (
    <>
      <Container>
        <NotificationArea />
        <PostCancelNotification
          {...{
            t,
            authProvider,
            stack,
            subscriptionDetailsData
          }}
        />
        <PaymentNotification
          {...{
            t,
            authProvider,
            stack
          }}
        />
        <SubscriptionOverviewHeader {...{ t }} />
        <CardContainer>
          <PaymentTitle>
            {t('SubscriptionOverview.payment-title', 'Payment details')}
          </PaymentTitle>
          <SubscriptionOneDetails>
            <PlanDetails getText={{ t }} />
            {subscriptionDetailsData && (
              <PaymentBlock
                {...{
                  t,
                  authProvider,
                  stack
                }}
              />
            )}
            <ShippingAddress
              {...{
                t,
                authProvider,
                stack,
                navigation,
                preArrival: subscriptionDetailsData?.state
              }}
            />
            {showGoogleBlock && (
              <GoogleCloudStorage
                {...{
                  t,
                  authProvider,
                  stack,
                  refechAPIfn,
                  blockGActivation,
                  setBlockGActivation
                }}
                refetchApiFn={refechAPIfn}
              />
            )}
            {showMicrosoftBlock && (
              <MicrosoftStorage {...{ t, authProvider, stack }} />
            )}
            {showConnectivity && <Mobile5GConnectivity {...{ t }} />}
          </SubscriptionOneDetails>

          <HPOneLiveSupport {...{ t }} />
          <SubscriptionIncludes>
            <SubscriptionTitle>
              {t('SubscriptionOverview.plan-title', 'Plan details')}
            </SubscriptionTitle>
            <SubscriptionButtons
              {...{
                t,
                authProvider,
                stack,
                navigation
              }}
            />
          </SubscriptionIncludes>
          <CardSubcription
            data-testid="CardSubcription"
            isSuspended={!subscriptionStatus?.isEntityEnabled || isCancelled}
          >
            {subscriptionDetailsData && (
              <DeviceBlock
                subscriptionDetailsData={subscriptionDetailsData}
                {...{
                  t,
                  authProvider,
                  stack
                }}
              />
            )}
          </CardSubcription>
        </CardContainer>
      </Container>
    </>
  )
}

export default SubscriptionOverview
