import React from 'react'
import {
  IconSlashCircle,
  IconCheckCircle
} from '@veneer/core/dist/scripts/icons'
import {
  CardContentActivated,
  CardContentNotActivated,
  CardTextContent
} from './style'
import { useAppSelector } from '../../customHooks'
import { tokens } from '@veneer/tokens'
const { colorGreen6, colorGray4, colorGray7 } = tokens
const MicrosoftButtonContent = ({ t, msData, isCancelled }) => {
  const subscriptionStatus = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionStatus
  )
  return (
    <CardTextContent>
      {msData.content ? (
        <>
          <IconCheckCircle
            data-testid="IconCheckCircle"
            size={20}
            style={{
              color:
                !subscriptionStatus.isEntityEnabled || isCancelled
                  ? colorGray4
                  : colorGreen6
            }}
          />
          <CardContentActivated
            data-testid="CardActivated"
            isSuspended={!subscriptionStatus.isEntityEnabled || isCancelled}
          >
            {t('CloudStorage.activated', 'Activated')}
          </CardContentActivated>
        </>
      ) : (
        <>
          <IconSlashCircle
            data-testid="IconSlashCircle"
            size={20}
            style={{
              color:
                !subscriptionStatus.isEntityEnabled || isCancelled
                  ? colorGray4
                  : colorGray7
            }}
          />
          <CardContentNotActivated
            isSuspended={!subscriptionStatus.isEntityEnabled || isCancelled}
          >
            {t('CloudStorage.Not-activated', 'Not activated')}
          </CardContentNotActivated>
        </>
      )}
    </CardTextContent>
  )
}

export default MicrosoftButtonContent
