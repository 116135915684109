import {
  Stack,
  StratusClient
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'

import type { ReturnDetailsTenantData } from '../types/services/returns'
import type { JarvisAuthProvider } from '@jarvis/web-http'
import type { AxiosResponse } from 'axios'

const baseUrl = {
  [Stack.prod]: 'https://us1.api.ws-hp.com/returns/v2',
  [Stack.stage]: 'https://stage-us1.api.ws-hp.com/returns/v2',
  [Stack.pie]: 'https://pie-us1.api.ws-hp.com/returns/v2',
  [Stack.dev]: 'https://pie-us1.api.ws-hp.com/returns/v2'
}

export class ReturnsClient extends StratusClient {
  apiName = 'ws-hp.com/returns'
  apiVersion = '1'
  timeout = 0

  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(baseUrl[stack], authToken)
  }

  async getReturnDetailsByTenantId(
    tenantId?: string
  ): Promise<ReturnDetailsTenantData[]> {
    return this.jarvisWebHttpInstance
      .get({
        url: `/return-details/tenants/${tenantId}`,
        timeout: this.timeout
      })
      .then(
        (response: AxiosResponse<ReturnDetailsTenantData[]>) => response.data
      )
  }
}
