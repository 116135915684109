import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'

import { Stack } from '../types/stratus'
import type { Device } from '../types/services/device'
import { URLS } from './urls-api'

export class DeviceCacheApiClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(URLS[stack].API_URLS.DEVICE_CACHE_URL, authToken)
  }

  async show(id: string): Promise<Device> {
    return this.jarvisWebHttpInstance
      .get({
        url: `/devices/${id}`
      })
      .then(({ data: accessoriesDetails }) => accessoriesDetails)
  }
}
