import React, { useState, useEffect } from 'react'
import { IconCloud, IconExternalLink } from '@veneer/core/dist/scripts/icons'
import Images from '../../assets/images'
import {
  Image,
  Card,
  CardTitle,
  CardContent,
  Container,
  Border,
  CardContentMedium,
  CardPlanContent,
  AlignContent,
  MicrosoftImage,
  AlignToBottom,
  InsideCard,
  CardErrorText,
  ErrorContainer,
  CardErrorContent,
  MicrosoftPersonalImage
} from './style'

import { LoadingHandler } from '@jarvis/react-hpx-shared-components'

import { useAppDispatch, useAppSelector } from '../../customHooks'
import { useLazyGetCloudPlanDetails } from '../../services/ApolloClient'
import { Entitlements, Subscription } from 'src/types/services/subscription'
import { ThirdPartyType, APINotificationList } from '../../constants'
import { isInCancelState } from '../../utils/common-utils'
import MicrosoftButtonContent from './MicrosoftButtonContent'
import { addNotification } from '../../stores/api-notifications'
import {
  updatelaptopImage,
  updateMaxDaysToReturn
} from '../../stores/subscription'
import { checkGrants } from './utility'
import { redirectURLs } from '../../configs/external-link'
import { tokens } from '@veneer/tokens'
import { useFlags } from 'launchdarkly-react-client-sdk'
const MicrosoftStorage = ({ t, authProvider, stack }) => {
  const flags = useFlags()
  const dispatch = useAppDispatch()
  const [getms65PlanDetails] = useLazyGetCloudPlanDetails()
  const [microsoftPlanDetails, setMicrosoftPlanDetails] = useState<string>()
  const [showMicrosoftBlock, setShowMicrosoftBlock] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [msData, setMSData] = useState(null)
  const { colorGray4, colorGray7, colorHpBlue6 } = tokens
  const subscriptionData: Subscription = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionData
  )

  const subscriptionStatus = useAppSelector(
    (state) => state.userSubscriptionDetails.subscriptionStatus
  )
  const microsoftStorageData = useAppSelector(
    (state) => state.userSubscriptionDetails.microsoftStorageData
  )
  const currentActivePc: Entitlements = useAppSelector(
    (state) => state.userSubscriptionDetails.activePcData
  )
  const isCancelled = isInCancelState(currentActivePc?.state)

  const msPlanDetailsData = (data) => {
    /* istanbul ignore next */
    data.options.forEach((childItem) => {
      if (
        childItem?.product?.hp_product_type_label ===
          ThirdPartyType.MICROSOFT &&
        microsoftStorageData?.product?.value?.productSku ===
          childItem?.product?.sku
      ) {
        setMicrosoftPlanDetails(
          `${childItem.product.hp_storage_amount} ${childItem.product.hp_storage_uom_label}`
        )
      }
      if (
        childItem?.product?.hp_product_type_label === 'pc' &&
        currentActivePc.product.value.productSku === childItem?.product?.sku
      ) {
        const laptopImageURL = `${childItem.product.thumbnail?.url}`
        dispatch(updatelaptopImage(laptopImageURL))
      }
    })
  }

  const isShowMicrosoftActivationLink = flags?.showMicrosoftActivationLink

  useEffect(() => {
    const fetchData = async () => {
      if (microsoftStorageData?.product?.value?.parentSku) {
        const microsoftPlanID = microsoftStorageData.product.value.parentSku

        try {
          const eligibleforUpgradeData = await checkGrants(
            authProvider,
            stack,
            subscriptionData.tenantId
          )
          setMSData(eligibleforUpgradeData)
          if (!isCancelled && !eligibleforUpgradeData.content) {
            showNotification()
          }
          await getms65PlanDetails({
            variables: { parentSKU: microsoftPlanID },
            onError: () => {
              setShowMicrosoftBlock(false)
            },
            onCompleted: (data) => {
              /* istanbul ignore next */
              data.products.items[0].items.forEach((item) => {
                msPlanDetailsData(item)
              })
              dispatch(
                updateMaxDaysToReturn(
                  data.products.items[0].hp_max_days_to_return
                )
              )
            }
          })
        } catch (err) {
          console.log(err)
        } finally {
          setLoading(false)
        }
      }
    }
    fetchData()
  }, [microsoftStorageData, getms65PlanDetails, subscriptionData.tenantId])
  const showNotification = () => {
    const notification = {
      statusType: 'help',
      title: t(
        'NotificationsCard.microsoftInactiveNotification.title',
        'Your Microsoft 365 Personal subscription is ready for activation'
      ),
      description: (
        <div>
          {t(
            'NotificationsCard.microsoftInactiveNotification.subtitle',
            'Get access to premium Microsoft apps and Cloud storage'
          )}
        </div>
      ),
      notificationName: APINotificationList.MICROSOFT_INACTIVE,
      showCloseButton: true,
      className: 'notificationCard',
      hideIcon: true
    }
    dispatch(addNotification(notification))
  }

  return (
    <Card>
      {loading ? (
        <LoadingHandler
          loadingText={t('loader.text', 'Loading...')}
          loading={true}
        />
      ) : showMicrosoftBlock ? (
        <Container
          data-testid="modal-content"
          isSuspended={!subscriptionStatus.isEntityEnabled || isCancelled}
        >
          <Image
            data-testid="Image"
            src={Images.MicrosoftLogo}
            width="147"
            height="24"
            alt="Microsoft 365"
          />

          <MicrosoftPersonalImage
            data-testid="ImagePersonal"
            src={Images.MicrosoftPersonal}
            width="72"
            height="15"
            alt="Personal"
          />

          <CardTitle
            data-testid="connectivity_title"
            isSuspended={!subscriptionStatus.isEntityEnabled || isCancelled}
          >
            {t('CloudStorage.subscription', 'Subscription')}
          </CardTitle>

          <AlignContent>
            <CardPlanContent>{t('CloudStorage.plan', ' Plan')}</CardPlanContent>
            <CardContent>
              <IconCloud
                size={20}
                data-testid="Icon-cloud"
                style={{
                  color:
                    !subscriptionStatus.isEntityEnabled || isCancelled
                      ? colorGray4
                      : colorGray7
                }}
              />
              <CardContentMedium
                data-testid="plan_detail"
                isSuspended={!subscriptionStatus.isEntityEnabled || isCancelled}
              >
                {microsoftPlanDetails}
              </CardContentMedium>
            </CardContent>
            <div>
              <MicrosoftButtonContent {...{ t, msData, isCancelled }} />
            </div>

            <AlignToBottom>
              <Border></Border>
            </AlignToBottom>
            {isShowMicrosoftActivationLink && (
              <div>
                {!msData.content ? (
                  <a
                    className="full-width accessibility-links"
                    tabIndex={0}
                    href={msData.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CardContent
                      data-testid="Activate_plan"
                      isSuspended={
                        !subscriptionStatus.isEntityEnabled || isCancelled
                      }
                    >
                      {t(
                        'CloudStorage.MS-Activation-Link',
                        'Activate Microsoft 365 Personal'
                      )}
                    </CardContent>

                    <CardContent data-testid="Activate_Link">
                      <IconExternalLink
                        data-testid="Activate_Icon"
                        size={24}
                        style={{
                          color:
                            !subscriptionStatus.isEntityEnabled || isCancelled
                              ? colorGray4
                              : colorHpBlue6
                        }}
                      />
                    </CardContent>
                  </a>
                ) : (
                  <a
                    className="full-width accessibility-links"
                    href={redirectURLs.msNavigationURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CardContent
                      isSuspended={
                        !subscriptionStatus.isEntityEnabled || isCancelled
                      }
                    >
                      {t(
                        'CloudStorage.MS-Activated-Link',
                        'Go to Microsoft 365 Personal'
                      )}
                    </CardContent>
                    <CardContent>
                      <IconExternalLink
                        size={24}
                        style={{
                          color:
                            !subscriptionStatus.isEntityEnabled || isCancelled
                              ? colorGray4
                              : colorHpBlue6
                        }}
                      />
                    </CardContent>
                  </a>
                )}
              </div>
            )}
          </AlignContent>
        </Container>
      ) : (
        <ErrorContainer data-testid="error-container">
          <InsideCard>
            <MicrosoftImage>
              <Image
                data-testid="Image"
                src={Images.MicrosoftPrearrival}
                width="147"
                height="24"
                alt="Microsoft 365"
              />
            </MicrosoftImage>
            <MicrosoftPersonalImage
              data-testid="ImagePersonal"
              src={Images.MicrosoftPersonal}
              width="72"
              height="15"
              alt="Personal"
            />
            <CardErrorText>
              {t(
                'CloudStorage.Content-error-paragraph-1',
                'Something went wrong.'
              )}
            </CardErrorText>
            <CardErrorText>
              {t(
                'CloudStorage.Content-error-paragraph-2',
                'Your cloud storage personal information did not load.'
              )}
            </CardErrorText>
            <CardErrorContent>
              {t(
                'CloudStorage.Content-error-description',
                'Please refresh the page.'
              )}
            </CardErrorContent>
          </InsideCard>
        </ErrorContainer>
      )}
    </Card>
  )
}

export default MicrosoftStorage
